import React, { useContext, useEffect, useState } from 'react';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import {
  IconButton,
  ListItem,
  Tooltip,
  Typography,
  Box,
  Grid,
  SwipeableDrawer,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import styled from '@emotion/styled';
import MuiDrawer from '@mui/material/Drawer';
import { NavLink } from 'react-router-dom';
import Home from '@mui/icons-material/HomeOutlined';
import { makeStyles, withStyles } from '@mui/styles';
import NewApplication from '@mui/icons-material/NoteAlt';
import MyApplication from '@mui/icons-material/SpeakerNotes';
import FeePayment from '@mui/icons-material/Payment';
import Document from '@mui/icons-material/AssignmentInd';
import History from '@mui/icons-material/ManageHistory';
import Ken42Icon from '../../Assets/SvgImages/Ken42.svg';
import spjain from '../../Assets/SvgImages/spjain.svg';
import spjainDetailed from '../../Assets/Images/spjainDetailed.png';
import { AuthContext } from '../../Context/authContext';
import routes from '../../Constants/routes';
import FacebookIcon from '../../Assets/SvgImages/FacebookIcon.svg';
import LinkedinIcon from '../../Assets/SvgImages/LinkedinIcon.svg';
import HomeNav from '../../Assets/Svg/home.svg';
import MyApplicationNav from '../../Assets/Svg/applications.svg';
import PaymentNav from '../../Assets/Svg/payment.svg';
import DocumentNav from '../../Assets/Svg/document.svg';
import TwitterIcon from '../../Assets/SvgImages/TwitterIcon.svg';
import InstagramIcon from '@mui/icons-material/Instagram';
import OfferLetterIcon from '../../Assets/SvgImages/OfferLetterIcon.svg';
import clsx from 'clsx';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import { TABLET_BREAKPOINT } from '../../Constants/constant';
import { getDocuments } from '../../Utils/apiServices';
import NitteLogo from '../../Assets/Images/nitteeLogo.png';
import Nlogo from '../../Assets/Images/Nlogo.png';
import FinalLogo from '../../Assets/Images/FINAL_NITTE.png';

const drawerWidth = 210;

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: '12px 20px',
    width: 'calc(100% + 20px)',
    marginBottom: 8,
    '&:hover': {
      width: 'calc(100% + 20px)',
      backgroundColor: theme.palette.KenColors.sideNavItemHoverBackground,
      borderRadius: '24px 0px 0px 24px',

      '& $subLevel': {
        color: theme.palette.KenColors.sideNavItemHoverColor,
      },
      '& $heading': {
        color: theme.palette.KenColors.sideNavItemHoverColor,
      },
    },
    '&.Mui-selected': {
      backgroundColor: 'turquoise',
      color: 'white',
      fontWeight: 600,
      borderRadius: '24px 0px 0px 24px',
    },
    borderRadius: '24px 0px 0px 24px',
  },
  active: {
    '& $listItem': {
      width: 'calc(100% + 20px)',
      // backgroundColor: '#4290BD',
      backgroundColor: theme.palette.KenColors.sideNavItemActiveBackground,
      borderRadius: '24px 0px 0px 24px',
    },

    '& $subListItem': {
      borderLeft: `4px solid ${theme.palette.KenColors.kenWhite}`,
      marginLeft: 0,
      backgroundColor: theme.palette.KenColors.sideNavItemActiveBackground,
      borderRadius: '24px 0px 0px 24px',
    },

    '& $heading': {
      color: theme.palette.KenColors.sideNavItemActiveColor,
    },
    '& $subLevel': {
      color: theme.palette.KenColors.sideNavItemActiveColor,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.KenColors.sideNavItemActiveBackground,
      color: 'white',
      fontWeight: 600,
      borderRadius: '24px 0px 0px 24px',

      '&:hover': {
        backgroundColor: theme.palette.KenColors.sideNavItemActiveBackground,
        // color: 'white',
        borderRadius: '24px 0px 0px 24px',
      },
    },
    textDecoration: 'none',
    borderRadius: '24px 0px 0px 24px',
  },
  menuIcon: {
    color: theme.palette.KenColors.kenWhite,

    '& >p': {
      display: 'flex',
      color: theme.palette.KenColors.kenWhite,
    },
  },
  heading: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '0.875rem',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.KenColors.kenWhite,
  },
  drawer: {
    justifyContent: 'space-between',
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    // background: 'linear-gradient(176.94deg, #158ABB 14.53%, #060B6B 95.84%)',
    background: theme.palette.KenColors.sideNavBackground,
    margin: 16,
    borderRadius: 12,
    height: '95vh',
    [theme.breakpoints.down('md')]: {
      margin: 0,
      borderRadius: '0px 24px 24px 0px',
      height: '100vh',
      'overflow-x': 'hidden',
    },
  },
  root: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      // Note: added so side bar drawer show on top of header when active
      'z-index': '1302',
    },
  },
  icon: {
    color: theme.palette.KenColors.kenWhite,
  },
  link: {
    textDecoration: 'none',
  },
  typoReserved: {
    fontSize: 10,
    color: theme.palette.KenColors.kenWhite,
  },
  typoReservedOpen: {
    width: 88,
    whiteSpace: 'initial',
  },
  imageBanner: {
    width: 76,
    height: 47,
    // marginLeft: 8,
    marginLeft: 0,
  },
  imageBannerDetailed: {
    width: 166,
    height: 85,
    marginLeft: 8,
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const DrawerFooter = styled('div', {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  opacity: 1,
  marginBottom: theme.spacing(3),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  display: 'relative',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(8)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(12)} + 1px)`,
  },
});

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

const menu = [
  {
    icon: <img src={HomeNav} alt="" />,
    label: 'Home',
    link: `/${routes.home}`,
    callback: '',
  },

  {
    icon: <img src={MyApplicationNav} alt="" />,
    label: 'My Applications',
    link: `/${routes.myApplications}`,
    callback: '',
  },
  // {
  //   icon: <img src={PaymentNav} alt="" />,
  //   label: 'Fee Payments',
  //   link: `/${routes.feePayments}`,
  //   callback: '',
  // },
  // {
  //   icon: <img src={OfferLetterIcon} alt="" />,
  //   label: 'Offer Letter',
  //   link: `/${routes.offerLetter}`,
  //   callback: '',
  // },
];

const GetSideNavData = (data) => {
  const classes = useStyles();
  const { open, handleDrawerClose, handleDrawerOpen, sideNavMenu } = data;
  const theme = useTheme();

  return (
    <>
      <Box>
        <DrawerHeader>
          <Grid container direction="column">
            <Grid
              item
              style={{ textAlign: 'left', marginTop: 16, minHeight: 92 }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img
                // src={FinalLogo}
                // src={"https://inazstgpfs3001.blob.core.windows.net/assets/Blue-Logo-CAPS-800x117.png"}
                // src={"https://inazstgpfs3001.blob.core.windows.net/assets/ken42_logo.png"}
                // src={"https://inazstgpfs3001.blob.core.windows.net/assets/samsung%202.png"}
                // src={
                //   'https://inazstgpfs3001.blob.core.windows.net/assets/samsung-white.SVG'
                // }
                // src={
                //   'https://inazstgpfs3001.blob.core.windows.net/assets/Images/SAMSUNG/SICLogo.jpg'
                // }
                src={
                  'https://infivestoragedev.blob.core.windows.net/infive/SIC_RMV.png'
                  // 'https://img.talkandroid.com/uploads/2016/01/LG-LOGO-630x304.jpg'
                  
                }
                alt="Ken42 Icon"
                className={clsx(classes.imageBanner, {
                  [classes.imageBannerDetailed]: open === true,
                })}
              />
            </Grid>
            <Grid
              item
              style={{ textAlign: 'right', marginRight: -12, paddingTop: 16 }}
              sx={{ display: { xs: 'none', tablet: 'block' } }}
            >
              <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
                {!open ? (
                  <KeyboardDoubleArrowRightIcon className={classes.icon} />
                ) : (
                  <KeyboardDoubleArrowLeftIcon className={classes.icon} />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </DrawerHeader>
        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingLeft: open ? '40px' : undefined,
          }}
        >
          {sideNavMenu &&
            sideNavMenu?.map((item, index) => {
              return (
                <NavLink
                  key={index}
                  to={
                    item?.link === null || item?.link === undefined ? (
                      <Typography>{item?.link}</Typography>
                    ) : (
                      item?.link
                    )
                  }
                  className={({ isActive }) =>
                    isActive ? classes.active : classes.link
                  }
                >
                  <ListItem
                    onClick={item.callback}
                    classes={{
                      root: classes.listItem,
                      selected: classes.active,
                    }}
                    button
                  >
                    <Grid
                      container
                      alignItems="center"
                      sx={{ minWidth: open ? 180 : undefined }}
                    >
                      <Grid item sx={{ minWidth: open ? '32px' : undefined }}>
                        {open === false ? (
                          <LightTooltip
                            title={item.label}
                            placement="right-end"
                          >
                            <span className={classes.menuIcon}>
                              <Typography>{item.icon}</Typography>
                            </span>
                          </LightTooltip>
                        ) : (
                          <span className={classes.menuIcon}>
                            <Typography>{item.icon}</Typography>
                          </span>
                        )}
                      </Grid>
                      <Grid item>
                        {open && (
                          <span className={classes.heading}>{item.label}</span>
                        )}
                      </Grid>
                    </Grid>
                  </ListItem>
                </NavLink>
              );
            })}
        </List>
      </Box>
      <DrawerFooter open={open}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          direction="column"
        >
          <Grid item marginBottom={theme.spacing(2)}>
            <>
              <Grid
                container
                spacing={open ? 2 : 1}
                direction={open ? 'row' : 'column'}
              >
                <Grid item display="flex" alignItems="flex-end">
                  {/* <img src={FacebookIcon} /> */}

                  <a href="https://www.facebook.com/" target="_blank">
                    <img src={FacebookIcon} />
                  </a>
                </Grid>
                <Grid item display="flex" alignItems="flex-end">
                  {/* <img src={TwitterIcon} /> */}

                  <a href="https://www.instagram.com/" target="_blank">
                    <InstagramIcon sx={{ color: '#ffffff', height: '20px', width: '20px', border: '1.5px solid white', borderRadius: '50%', padding: '3px' }} />
                  </a>
                </Grid>
                <Grid item display="flex" alignItems="flex-end">
                  {/* <img src={LinkedinIcon} /> */}

                  <a href="https://in.linkedin.com/" target="_blank">
                    <img src={LinkedinIcon} />
                  </a>
                </Grid>
              </Grid>
            </>
          </Grid>
          <Grid item>
            <Typography
              className={clsx(classes.typoReserved, {
                [classes.typoReservedOpen]: open === false,
              })}
            >
              © 2024 All rights Reserved
            </Typography>
          </Grid>
        </Grid>
      </DrawerFooter>
    </>
  );
};

export default function SideNavigation(props) {
  const [sideNavMenu, setSideNavMenu] = useState(menu);
  const classes = useStyles();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    state: { user },
    dispatch,
  } = useContext(AuthContext);
  const { open, setSwipeableDrawer, swipeableDrawer, setOpen } = props;

  useEffect(() => {
    setSideNavMenu((prev) => {
      let temp = [
        ...sideNavMenu.map((i) => {
          // Note: added to close drawer on mobile devices
          if (typeof i?.callback !== 'function') {
            i.callback = () => {
              setSwipeableDrawer(false);
            };
          }
          return i;
        }),
      ];

      // Check side menu is not already having logout button
      if (
        smallScreen &&
        (sideNavMenu || []).filter((s) => s.label === 'Logout').length === 0
      ) {
        temp.push({
          icon: <ExitToAppOutlinedIcon />,
          label: 'Logout',
          link: ``,
          callback: () => {
            dispatch({
              type: 'LOGOUT',
              payload: {
                user: {},
                token: null,
              },
            });
          },
        });
      }

      return temp;
    });
  }, [isMobileScreen, smallScreen]);

  useEffect(() => {
    getDocuments(user.applicantId).then((res) => {
      if (
        res?.data?.some((el) => el.enableDocuments === 'false') &&
        sideNavMenu?.filter((s) => s.label === 'Documents')?.length === 0
      ) {
        setSideNavMenu([
          ...sideNavMenu,
          {
            icon: <img src={DocumentNav} alt="" />,
            label: 'Documents',
            link: `/${routes.document}`,
            callback: isMobileScreen
              ? () => {
                  setSwipeableDrawer(false);
                }
              : '',
          },
        ]);
      }
    });
  }, []);

  useEffect(() => {
    if (isMobileScreen) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [isMobileScreen]);

  const toggleDrawer = (event) => {
    setSwipeableDrawer(false);
  };

  return (
    <Box sx={{ m: isMobileScreen ? undefined : 2 }}>
      {!isMobileScreen ? (
        <Drawer
          variant="permanent"
          open={open}
          classes={{ paper: classes.drawer, root: classes.root }}
        >
          <GetSideNavData sideNavMenu={sideNavMenu} {...props} />
        </Drawer>
      ) : (
        <SwipeableDrawer
          anchor={'left'}
          open={swipeableDrawer}
          onClose={toggleDrawer}
          classes={{ paper: classes.drawer, root: classes.root }}
        >
          <GetSideNavData sideNavMenu={sideNavMenu} {...props} />
        </SwipeableDrawer>
      )}
    </Box>
  );
}
