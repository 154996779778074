import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Grid, CircularProgress, FormControl } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useMediaQuery } from '@mui/material';
import { AccountLink, SubTitle, Title } from '../common';
import KenInput from '../../../Components/KenInput';
import KenSelect from '../../../Components/KenSelect';
import KenButton from '../../../Components/KenButton';
import KenPhoneInput from '../../../Components/KenPhoneInput';
import { useNavigate } from 'react-router-dom';
import routes from '../../../Constants/routes';
import LoginBG from '../../../Assets/Svg/login-bg.svg';
import MobileLoginBG from '../../../Assets/Svg/login-mobile-bg.svg';
import { Controller, useForm, clearErrors } from 'react-hook-form';
import validator from 'validator';
import { requestForOTP, signUp, sendOtp, requestForNewOTP } from '../api';
import { useSnackbar } from 'notistack';
import phone from 'phone';

import getValidation from '../../../Utils/Helpers/validationHelper';

const useStyles = makeStyles((theme) => ({
  customDatePicker: {
    '& .react-date-picker__calendar': {
      zIndex: 2,
    },
    '& .react-date-picker__wrapper': {
      border: 0,
      borderRadius: '4px',
      padding: '6px 16px',
      backgroundColor: '#F3F5F7',
      textAlign: 'left',
      '&:focus': {
        backgroundColor: theme.palette.KenColors.kenWhite,
      },
      '& input': {
        color: theme.palette.KenColors.grey2,
      },
      '& .react-date-picker__inputGroup__divider': {
        color: theme.palette.KenColors.grey2,
      },
      '& .react-date-picker__button': {
        paddingRight: 0,
      },
    },
  },

  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${LoginBG})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${MobileLoginBG})`,
    },
  },
  loginContent: {
    marginTop: '20px',
    backgroundColor: 'white',
    maxHeight: '600px',
    overflowY: 'scroll',
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    borderRadius: '16px',
    width: '100%', // Adjusted width for better responsiveness
    maxWidth: '470px',
    padding: '25px 45px',
    [theme.breakpoints.down('md')]: {
      padding: '48px 32px', // Adjusted padding for smaller screens
      margin: '20px',
      maxWidth: '363px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '48px 32px', // Adjusted padding for smaller screens
      margin: '20px',
      maxWidth: '250px !important ',
    },
  },
  title: {
    fontWeight: '500 !important',
    margin: '0 auto 20px',
    display: 'block',
    textAlign: 'center',
    marginTop: '10px',
  },
  subTitle: {
    fontSize: '16px !important',
    fontWeight: '500 !important',
    marginBottom: '32px !important',
    margin: '0 auto 20px',
    display: 'block',
    textAlign: 'center',
    marginTop: '10px',
  },
  inputFieldLabel: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '100%',
    textTransform: 'capitalize',
    color: theme.palette.KenColors.grey2,
  },
  verify: {
    width: '40%',
    height: '40px',
    marginBottom: '40px',
    borderRadius: '12px !important',
    fontSize: '16px',
    fontWeight: 600,
    backgroundColor: '#4a006a !important',
    color: 'white !important',
    fontFamily: 'SamsungSharp',
    margin: 'auto', // Horizontally center the button
  },
  signupWith: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '100%',
    color: theme.palette.KenColors.neutral100,
  },
  signupWithItem: {
    margin: '0 8px',
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    boxShadow:
      '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    '& img': {
      height: '24px',
      width: '24px',
    },
  },
  phoneContainer: {
    display: 'flex',
    '& .phone-input': {
      fontFamily: 'Poppins,sans-serif',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '100%',
      color: theme.palette.KenColors.grey2,
      padding: '16px',
      order: 2,
      background: '#F3F5F7',
      border: 0,
      borderRadius: '4px',
      marginLeft: '11px',
      '&::placeholder': {
        fontSize: '16px',
        lineHeight: '100%',
        color: '#A8AFBC',
        fontFamily: 'Poppins,sans-serif',
      },
    },
    '& .phone-dropdown': {
      position: 'relative',
      order: 1,
      border: 0,
      background: '#F3F5F7',
      '&.open': {
        background: '#F3F5F7',
        '& .selected-flag': {
          background: '#F3F5F7',
        },
      },
      '& .selected-flag': {
        width: '50px',
        padding: '10px',
        '&:hover': {
          background: '#F3F5F7',
        },
      },
    },
  },
  carousel: {
    marginTop: '15%',
  },
}));

const email = (value) => {
  if (validator.isEmail(value)) {
    if (value.endsWith('.com.in')) {
      return 'Please enter valid email';
    } else if(value.endsWith('.com') || value.endsWith('.in') || value.endsWith('.net') || value.endsWith('.biz') || value.endsWith('.edu') || value.endsWith('.info') || value.endsWith('.co.in') || value.endsWith('.gov') || value.endsWith('.io') || value.endsWith('.org')) {
      return true;
    } else {
      return 'Please enter valid email';
    }
  }

  return 'Please enter valid email';
};

const phoneValidator = (value) => {
  const validPhone = phone(value);
  if (validPhone.isValid == false) {
    return 'Please enter valid Phone number';
  }

  return true;
};

export default function Login() {
  const classes = useStyles();
  const navigate = useNavigate();
  // const [date, onDateChange] = useState(null);
  const [salutation, setSalutation] = useState(null);
  const [trainingCentre, setTrainingCentre] = useState(null);
  const [courseOptions, setCourseOptions] = useState();
  const [currentSlide, setCurrentSlide] = useState(0); // State to keep track of the current slide index
  const [showVideo, setShowVideo] = useState(false);

  const [course, setCourse] = useState();
  // const [modalStyle] = React.useState(getModalStyle);
  // const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { control, handleSubmit, setValue, trigger } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    if (trainingCentre) {
      switch (trainingCentre) {
        case 'Gautam Buddha University, Greater Noida':
          setCourseOptions([
            {
              label: 'Artificial Intelligence',
              value: 'Artificial Intelligence',
            },
          ]);
          break;
        case 'Deen Dayal Upadhyaya Gorakhpur University, Gorakhpur':
          setCourseOptions([
            { label: 'Big Data', value: 'Big Data' },
            { label: 'Coding & Programming', value: 'Coding & Programming' },
            {
              label: 'Artificial Intelligence',
              value: 'Artificial Intelligence',
            },
            { label: 'Internet Of Things', value: 'Internet Of Things' },
          ]);
          break;
        case 'Madan Mohan Malviya University of Technology, Gorakhpur':
          setCourseOptions([
            { label: 'Coding & Programming', value: 'Coding & Programming' },
            {
              label: 'Artificial Intelligence',
              value: 'Artificial Intelligence',
            },
          ]);
          break;
        case 'NSIC, Delhi':
          setCourseOptions([
            { label: 'Internet Of Things', value: 'Internet Of Things' },
          ]);
          break;
        case 'Babasaheb Bhimrao Ambedkar University, Lucknow':
          setCourseOptions([
            { label: 'Coding & Programming', value: 'Coding & Programming' },
            { label: 'Internet Of Things', value: 'Internet Of Things' },
          ]);
          break;
        case 'University of Lucknow, Lucknow':
          setCourseOptions([
            {
              label: 'Artificial Intelligence',
              value: 'Artificial Intelligence',
            },
            { label: 'Coding & Programming', value: 'Coding & Programming' },
            { label: 'Big Data', value: 'Big Data' },
          ]);
          break;
        case 'Khwaja Moinuddin Chishti Language University, Lucknow':
          setCourseOptions([
            { label: 'Coding & Programming', value: 'Coding & Programming' },
            { label: 'Big Data', value: 'Big Data' },
          ]);
          break;
        case 'NSIC, Chennai':
          setCourseOptions([
            { label: 'Coding & Programming', value: 'Coding & Programming' },
            { label: 'Big Data', value: 'Big Data' },
          ]);
          break;
        case 'Rajiv Gandhi College of Engineering':
          setCourseOptions([
            { label: 'Coding & Programming', value: 'Coding & Programming' },
            { label: 'Big Data', value: 'Big Data' },
          ]);
          break;
          case 'Sri Jayachamarajendra Polytechnic College, Bangalore':
            setCourseOptions([
              { label: 'Coding & Programming', value: 'Coding & Programming' },
              {
                label: 'Artificial Intelligence',
                value: 'Artificial Intelligence',
              },
              { label: 'Internet Of Things', value: 'Internet Of Things' },
            ]);
            break;
          case 'Govt Sri Krishnarajendra Silver JubleeTechnological Institute':
            setCourseOptions([
              { label: 'Coding & Programming', value: 'Coding & Programming' },
            ]);
            break;
          case 'University of Visveshvaraya College of Engineering':
            setCourseOptions([
              { label: 'Coding & Programming', value: 'Coding & Programming' },
            ]);
            break; 
      }
    }
  }, [trainingCentre]);

  function splitName(data) {
    const lastName = data.lastName.trim();
    let firstName, lastNameSplit;

    if (lastName.includes(' ')) {
      const spaceIndex = lastName.indexOf(' ');
      firstName = lastName.substring(0, spaceIndex);
      lastNameSplit = lastName.substring(spaceIndex + 1);
    } else {
      firstName = lastName;
      lastNameSplit = '.';
    }

    return { firstName, lastName: lastNameSplit };
  }

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      let { firstName, lastName } = splitName(data);
      data.firstName = firstName
      data.lastName = lastName
      data.AccountId = "0012y00000UY32uAAD"
      // const resData = await signUp(data);
      // console.log(resData, '3der3red');
      // if (resData == undefined) {
      //   enqueueSnackbar("Email ID already exists.", { variant: 'error' });
      //   setTimeout(() => navigate(routes.login), 3000);
      // }
      // if (!resData?.success) {
      //   resData?.errorMessages?.forEach((items) => {
      //     enqueueSnackbar({ message: items?.errorMessage, variant: 'error' });
      //   });
      //   setTimeout(() => navigate(routes.login), 3000);
      // } else {
        const res = await requestForNewOTP({ id: data.email });

        navigate(routes.verifyEmail, {
          state: {
            id: res.applicantId,
            email: data.email,
            phone: data.phone,
            formId: res.applicationFormId,
            // formId: '',
            data,
          },
        });
      // TODO: Change it back to verifyLogin and id to phone
    } catch (error) {
      console.log('isnide')
      console.log(error.response, "32de");
      // return enqueueSnackbar(
      //   error && error?.response?.data
      //     ? Array.isArray(error?.response?.data?.errorMessages) &&
      //         error?.response?.data?.errorMessages[0]?.errorMessage
      //     : error?.response?.data?.errorMessage || 'Something went wrong',
      //   {
      //     variant: 'error',
      //   }
      // );
    } finally {
      setLoading(false);
    }
  };
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const trainingCentreOption = [
    {
      label: 'Gautam Buddha University, Greater Noida',
      value: 'Gautam Buddha University, Greater Noida',
    },
    { label: 'Deen Dayal Upadhyaya Gorakhpur University, Gorakhpur', value: 'Deen Dayal Upadhyaya Gorakhpur University, Gorakhpur' },
    {
      label: 'Madan Mohan Malviya University of Technology, Gorakhpur',
      value: 'Madan Mohan Malviya University of Technology, Gorakhpur',
    },
    {
      label: 'NSIC, Delhi',
      value: 'NSIC, Delhi',
    },
    { label: 'Babasaheb Bhimrao Ambedkar University, Lucknow', value: 'Babasaheb Bhimrao Ambedkar University, Lucknow' },
    {
      label: 'NSIC, Chennai',
      value: 'NSIC, Chennai',
    },
    {
      label: 'Rajiv Gandhi College of Engineering',
      value: 'Rajiv Gandhi College of Engineering',
    },
    {
      label: 'University of Lucknow, Lucknow',
      value: 'University of Lucknow, Lucknow',
    },
    {
      label: 'Khwaja Moinuddin Chishti Language University, Lucknow',
      value: 'Khwaja Moinuddin Chishti Language University, Lucknow',
    },
    {
      label: 'Sri Jayachamarajendra Polytechnic College, Bangalore',
      value: 'Sri Jayachamarajendra Polytechnic College, Bangalore',
    },
    {
      label: 'Govt Sri Krishnarajendra Silver JubleeTechnological Institute',
      value: 'Govt Sri Krishnarajendra Silver JubleeTechnological Institute',
    },
    {
      label: 'University of Visveshvaraya College of Engineering',
      value: 'University of Visveshvaraya College of Engineering',
    },
  ];

  return (
    <Grid container>
      {/* Conditionally render only on small screens */}
      {isSmallScreen && (
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '81vh',
            backgroundImage: `url(${MobileLoginBG})`,
            backgroundRepeat: 'no-repeat', // Set background image to not repeat
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box className={classes.loginContent}>
              <PerfectScrollbar>
                <Title className={classes.title} text="Get Started!" />
                <SubTitle
                  className={classes.subTitle}
                  text="Sign Up to start using this platform."
                />
                {/* <Grid container spacing={1}> */}
                {/* <Grid item xs={2}> */}
                <FormControl fullWidth style={{ marginBottom: '24px' }}>
                  <Controller
                    name="salutation"
                    control={control}
                    rules={{ 
                      required: 'This field is Required', 
                      // pattern:{
                      //   value: "",
                      //   message: "Please Input a valid Name"
                      // }
                    }}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <KenSelect
                          name="salutation"
                          required
                          select
                          label="Salutation"
                          value={salutation}
                          labelClassName={classes.inputFieldLabel}
                          options={[
                            { label: 'Mr.', value: 'Mr.' },
                            { label: 'Mrs.', value: 'Mrs.' },
                            { label: 'Ms.', value: 'Ms.' },
                          ]}
                          {...field}
                          error={error?.message}
                          onChange={(event, item, v) => {
                            setSalutation(event.target.value);
                            // clearErrors('salutation');
                            setValue('salutation', event.target.value);
                          }}
                        />
                      );
                    }}
                  />
                </FormControl>
                {/* </Grid> */}
                {/* <Grid item xs={5}> */}
                {/* <FormControl fullWidth style={{ marginBottom: '24px' }}>
                  <Controller
                    name="firstName"
                    control={control}
                    rules={{
                      required: 'This field is Required',
                      maxLength: {
                        value: 20,
                        message: 'This field cannot be more than 20 characters',
                      },
                      pattern: {
                        message: 'Please input a correct value',
                        value: getValidation('alpha'),
                      },
                    }}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <KenInput
                          required
                          name="firstName"
                          label="First Name"
                          placeholder="Enter First Name"
                          errorLabel={false}
                          labelClassName={classes.inputFieldLabel}
                          {...field}
                          error={error?.message}
                        />
                      );
                    }}
                  />
                </FormControl> */}
                {/* </Grid> */}
                {/* <FormControl fullWidth style={{ marginBottom: '24px' }}>
                      <Controller
                        name="middleName"
                        control={control}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <KenInput
                              name="middleName"
                              label="Middle Name"
                              placeholder="Enter Middle Name"
                              labelClassName={classes.inputFieldLabel}
                              errorLabel={false}
                              {...field}
                              error={error}
                            />
                          );
                        }}
                      />
                    </FormControl> */}
                {/* <Grid item xs={5}> */}
                <FormControl fullWidth style={{ marginBottom: '24px' }}>
                  <Controller
                    name="lastName"
                    control={control}
                    rules={{
                      required: 'This field is Required',
                      minLength: {
                        value: 2,
                        message: 'Please Enter valid name',
                      },
                      maxLength: {
                        value: 50,
                        message: 'This field cannot be more than 50 characters',
                      },
                      pattern: {
                        message: 'Please input a correct value',
                        value: getValidation('alpha'),
                      },
                    }}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <KenInput
                          required
                          name="lastName"
                          label="Full Name"
                          placeholder="Enter Full Name"
                          errorLabel={false}
                          labelClassName={classes.inputFieldLabel}
                          {...field}
                          error={error?.message}
                        />
                      );
                    }}
                  />
                </FormControl>
                {/* </Grid> */}
                {/* </Grid> */}
                {/* <Grid container spacing={1}> */}
                {/* <Grid item xs={6}> */}
                <FormControl fullWidth style={{ marginBottom: '24px' }}>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: 'This field is Required',
                      validate: email,
                    }}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <KenInput
                          required
                          name="email"
                          label="Email"
                          placeholder="Enter Email"
                          labelClassName={classes.inputFieldLabel}
                          errorLabel={false}
                          {...field}
                          error={error?.message}
                        />
                      );
                    }}
                  />
                </FormControl>
                {/* </Grid> */}
                {/* <Grid item xs={6}> */}
                <FormControl fullWidth style={{ marginBottom: '24px' }}>
                  <Controller
                    name="phone"
                    control={control}
                    rules={{
                      required: 'This field is Required',
                      validate: phoneValidator,
                      maxLength: {
                        value: 17,
                        message: 'Please input valid phone number',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <KenPhoneInput
                          name="phone"
                          containerClass={classes.phoneContainer}
                          inputClass={`${classes.inputFieldLabel} phone-input`}
                          buttonClass="phone-dropdown"
                          country={'in'}
                          placeholder="Enter Number"
                          errorLabel={false}
                          {...field}
                          error={error?.message}
                          onChange={(phone) => {
                            setValue('phone', phone);
                            trigger('phone');
                          }}
                        />
                      );
                    }}
                  />
                </FormControl>
                {/* </Grid> */}
                {/* </Grid> */}
                {/* <Grid container spacing={1}> */}
                {/* <Grid item xs={6}> */}
                <FormControl fullWidth style={{ marginBottom: '24px' }}>
                  <Controller
                    name="trainingCentre"
                    control={control}
                    rules={{ required: 'This field is Required' }}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <KenSelect
                          name="trainingCentre"
                          required
                          select
                          label="Training Centre"
                          value={trainingCentre}
                          labelClassName={classes.inputFieldLabel}
                          options={trainingCentreOption}
                          {...field}
                          error={error?.message}
                          onChange={(event, item, v) => {
                            setTrainingCentre(event.target.value);
                            setValue('trainingCentre', event.target.value);
                          }}
                        />
                      );
                    }}
                  />
                </FormControl>
                {/* </Grid> */}
                {trainingCentre ? (
                  // <Grid item xs={6}>
                  <FormControl fullWidth style={{ marginBottom: '24px' }}>
                    <Controller
                      name="course"
                      control={control}
                      rules={{ required: 'This field is Required' }}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <KenSelect
                            name="course"
                            required
                            select
                            label="Course"
                            value={course}
                            labelClassName={classes.inputFieldLabel}
                            options={courseOptions}
                            {...field}
                            error={error?.message}
                            onChange={(event, item, v) => {
                              setCourse(event.target.value);
                              setValue('course', event.target.value);
                            }}
                          />
                        );
                      }}
                    />
                  </FormControl>
                ) : (
                  // </Grid>
                  <></>
                )}

                {/* </Grid> */}
                {/* <FormControl fullWidth style={{ marginBottom: "24px" }}>
                    <Controller
                      name="salutation"
                      control={control}
                      rules={{ required: true }}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <KenSelect
                            name="salutation"
                            required
                            select
                            label="Salutation"
                            value={salutation}
                            labelClassName={classes.inputFieldLabel}
                            options={[
                              { label: "Mr.", value: "Mr." },
                              { label: "Mrs.", value: "Mrs." },
                              { label: "Ms.", value: "Ms." }
                            ]}
                            {...field}
                            error={error?.message}
                            onChange={(event, item, v) => {
                              setSalutation(event.target.value);
                              setValue("salutation", event.target.value);
                            }}
                          />
                        );
                      }}
                    />
                  </FormControl> */}
                <Box width="100%">
                  <KenButton
                    variant="primary"
                    buttonClass={classes.verify}
                    type="submit"
                    disabled={loading}
                  >
                    Verify
                  </KenButton>
                  {loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '49%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    />
                  )}
                </Box>
                <Box
                  display={'flex'}
                  justifyContent="center"
                  alignItems={'center'}
                  width="100%"
                >
                  <AccountLink
                    text="Already Have An Account?"
                    actionText={'Log In'}
                    onAction={() => navigate(routes.login)}
                  />
                </Box>
              </PerfectScrollbar>
            </Box>
          </form>
        </Grid>
      )}

      {/* Conditionally render only on screens larger than small */}
      {!isSmallScreen && (
        <Grid
          item
          xs={12} // Show on extra small screens and above
          style={{
            position: 'relative',
            minHeight: '100vh',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            width: '100%',
            backgroundImage: `url("https://inazstgpfs3001.blob.core.windows.net/assets/Images/SAMSUNG/BackgroundPFS.png")`,
          }}
        >
          <Grid
            className={classes.container}
            style={{
              position: 'absolute',
              top: '50%',
              left: '80%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box className={classes.loginContent}>
                <PerfectScrollbar>
                  <Title className={classes.title} text="Get Started!" />
                  <SubTitle
                    className={classes.subTitle}
                    text="Sign Up to start using this platform."
                  />
                  {/* <Grid container spacing={1}> */}
                  {/* <Grid item xs={2}> */}
                  <FormControl fullWidth style={{ marginBottom: '24px' }}>
                    <Controller
                      name="salutation"
                      control={control}
                      rules={{ required: 'This field is Required' }}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <KenSelect
                            name="salutation"
                            required
                            select
                            label="Salutation"
                            value={salutation}
                            labelClassName={classes.inputFieldLabel}
                            options={[
                              { label: 'Mr.', value: 'Mr.' },
                              { label: 'Mrs.', value: 'Mrs.' },
                              { label: 'Ms.', value: 'Ms.' },
                            ]}
                            {...field}
                            error={error?.message}
                            onChange={(event, item, v) => {
                              setSalutation(event.target.value);
                              setValue('salutation', event.target.value);
                            }}
                          />
                        );
                      }}
                    />
                  </FormControl>
                  {/* </Grid> */}
                  {/* <Grid item xs={5}> */}
                  {/* <FormControl fullWidth style={{ marginBottom: '24px' }}>
                    <Controller
                      name="firstName"
                      control={control}
                      rules={{
                        required: 'This field is Required',
                        maxLength: {
                          value: 20,
                          message:
                            'This field cannot be more than 20 characters',
                        },
                        pattern: {
                          message: 'Please input a correct value',
                          value: getValidation('alpha'),
                        },
                      }}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <KenInput
                            required
                            name="firstName"
                            label="First Name"
                            placeholder="Enter First Name"
                            errorLabel={false}
                            labelClassName={classes.inputFieldLabel}
                            {...field}
                            error={error?.message}
                          />
                        );
                      }}
                    />
                  </FormControl> */}
                  {/* </Grid> */}
                  {/* <FormControl fullWidth style={{ marginBottom: '24px' }}>
                      <Controller
                        name="middleName"
                        control={control}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <KenInput
                              name="middleName"
                              label="Middle Name"
                              placeholder="Enter Middle Name"
                              labelClassName={classes.inputFieldLabel}
                              errorLabel={false}
                              {...field}
                              error={error}
                            />
                          );
                        }}
                      />
                    </FormControl> */}
                  {/* <Grid item xs={5}> */}
                  <FormControl fullWidth style={{ marginBottom: '24px' }}>
                    <Controller
                      name="lastName"
                      control={control}
                      rules={{
                        required: 'This field is Required',
                        minLength: {
                          value: 2,
                          message: 'Please Enter valid name',
                        },
                        maxLength: {
                          value: 50,
                          message:
                            'This field cannot be more than 50 characters',
                        },
                        pattern: {
                          message: 'Please input a correct value',
                          value: getValidation('alpha'),
                        },
                      }}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <KenInput
                            required
                            name="lastName"
                            label="Full Name"
                            placeholder="Enter Full Name"
                            errorLabel={false}
                            labelClassName={classes.inputFieldLabel}
                            {...field}
                            error={error?.message}
                          />
                        );
                      }}
                    />
                  </FormControl>
                  {/* </Grid> */}
                  {/* </Grid> */}
                  {/* <Grid container spacing={1}> */}
                  {/* <Grid item xs={6}> */}
                  <FormControl fullWidth style={{ marginBottom: '24px' }}>
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: 'This field is Required',
                        validate: email,
                      }}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <KenInput
                            required
                            name="email"
                            label="Email"
                            placeholder="Enter Email"
                            labelClassName={classes.inputFieldLabel}
                            errorLabel={false}
                            {...field}
                            error={error?.message}
                          />
                        );
                      }}
                    />
                  </FormControl>
                  {/* </Grid> */}
                  {/* <Grid item xs={6}> */}
                  <FormControl
                    fullWidth
                    style={{ marginBottom: '24px', alignItems: 'baseline' }}
                  >
                    <Controller
                      name="phone"
                      control={control}
                      rules={{
                        required: 'This field is Required',
                        validate: phoneValidator,
                      }}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <KenPhoneInput
                            name="phone"
                            containerClass={classes.phoneContainer}
                            inputClass={`${classes.inputFieldLabel} phone-input`}
                            buttonClass="phone-dropdown"
                            country={'in'}
                            placeholder="Enter Number"
                            errorLabel={false}
                            {...field}
                            error={error?.message}
                            onChange={(phone) => {
                              setValue('phone', phone);
                              trigger('phone');
                            }}
                          />
                        );
                      }}
                    />
                  </FormControl>
                  {/* </Grid> */}
                  {/* </Grid> */}
                  {/* <Grid container spacing={1}> */}
                  {/* <Grid item xs={6}> */}
                  <FormControl fullWidth style={{ marginBottom: '24px' }}>
                    <Controller
                      name="trainingCentre"
                      control={control}
                      rules={{ required: 'This field is Required' }}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <KenSelect
                            name="trainingCentre"
                            required
                            select
                            label="Training Centre"
                            value={trainingCentre}
                            labelClassName={classes.inputFieldLabel}
                            options={trainingCentreOption}
                            {...field}
                            error={error?.message}
                            onChange={(event, item, v) => {
                              setTrainingCentre(event.target.value);
                              setValue('trainingCentre', event.target.value);
                            }}
                          />
                        );
                      }}
                    />
                  </FormControl>
                  {/* </Grid> */}
                  {trainingCentre ? (
                    // <Grid item xs={6}>
                    <FormControl fullWidth style={{ marginBottom: '24px' }}>
                      <Controller
                        name="course"
                        control={control}
                        rules={{ required: 'This field is Required' }}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <KenSelect
                              name="course"
                              required
                              select
                              label="Course"
                              value={course}
                              labelClassName={classes.inputFieldLabel}
                              options={courseOptions}
                              {...field}
                              error={error?.message}
                              onChange={(event, item, v) => {
                                setCourse(event.target.value);
                                setValue('course', event.target.value);
                              }}
                            />
                          );
                        }}
                      />
                    </FormControl>
                  ) : (
                    // </Grid>
                    <></>
                  )}

                  {/* </Grid> */}
                  {/* <FormControl fullWidth style={{ marginBottom: "24px" }}>
                    <Controller
                      name="salutation"
                      control={control}
                      rules={{ required: true }}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <KenSelect
                            name="salutation"
                            required
                            select
                            label="Salutation"
                            value={salutation}
                            labelClassName={classes.inputFieldLabel}
                            options={[
                              { label: "Mr.", value: "Mr." },
                              { label: "Mrs.", value: "Mrs." },
                              { label: "Ms.", value: "Ms." }
                            ]}
                            {...field}
                            error={error?.message}
                            onChange={(event, item, v) => {
                              setSalutation(event.target.value);
                              setValue("salutation", event.target.value);
                            }}
                          />
                        );
                      }}
                    />
                  </FormControl> */}
                  <Box width="100%">
                    <KenButton
                      variant="primary"
                      buttonClass={classes.verify}
                      type="submit"
                      disabled={loading}
                    >
                      Verify
                    </KenButton>
                    {loading && (
                      <CircularProgress
                        size={24}
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '49%',
                          transform: 'translate(-50%, -50%)',
                        }}
                      />
                    )}
                  </Box>
                  <Box
                    display={'flex'}
                    justifyContent="center"
                    alignItems={'center'}
                    width="100%"
                  >
                    <AccountLink
                      text="Already Have An Account?"
                      actionText={'Log In'}
                      onAction={() => navigate(routes.login)}
                    />
                  </Box>
                </PerfectScrollbar>
              </Box>
            </form>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
