import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Verify from '../common/Verify';
import LoginBG from '../../../Assets/Svg/login-bg.svg';
import MobileLoginBG from '../../../Assets/Svg/login-mobile-bg.svg';
import { useMediaQuery } from '@mui/material';
import routes from '../../../Constants/routes';
import {
  requestForOTP,
  verifyOTP,
  validateOtp,
  sendOtp,
  postDetails,
  signUp,
} from '../api';
import { useSnackbar } from 'notistack';
import { handleError } from '../util';
import { AuthContext } from '../../../Context/authContext';
import Carousel from 'nuka-carousel';
import { createUpdateApplication } from '../../../Utils/apiServices';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundImage: `url(${LoginBG})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${MobileLoginBG})`,
    },
  },
  carousel: {
    marginTop: '15%',
  },
}));
export default function VerifyEmail() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { dispatch } = useContext(AuthContext);
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [currentSlide, setCurrentSlide] = useState(0); // State to keep track of the current slide index

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const onSubmit = async ({ otp: { otp } }) => {
    setLoading(true);
    try {
      const res = await verifyOTP({
        otp,
        id: state?.email,
        course: state?.data?.course
      });

      if (!res || !res?.success) {
        return enqueueSnackbar(res?.errorMessage || 'Something went wrong', {
          variant: 'error',
        });
      } else {
        const resData = await createUpdateApplication({ applicant: state?.data });
        console.log(resData, "resData");
        if (resData) {
          const userData = {
            id: state?.id,
            applicantEmail: resData?.contactEmail,
            applicantId: resData?.contactId,
            applicantName: resData?.applicantName || '',
            applicantCampus: resData?.applicantCampus,
            formId: resData.applicationFormId,
            // formId: state?.formId
          }
          dispatch({
            type: 'LOGIN',
            payload: {
              user: userData,
            },
          });
        } else {
          enqueueSnackbar('Email ID already exists.', {
            variant: 'error',
          });
          setTimeout(() => navigate(routes.login), 3000);
        }
      }
    } catch (error) {
      error.response.data.errorMessages.map((error) => {
        enqueueSnackbar(error.errorMessage, {
          variant: 'error',
        }
      )
    })
      handleError(enqueueSnackbar, error);
    } finally {
      setLoading(false);
    }
  };
  const handleSlideChange = (index) => {
    setCurrentSlide(index); // Update the current slide index
  };
  return (
    <Box>
      {/* Conditionally render background image */}
      {!isSmallScreen && (
        <Box
          style={{
            position: 'relative',
            minHeight: '100vh',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            width: '100%',
            backgroundImage:
              'url("https://inazstgpfs3001.blob.core.windows.net/assets/Images/SAMSUNG/BackgroundPFS.png")',
          }}
        >
          <Box
            className={classes.container}
            style={{
              position: 'absolute',
              top: '50%',
              left: '80%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <form>
              <Verify
                loading={loading}
                from="verifyEmail"
                subTitle={
                  <>
                    <span>
                      Please enter the OTP sent to your registered Email ID.
                    </span>
                  </>
                }
                submitText="Verify & Sign Up"
                onVerify={(otp) => {
                  onSubmit({ otp });
                }}
                resendOTP={async () => {
                  await requestForOTP({ id: state?.email });
                }}
              ></Verify>
            </form>
          </Box>
        </Box>
      )}

      {/* Always render login content */}
      {isSmallScreen && (
        <Box
          className={classes.container}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '90vh',
          }}
        >
          <form>
            <Verify
              loading={loading}
              from="verifyEmail"
              subTitle={
                <>
                  <span>
                    Please enter the OTP sent to your registered Email ID.
                  </span>
                </>
              }
              submitText="Verify & Sign Up"
              onVerify={(otp) => {
                onSubmit({ otp });
              }}
              resendOTP={async () => {
                await requestForOTP({ id: state?.email });
              }}
            ></Verify>
          </form>
        </Box>
      )}
    </Box>
  );
}
