import { Divider, Grid } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useSnackbar } from 'notistack';

import { deleteApplicationRecords } from '../../Utils/apiServices';
import KenFieldContent from '../KenApplicationForm/Components/KenStepperContent/Components/KenFieldContent';
import KenButton from '../KenButton';
import { useTranslation } from 'react-i18next';
import { useApplicationContext } from '../../Context/applicationContext';
import _ from 'lodash';
import { removeArrayItem } from '../../Utils/Helpers/applicationHelper';
import { useAppContext } from '../../Context/appContext';

export default function FieldArray(props) {
  const {
    name,
    fieldsArray = [],
    defaultItemValue = {},
    metaData: { addMultiple = true, minItems = 0, maxItems = 5 } = {},
  } = props;

  // const { control} = useFormContext();
  const { fields, append, remove } = useFieldArray({
    // control, // control props comes from useForm (optional: if you are using FormContext)
    name: name, // unique name for your Field Array
  });

  const {
    applicationState: { applicationData },
    applicationDispatch,
  } = useApplicationContext();
  const { dispatch: dispatchAppContext } = useAppContext();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getValues } = useFormContext();

  useEffect(() => {
    if (minItems && fields.length < minItems) {
      for (let index = 0; index < minItems - fields.length; index++) {
        append(defaultItemValue);
      }
    }
    if (fields.length > maxItems) {
      while (fields.length > maxItems) {
        remove(fields.length - 1);
      }
    }
    console.log('==> ', minItems, fields);
  }, [minItems, maxItems, fields]);

  const isRemovable = (index) => {
    if (minItems && index < minItems) {
      return false;
    }
    return true;
  };

  const handleRemove = (itemName, index) => {
    // call api to remove object if recordId exists for this and update context
    const { recordId } = getValues(itemName) || {};
    console.log('removeItem:', getValues(itemName));

    if (recordId) {
      // Call api on success delete the record from form

      dispatchAppContext({ type: 'udpateLoading', value: true });
      deleteApplicationRecords({
        type: name,
        recordIds: [recordId],
      })
        .then((res) => {
          dispatchAppContext({ type: 'udpateLoading', value: false });
          if (res?.success) {
            remove(index);
            // Update applicationData
            applicationDispatch({
              type: 'update',
              payload: {
                applicationData: removeArrayItem(applicationData, name, index),
              },
            });
          } else {
            //TODO: Show alert
            enqueueSnackbar(t('messages:Application_RemoveItem_failed'), {
              variant: 'error',
            });
          }
        })
        .catch((err) => {
          dispatchAppContext({ type: 'udpateLoading', value: false });
          console.log('Remove items: ', err);
          //TODO: Show alert
          enqueueSnackbar(t('messages:Application_RemoveItem_failed'), {
            variant: 'error',
          });
        });
    } else {
      remove(index);
    }
  };

  return (
    <>
      {fields.map((field, index) => (
        <React.Fragment key={field.id}>
          {fieldsArray.map((fieldSchema) => (
            <KenFieldContent
              {...fieldSchema}
              name={`${name}.${index}.${fieldSchema.name}`}
            />
          ))}
          {isRemovable(index) && (
            <Grid item xs={12} textAlign={'right'}>
              <KenButton
                onClick={() => {
                  handleRemove(`${name}.${index}`, index);
                }}
              >
                Remove
              </KenButton>
            </Grid>
          )}
          {index !== fields.length - 1 && (
            <Grid item xs={12}>
              <Divider
                variant="middle"
                style={{ marginBottom: 1, marginTop: 1 }}
              />
            </Grid>
          )}
        </React.Fragment>
      ))}
      {addMultiple && fields.length < maxItems && (
        <Grid item xs={12} textAlign={'left'}>
          <KenButton
            onClick={() => {
              append({});
            }}
            style={{ height: '36px' }}
          >
            + Add new
          </KenButton>
        </Grid>
      )}
    </>
  );
}
