import React, { useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useApplicationContext } from '../../Context/applicationContext';
import { AuthContext } from '../../Context/authContext';
import { createUpdateApplication } from '../../Utils/apiServices';
import Instructions from '../KenApplicationForm/Components/InstructionsPage';
import KenStepperFooter from '../KenApplicationForm/Components/KenStepperFooter';
import KenLoader from '../KenLoader/index';

export default function InstructionsStep() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState();

  // Application context to update the applicationId, data and active step
  const {
    applicationState: { formId, form, applicationId, applicantId },
    applicationDispatch,
  } = useApplicationContext();
  const { dispatch } = useContext(AuthContext);

  const user = JSON.parse(sessionStorage.getItem('user'));
  console.log(user);

  // Create the application on next and update the url with applicationId params
  const handleNext = () => {
    setLoading(true);
    console.log('====> ', {
      applicationId: applicationId,
      application: {
        academicCycle: form.academicCycle,
        programId: form.programId,
        programName: form.programName,
        applicantId: user?.applicantId,
        applicationForm: form.recordId,
        currentStep: 0,
      },
    });

    // Create application
    createUpdateApplication({
      applicationId: applicationId,
      application: {
        academicCycle: form.academicCycle,
        programId: form.programId,
        programName: form.programName,
        applicantId: user?.applicantId,
        applicationForm: form.recordId,
        currentStep: 0,
      },
    })
      .then((res) => {
        // update url - must for proper functions on reload and applicationContect
        if (res.success) {
          applicationDispatch({
            activeStep: 0,
            applicationId: res.applicationId,
            applicationData: res?.data,
          });
          setSearchParams({
            formId: formId,
            applicationId: res.applicationId,
          });
          dispatch({
            type: 'LOGIN',
            payload: {
              user: {
                applicantEmail: user?.applicantEmail,
                applicantId: res?.data?.applicant?.recordId,
                applicantName: user?.applicantName,
                applicantDob: user?.applicantDob,
                applicantCampus: user?.applicantCampus,
              },
            },
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  return (
    <div>
      <div style={{ marginBottom: 70 }}>
        {loading && <KenLoader />}
        <Instructions />
      </div>
      {/* <Footer isSave={false} isPrev={false} onNext={handleNext} /> */}
      <KenStepperFooter isSave={false} isPrev={false} onNext={handleNext} />
    </div>
  );
}
