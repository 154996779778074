import React, { useEffect, useState, useContext } from 'react';
import { Box } from '@mui/system';
import KenFormBuilder from './Components/KenFormBuilder';
import { UploadDocumentContextProvider } from '../../Context/uploadDocumentContext';
import {
  getApplicationByApplicationId,
  getContactInfoByContactId,
  getFormByFormId,
} from '../../Utils/apiServices';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormContext } from '../../Context/formContext';
import KenLoader from '../KenLoader';
import { getNameById } from '../../Utils/Helpers/stringHelper';
import { useBack } from 'use-back';
import { REGEX } from '../../Constants/constant';
import { Country, State, City } from 'country-state-city';

const formJSON = [
  {
    stepId: 'Course Details',
    stepName: 'Course Details',
    showStepName: false,
    stepVisible: true,
    stepPosition: 0,
    stepType: 'form',
    sections: [
      // {
      // 	sectionId: "newApplicantRelations",
      // 	sectionName: "Family Details",
      // 	subSectionName: "",
      // 	sectionPosition: 1,
      // 	sectionVisible: true,
      // 	sectionHelp: "Fields marked with * are required",
      // 	sectionType: "form",
      // 	fields: [
      // 		{
      // fieldId: `newApplicantRelations1`,
      // // //required: true,
      // name: `newApplicantRelations1`,
      // label: "Family Info",
      // type: "addMultiple",
      // fieldsArray: [
      // 	{
      // 					fieldId: `firstName`,
      // 					// //required: true,
      // 					name: `firstName`,
      // 					label: "First Name",
      // 					type: "input",
      // 					// validations: [],
      // 					ui: {
      // 						grid: {
      // 							xs: 12,
      // 							md: 6,
      // 							lg: 6,
      // 						},
      // 					},
      // 				},
      // 				{
      // 					fieldId: `middleName`,
      // 					// //required: true,
      // 					name: `middleName`,
      // 					label: "Middle Name",
      // 					type: "input",
      // 					// validations: [],
      // 					ui: {
      // 						grid: {
      // 							xs: 12,
      // 							md: 6,
      // 							lg: 6,
      // 						},
      // 					},
      // 				},
      // 			],
      // 			apiKey: `application[studentMentorAssistance]`,
      // 			options: [
      // 				{ label: "Yes", value: "Yes" },
      // 				{ label: "No", value: "No" },
      // 			],
      // 			// validationType: "string",
      // 			ui: {
      // 				grid: {
      // 					xs: 12,
      // 					sm: 12,
      // 					md: 12,
      // 					lg: 12,
      // 				},
      // 			},
      // 		},
      // 		{
      // 			fieldId: `application[studentMentorAssistance]`,
      // 			// //required: true,
      // 			name: `application[studentMentorAssistance]`,
      // 			label:
      // 				"Would you like our student mentor to assist you in the admission process?",
      // 			type: "select",
      // 			apiKey: `application[studentMentorAssistance]`,
      // 			options: [
      // 				{ label: "Yes", value: "Yes" },
      // 				{ label: "No", value: "No" },
      // 			],
      // 			validationType: "string",
      // 			validations: [
      // 				// {
      // 				// 	type: "required",
      // 				// 	params: ["this field is required"],
      // 				// },
      // 			],
      // 			ui: {
      // 				grid: {
      // 					xs: 12,
      // 					md: 8,
      // 					lg: 8,
      // 				},
      // 			},
      // 		},
      // 	],
      // },
      {
        sectionId: 'CourseDetails1',
        sectionName: 'Course Details',
        requiredHint: true,
        subSectionName: '',
        sectionPosition: 1,
        sectionVisible: true,
        sectionHelp: 'Fields marked with * are required',
        sectionType: 'form',
        fields: [
          {
            fieldId: `application[studentMentorAssistance]`,
            required: true,
            name: `application[studentMentorAssistance]`,
            label:
              'Would you like our student mentor to assist you in the admission process?',
            type: 'select',
            apiKey: `application[studentMentorAssistance]`,
            options: [
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' },
            ],
            validationType: 'string',
            validations: { required: 'This field is required' },
            ui: {
              grid: {
                xs: 12,
                md: 8,
                lg: 8,
              },
            },
          },
        ],
      },
      {
        sectionId: 'ProgramDetails2',
        sectionName: '',
        subSectionName: '',
        showSectionName: false,
        sectionPosition: 2,
        sectionVisible: true,
        sectionHelp: '',
        sectionType: 'form',
        fields: [
          {
            fieldId: `application[programName]`,
            // //required: true,
            name: `application[programName]`,
            label: 'Program',
            type: 'input',
            options: [
              // { label: "Program 1", value: "p1" },
              // { label: "Program 2", value: "p2" },
              // { label: "Program 3", value: "p3" },
            ],
            disabled: true,
            apiKey: 'Program',
            validationType: 'string',
            validations: [
              // {
              // 	type: "required",
              // 	params: ["this field is required"],
              // },
            ],
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `application[campusId]`,
            required: true,
            name: `application[campusId]`,
            label: 'Campus',
            type: 'select',
            options: [],
            apiKey: `application[campusId]`,
            validations: { required: 'This field is required' },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `application[academicCycle]`,
            // //required: true,
            name: `application[academicCycle]`,
            label: 'Academic Cycle',
            type: 'input',
            apiKey: `application[academicCycle]`,
            validations: [],
            disabled: true,
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `application[specializationId]`,
            // //required: true,
            name: `application[specializationId]`,
            label: 'Specialization',
            type: 'select',
            apiKey: `application[specializationId]`,
            options: [
              // { label: "Specialization 1", value: "s1" },
              // { label: "Specialization 2", value: "s2" },
              // { label: "Specialization 3", value: "s3" },
            ],
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
        ],
      },
    ],
  },
  {
    stepId: 'basicDetails',
    stepName: 'Basic Details',
    showStepName: false,
    stepPosition: 1,
    stepVisible: true,
    stepType: 'form',
    showAccordionInMobile: true,
    sections: [
      {
        sectionName: 'Basic Details',
        subSectionName: '',
        sectionPosition: 1,
        sectionVisible: true,
        sectionType: 'form',
        fields: [
          {
            fieldId: `applicant[firstName]`,
            required: true,
            name: `applicant[firstName]`,
            label: 'First Name',
            type: 'input',
            disabled: true,
            validations: {
              required: 'This field is required',
              pattern: {
                value: REGEX.NAME,
                message: 'Please Input a valid First Name',
              },
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `applicant[middleName]`,
            // //required: true,
            name: `applicant[middleName]`,
            label: 'Middle Name',
            disabled: true,
            type: 'input',
            validations: {},
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },

          {
            fieldId: `applicant[lastName]`,
            required: true,
            name: `applicant[lastName]`,
            label: 'Last Name',
            disabled: true,
            type: 'input',
            validations: {
              required: 'This field is required',
              pattern: {
                value: REGEX.NAME,
                message: 'Please Input a valid Last Name',
              },
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `applicant[dateOfBirth]`,
            required: true,
            name: `applicant[dateOfBirth]`,
            label: 'Date of Birth',
            type: 'date',
            validations: {
              required: 'This field is required',
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `addresses[0][mailingCountry]`,
            required: true,
            name: `addresses[0][mailingCountry]`,
            isPartOfCollection: true,
            label: 'Country',
            type: 'select',
            validations: {
              required: 'This field is required',
            },
            options: [],
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `addresses[0][mailingState]`,
            required: true,
            name: `addresses[0][mailingState]`,
            label: 'State',
            type: 'select',
            validations: {
              required: 'This field is required',
            },
            options: [],
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `addresses[0][mailingDistrict]`,
            required: true,
            name: `addresses[0][mailingDistrict]`,
            label: 'City',
            type: 'select',
            validations: {
              required: 'This field is required',
            },
            options: [],
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `applicant[nationality]`,
            required: true,
            name: `applicant[nationality]`,
            label: 'Nationality',
            type: 'input',
            validations: {
              required: 'This field is required',
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          // {
          // 	fieldId: "passportId",
          // 	//required: true,
          // 	name: "passportId",
          // 	label: "Passport Id",
          // 	type: "input",
          // 	validations: [
          // 		{
          // 			type: "required",
          // 			dataType: "string",
          // 			message: "Please enter your name",
          // 		},
          // 	],
          // 	ui: {
          // 		grid: {
          // 			xs: 12,
          // 			md: 6,
          // 			lg: 6,
          // 		},
          // 	},
          // },
          {
            fieldId: `applicant[passportNumber]`,
            //required: true,
            name: `applicant[passportNumber]`,
            label: 'Passport Number',
            type: 'input',

            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `applicant[dateOfExpiry]`,
            //required: true,
            name: `applicant[dateOfExpiry]`,
            label: 'Passport Expiry',
            type: 'date',
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `addresses[1][mailingStreet]`,
            required: true,
            name: `addresses[1][mailingStreet]`,
            label: 'Current Address',
            type: 'input',
            validations: {
              required: 'This field is required',
              maxLength: {
                value: 200,
                message: 'This field cannot be more than 7 characters',
              },
            },
            ui: {
              grid: {
                xs: 12,
                md: 12,
                lg: 12,
              },
            },
          },
          {
            fieldId: `addresses[0][mailingStreet]`,
            required: true,
            name: `addresses[0][mailingStreet]`,
            label: 'Permanent Address',
            type: 'input',
            validations: {
              required: 'This field is required',
              maxLength: {
                value: 200,
                message: 'This field cannot be more than 200 characters',
              },
            },
            ui: {
              grid: {
                xs: 12,
                md: 12,
                lg: 12,
              },
            },
          },
          {
            fieldId: `addresses[0][mailingPostalCode]`,
            required: true,
            name: `addresses[0][mailingPostalCode]`,

            label: 'PIN / PO BOX/ ZIP',
            type: 'input',
            validations: {
              required: 'This field is required',
              maxLength: {
                value: 7,
                message: 'This field cannot be more than 7 characters',
              },
              pattern: {
                value: REGEX.ONLYNUMBER,
                message: 'Please Input a valid Zipcode',
              },
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `applicant[phone]`,
            required: true,
            name: `applicant[phone]`,
            label: 'Phone Number',
            type: 'phone',
            apiKey: `applicant[phone]`,
            disabled: true,
            validations: {
              required: 'This field is required',
              pattern: {
                value: REGEX.NUMBER,
                message: 'Please Input a valid Phone Number',
              },
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `applicant[email]`,
            required: true,
            name: `applicant[email]`,
            label: 'Email',
            disabled: true,
            type: 'input',
            validations: {
              required: 'This field is required',
              pattern: {
                value: REGEX.EMAIL,
                message: 'Please Input a valid Email',
              },
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `applicant[gender]`,
            required: true,
            name: `applicant[gender]`,
            label: 'Gender',
            type: 'radio',
            validations: {
              required: 'This field is required',
            },
            options: [
              { label: 'Male', value: 'Male' },
              { label: 'Female', value: 'Female' },
              { label: 'Decline to State', value: 'Decline to State' },
            ],
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `applicant[martialStatus]`,
            required: true,
            name: `applicant[martialStatus]`,
            label: 'Marital Status',
            type: 'radio',
            options: [
              { label: 'Single', value: 'Single' },
              { label: 'Married', value: 'Married' },
              { label: 'Other', value: 'Others' },
            ],
            validations: {
              required: 'This field is required',
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
        ],
      },

      {
        sectionId: 'familyInformation',
        sectionName: 'Family Information',
        subSectionName: '',
        sectionPosition: 2,
        sectionVisible: true,
        sectionType: 'form',
        fields: [
          {
            fieldId: `applicantRelations`,
            name: `applicantRelations`,
            label: 'Family Information',
            type: 'addMultiple',
            fieldsArray: [
              {
                fieldId: `relationshipType`,
                required: true,
                name: `relationshipType`,
                validations: {
                  required: 'This field is required',
                },
                label: 'Relationship With Applicant',
                type: 'select',
                options: [
                  { label: 'Father', value: 'Father' },
                  { label: 'Mother', value: 'Mother' },
                  { label: 'Others', value: 'Others' },
                ],
                ui: {
                  grid: {
                    xs: 12,
                    md: 6,
                    lg: 6,
                  },
                },
              },
              {
                fieldId: 'firstName',
                required: true,
                name: 'firstName',
                label: 'First Name',
                type: 'input',
                validations: {
                  required: 'This field is required',
                  pattern: {
                    value: REGEX.NAME,
                    message: 'Please Input a valid First Name',
                  },
                },
                ui: {
                  grid: {
                    xs: 12,
                    md: 12,
                    lg: 12,
                  },
                },
              },
              {
                fieldId: 'lastName',
                required: true,
                name: 'lastName',
                label: 'Last Name',
                type: 'input',
                validations: {
                  required: 'This field is required',
                  pattern: {
                    value: REGEX.NAME,
                    message: 'Please Input a valid Last Name',
                  },
                },
                ui: {
                  grid: {
                    xs: 12,
                    md: 6,
                    lg: 6,
                  },
                },
              },
              {
                fieldId: 'phone',
                name: 'phone',
                label: 'Mobile',
                required: true,
                type: 'phone',
                validations: {
                  required: 'This field is required',
                },
                ui: {
                  grid: {
                    xs: 12,
                    md: 6,
                    lg: 6,
                  },
                },
              },
              {
                fieldId: 'email',
                name: 'email',
                label: 'Email',
                type: 'input',
                required: true,
                validations: {
                  required: 'This field is required',
                  pattern: {
                    value: REGEX.EMAIL,
                    message: 'Please Input a valid Email',
                  },
                },
                ui: {
                  grid: {
                    xs: 12,
                    md: 6,
                    lg: 6,
                  },
                },
              },
              {
                fieldId: 'age',
                required: true,
                name: 'age',
                label: 'Age',
                type: 'input',
                validations: {
                  required: 'This field is required',
                  pattern: {
                    value: REGEX.ONLYNUMBER,
                    message: 'Please Input a valid Age',
                  },
                },
                ui: {
                  grid: {
                    xs: 12,
                    md: 6,
                    lg: 6,
                  },
                },
              },
              {
                fieldId: 'occupation',
                required: true,
                name: 'occupation',
                label: 'Occupation',
                type: 'input',
                validations: {
                  required: 'This field is required',
                },
                ui: {
                  grid: {
                    xs: 12,
                    md: 6,
                    lg: 6,
                  },
                },
              },
              {
                fieldId: 'annualIncome',
                //   required: true,
                name: 'annualIncome',
                label: 'Annual Income',
                type: 'input',
                //   validations: {
                //     required: 'This field is required',
                //     pattern: {
                //       value: {},
                //       message: 'Please Input a valid Income',
                //     },
                //   },
                ui: {
                  grid: {
                    xs: 12,
                    md: 6,
                    lg: 6,
                  },
                },
              },
              {
                fieldId: 'currencyType',
                required: true,
                name: 'currencyType',
                label: 'Currency Type',
                type: 'select',
                pattern: {
                  value: REGEX.NUMBERWITHDECIMAL,
                  message: 'Please Input a valid Income',
                },
                options: [
                  {
                    label: 'Australian Dollar (AUD)',
                    value: 'Australian Dollar (AUD)',
                  },
                  {
                    label: 'British Pound (GBP)',
                    value: 'British Pound (GBP)',
                  },
                  {
                    label: 'Canadian Dollar (CAD)',
                    value: 'Canadian Dollar (CAD)',
                  },
                  {
                    label: 'European Euro (EUR)',
                    value: 'European Euro (EUR)',
                  },
                  {
                    label: 'Indian Rupee (INR)',
                    value: 'Indian Rupee (INR)',
                  },
                  {
                    label: 'New Zealand Dollar (NZD)',
                    value: 'New Zealand Dollar (NZD)',
                  },
                  {
                    label: 'Singapore dollar (SGD)',
                    value: 'Singapore dollar (SGD)',
                  },
                  {
                    label: 'U.S. Dollar (USD)',
                    value: 'U.S. Dollar (USD)',
                  },
                  {
                    label: 'UAE Dirham (AED)',
                    value: 'UAE Dirham (AED)',
                  },
                ],
                ui: {
                  grid: {
                    xs: 12,
                    md: 6,
                    lg: 6,
                  },
                },
              },
            ],
            //   {
            //     fieldId: `relationshipType`,
            //     required: true,
            //     name: `relationshipType`,
            //     validations: {
            //       required: 'This field is required',
            //     },
            //     label: 'Relationship With Applicant',
            //     type: 'select',
            //     options: [
            //       { label: 'Father', value: 'Father' },
            //       { label: 'Mother', value: 'Mother' },
            //       { label: 'Others', value: 'Others' },
            //     ],
            //     ui: {
            //       grid: {
            //         xs: 12,
            //         md: 6,
            //         lg: 6,
            //       },
            //     },
            //   },
            //   {
            //     fieldId: `firstName`,
            //     required: true,
            //     name: `firstName`,
            //     label: 'First Name',
            //     type: 'input',
            //     validations: {
            //       required: 'This field is required',
            //       pattern: {
            //         value: REGEX.NAME,
            //         message: 'Please Input a valid First Name',
            //       },
            //     },
            //     ui: {
            //       grid: {
            //         xs: 12,
            //         md: 12,
            //         lg: 12,
            //       },
            //     },
            //   },
            //   {
            //     fieldId: `lastName`,
            //     required: true,
            //     name: `lastName`,
            //     label: 'Last Name',
            //     type: 'input',
            //     validations: {
            //       required: 'This field is required',
            //       pattern: {
            //         value: REGEX.NAME,
            //         message: 'Please Input a valid Last Name',
            //       },
            //     },
            //     ui: {
            //       grid: {
            //         xs: 12,
            //         md: 6,
            //         lg: 6,
            //       },
            //     },
            //   },

            //   {
            //     fieldId: `age`,
            //     required: true,
            //     name: `age`,
            //     label: 'Age',
            //     type: 'input',
            //     validations: {
            //       required: 'This field is required',
            // pattern: {
            //   value: REGEX.ONLYNUMBER,
            //   message: 'Please Input a valid Age',
            // },
            //     },
            //     ui: {
            //       grid: {
            //         xs: 12,
            //         md: 6,
            //         lg: 6,
            //       },
            //     },
            //   },
            //   {
            //     fieldId: `occupation`,
            //     required: true,
            //     name: `occupation`,
            //     label: 'Occupation',
            //     type: 'input',
            //     validations: {
            //       required: 'This field is required',
            //     },
            //     ui: {
            //       grid: {
            //         xs: 12,
            //         md: 6,
            //         lg: 6,
            //       },
            //     },
            //   },
            //   {
            //     fieldId: `annualIncome`,
            //     required: true,
            //     name: `annualIncome`,
            //     label: 'Annual Income',
            //     type: 'input',
            //     validations: {
            //       required: 'This field is required',
            // pattern: {
            //   value: REGEX.NUMBERWITHDECIMAL,
            //   message: 'Please Input a valid Income',
            // },
            //     },
            //     ui: {
            //       grid: {
            //         xs: 12,
            //         md: 6,
            //         lg: 6,
            //       },
            //     },
            //   },
            // ],
          },
        ],
      },
      {
        sectionId: 'employmentDetails',
        sectionName: 'Employment Details',
        subSectionName: '',
        sectionPosition: 3,
        sectionVisible: true,
        sectionType: 'form',
        fields: [
          // {
          //     fieldId: `doYouHaveAnyWorkExperience`,
          //     name: `doYouHaveAnyWorkExperience`,
          //     //required: true,
          //     label: "Do you have any work experience or articleship",
          //     type: "radio",
          //     options: [
          //         { label: "Yes", value: "Yes" },
          //         { label: "No", value: "No" },
          //     ],
          //     validations: [],
          //     ui: {
          //         grid: {
          //             xs: 12,
          //             md: 6,
          //             lg: 6,
          //         },
          //     },
          // },
          {
            fieldId: `workExperiences`,
            name: `workExperiences`,
            //required: true,
            label: 'Work Experiences',
            type: 'addMultiple',
            fieldsArray: [
              {
                fieldId: `doYouHaveAnyWorkExperience`,
                name: `doYouHaveAnyWorkExperience`,
                // required: true,
                label: 'Do you have any work experience or articleship',
                type: 'radio',
                options: [
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                ],
                validations: [],
                ui: {
                  grid: {
                    xs: 12,
                    md: 6,
                    lg: 6,
                  },
                },
                radioSx: {
                  '&.Mui-checked': {
                    color: '#25AE88',
                  },
                }
              },
              {
                fieldId: `jobType`,
                //required: true,
                name: `jobType`,
                label: 'Job Type',
                type: 'select',

                options: [
                  { label: 'Full Time', value: 'Full Time' },
                  { label: 'Part Time', value: 'Part Time' },
                  { label: 'Internship', value: 'Internship' },
                  { label: 'Articleship', value: 'Articleship' },
                  {
                    label: 'Full Time (Still Working)',
                    value: 'Full Time (Still Working)',
                  },
                ],
                conditional: true,
                condition: {
                  stepId: 'basicDetails',
                  sectionId: 'employmentDetails',
                  fieldId: 'workExperiences',
                  fieldName: 'workExperiences',
                  subFieldId: 'doYouHaveAnyWorkExperience',
                  subFieldName: 'doYouHaveAnyWorkExperience',
                  conditionType: {
                    operation: 'equals',
                    value: 'Yes',
                  },
                  behaviorType: 'DISPLAY_CONTROL',
                  control: {
                    display: true,
                  },
                },
                validations: [],
                ui: {
                  grid: {
                    xs: 12,
                    md: 6,
                    lg: 6,
                  },
                },
              },
              {
                fieldId: `organisationName`,
                //required: true,
                name: `organisationName`,
                label: 'Company',
                type: 'input',
                conditional: true,
                condition: {
                  stepId: 'basicDetails',
                  sectionId: 'employmentDetails',
                  fieldId: 'workExperiences',
                  fieldName: 'workExperiences',
                  subFieldId: 'doYouHaveAnyWorkExperience',
                  subFieldName: 'doYouHaveAnyWorkExperience',
                  conditionType: {
                    operation: 'equals',
                    value: 'Yes',
                  },
                  behaviorType: 'DISPLAY_CONTROL',
                  control: {
                    display: true,
                  },
                },
                validations: [],
                ui: {
                  grid: {
                    xs: 12,
                    md: 6,
                    lg: 6,
                  },
                },
              },
              {
                fieldId: `industryType`,
                //required: true,
                name: `industryType`,
                label: 'Industry Type',
                type: 'input',
                conditional: true,
                condition: {
                  stepId: 'basicDetails',
                  sectionId: 'employmentDetails',
                  fieldId: 'workExperiences',
                  fieldName: 'workExperiences',
                  subFieldId: 'doYouHaveAnyWorkExperience',
                  subFieldName: 'doYouHaveAnyWorkExperience',
                  conditionType: {
                    operation: 'equals',
                    value: 'Yes',
                  },
                  behaviorType: 'DISPLAY_CONTROL',
                  control: {
                    display: true,
                  },
                },
                validations: [],
                ui: {
                  grid: {
                    xs: 12,
                    md: 6,
                    lg: 6,
                  },
                },
              },
              {
                fieldId: `location`,
                //required: true,
                name: `location`,
                label: 'Location',
                type: 'input',
                conditional: true,
                condition: {
                  stepId: 'basicDetails',
                  sectionId: 'employmentDetails',
                  fieldId: 'workExperiences',
                  fieldName: 'workExperiences',
                  subFieldId: 'doYouHaveAnyWorkExperience',
                  subFieldName: 'doYouHaveAnyWorkExperience',
                  conditionType: {
                    operation: 'equals',
                    value: 'Yes',
                  },
                  behaviorType: 'DISPLAY_CONTROL',
                  control: {
                    display: true,
                  },
                },
                validations: [],
                ui: {
                  grid: {
                    xs: 12,
                    md: 6,
                    lg: 6,
                  },
                },
              },
              {
                fieldId: `designation`,
                //required: true,
                name: `designation`,
                label: 'Roles /KRA',
                type: 'input',
                conditional: true,
                condition: {
                  stepId: 'basicDetails',
                  sectionId: 'employmentDetails',
                  fieldId: 'workExperiences',
                  fieldName: 'workExperiences',
                  subFieldId: 'doYouHaveAnyWorkExperience',
                  subFieldName: 'doYouHaveAnyWorkExperience',
                  conditionType: {
                    operation: 'equals',
                    value: 'Yes',
                  },
                  behaviorType: 'DISPLAY_CONTROL',
                  control: {
                    display: true,
                  },
                },
                validations: [],
                ui: {
                  grid: {
                    xs: 12,
                    md: 6,
                    lg: 6,
                  },
                },
              },
              {
                fieldId: `dateOfJoining`,
                //required: true,
                name: `dateOfJoining`,
                label: 'Date Of Joining',
                type: 'date',
                validations: [],
                conditional: true,
                condition: {
                  stepId: 'basicDetails',
                  sectionId: 'employmentDetails',
                  fieldId: 'workExperiences',
                  fieldName: 'workExperiences',
                  subFieldId: 'doYouHaveAnyWorkExperience',
                  subFieldName: 'doYouHaveAnyWorkExperience',
                  conditionType: {
                    operation: 'equals',
                    value: 'Yes',
                  },
                  behaviorType: 'DISPLAY_CONTROL',
                  control: {
                    display: true,
                  },
                },
                ui: {
                  grid: {
                    xs: 12,
                    md: 6,
                    lg: 6,
                  },
                },
              },
              {
                fieldId: `dateOfLeaving`,
                //required: true,
                name: `dateOfLeaving`,
                label: 'Date Of Leaving',
                type: 'date',
                conditional: true,
                condition: {
                  stepId: 'basicDetails',
                  sectionId: 'employmentDetails',
                  fieldId: 'workExperiences',
                  fieldName: 'workExperiences',
                  subFieldId: 'doYouHaveAnyWorkExperience',
                  subFieldName: 'doYouHaveAnyWorkExperience',
                  conditionType: {
                    operation: 'equals',
                    value: 'Yes',
                  },
                  behaviorType: 'DISPLAY_CONTROL',
                  control: {
                    display: true,
                  },
                },
                validations: [],
                ui: {
                  grid: {
                    xs: 12,
                    md: 6,
                    lg: 6,
                  },
                },
              },
              {
                fieldId: `currencyType`,
                //required: true,
                name: `currencyType`,
                label: 'Currency Type',
                type: 'select',

                options: [
                  {
                    label: 'Australian Dollar (AUD)',
                    value: 'Australian Dollar (AUD)',
                  },
                  {
                    label: 'British Pound (GBP)',
                    value: 'British Pound (GBP)',
                  },
                  {
                    label: 'Canadian Dollar (CAD)',
                    value: 'Canadian Dollar (CAD)',
                  },
                  {
                    label: 'European Euro (EUR)',
                    value: 'European Euro (EUR)',
                  },
                  { label: 'Indian Rupee (INR)', value: 'Indian Rupee (INR)' },
                  {
                    label: 'New Zealand Dollar (NZD)',
                    value: 'New Zealand Dollar (NZD)',
                  },
                  {
                    label: 'Singapore dollar (SGD)',
                    value: 'Singapore dollar (SGD)',
                  },
                  { label: 'U.S. Dollar (USD)', value: 'U.S. Dollar (USD)' },
                  { label: 'UAE Dirham (AED)', value: 'UAE Dirham (AED)' },
                ],
                conditional: true,
                condition: {
                  stepId: 'basicDetails',
                  sectionId: 'employmentDetails',
                  fieldId: 'workExperiences',
                  fieldName: 'workExperiences',
                  subFieldId: 'doYouHaveAnyWorkExperience',
                  subFieldName: 'doYouHaveAnyWorkExperience',
                  conditionType: {
                    operation: 'equals',
                    value: 'Yes',
                  },
                  behaviorType: 'DISPLAY_CONTROL',
                  control: {
                    display: true,
                  },
                },
                validations: [],
                ui: {
                  grid: {
                    xs: 12,
                    md: 6,
                    lg: 6,
                  },
                },
              },
              {
                fieldId: `annualSalary`,
                //required: true,
                name: `annualSalary`,
                label: 'Annual Salary',
                type: 'input',
                conditional: true,
                condition: {
                  stepId: 'basicDetails',
                  sectionId: 'employmentDetails',
                  fieldId: 'workExperiences',
                  fieldName: 'workExperiences',
                  subFieldId: 'doYouHaveAnyWorkExperience',
                  subFieldName: 'doYouHaveAnyWorkExperience',
                  conditionType: {
                    operation: 'equals',
                    value: 'Yes',
                  },
                  behaviorType: 'DISPLAY_CONTROL',
                  control: {
                    display: true,
                  },
                },
                validations: [],
                ui: {
                  grid: {
                    xs: 12,
                    md: 6,
                    lg: 6,
                  },
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    stepId: 'educationDetails',
    stepName: 'Education Details',
    stepPosition: 2,
    showStepName: false,
    stepVisible: true,
    showAccordionInMobile: true,
    stepType: 'form',
    sections: [
      {
        sectionName: 'Education Details',
        sectionId: 'postGraduation',
        subSectionName: 'Post Graduation',
        sectionPosition: 1,
        sectionVisible: true,
        sectionType: 'form',
        fields: [
          {
            fieldId: `postGraduation[yearOfPassing]`,
            //required: true,
            name: `postGraduation[yearOfPassing]`,
            label: 'Year of completion',
            type: 'date',
            validations: [],
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `postGraduation[institutionName]`,
            //required: true,
            name: `postGraduation[institutionName]`,
            label: 'University/Board',
            type: 'input',
            validations: [],
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },

          {
            fieldId: `postGraduation[obtainedMarks]`,
            required: true,
            name: `postGraduation[obtainedMarks]`,
            label: 'Percentage/CGPA',
            type: 'input',
            validations: {
              required: 'This field is required',
              pattern: {
                value: REGEX.ONLYNUMBER,
                message: 'This field is should be a number',
              },
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `postGraduation[educationType]`,
            //required: true,
            name: `postGraduation[educationType]`,
            label: 'Education Type',
            type: 'select',
            options: [
              { label: 'Full time', value: 'Full time' },
              { label: 'Part time', value: 'Part time' },
              { label: 'Distance', value: 'Distance' },
            ],
            validations: [],
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },

          {
            fieldId: `postGraduation[degreeEarned]`,
            //required: true,
            name: `postGraduation[degreeEarned]`,
            label: 'Degree',
            type: 'select',
            options: [
              { label: 'AA', value: 'AA' },
              { label: 'AS', value: 'AS' },
              { label: 'BA', value: 'BA' },
              { label: 'BS', value: 'BS' },
              { label: 'GED', value: 'GED' },
              { label: 'High School Diploma', value: 'High School Diploma' },
              { label: 'JD', value: 'JD' },
              { label: 'MA', value: 'MA' },
              { label: 'MBA', value: 'MBA' },
              { label: 'MD', value: 'MD' },
              { label: 'MS', value: 'MS' },
              { label: 'PhD', value: 'PhD' },
              { label: 'Other', value: 'Other' },
            ],
            validations: [],
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          // {
          // 	fieldId: "nameOfInstitutePostGrad",
          // 	//required: true,
          // 	name: "nameOfInstitutePostGrad",
          // 	label: "University/Institution/ College With Campus Name",
          // 	type: "input",
          // 	validations: [],
          // 	ui: {
          // 		grid: {
          // 			xs: 12,
          // 			md: 6,
          // 			lg: 6,
          // 		},
          // 	},
          // },
          {
            fieldId: `postGraduation[city]`,
            //required: true,
            name: `postGraduation[city]`,
            label: 'City',
            type: 'input',
            validations: [],
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
        ],
      },

      {
        sectionId: 'graduation',
        sectionName: '',
        subSectionName: 'Graduation',
        sectionPosition: 2,
        sectionVisible: true,
        sectionType: 'form',
        mergePrevMobile: true,
        fields: [
          {
            fieldId: `graduation[yearOfPassing]`,
            //required: true,
            name: `graduation[yearOfPassing]`,
            label: 'Year of completion',
            type: 'date',
            validations: [],
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `graduation[institutionName]`,
            //required: true,
            name: `graduation[institutionName]`,
            label: 'University/Board',
            type: 'input',
            validations: [],
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },

          {
            fieldId: `graduation[obtainedMarks]`,
            required: true,
            name: `graduation[obtainedMarks]`,
            label: 'Percentage/CGPA',
            type: 'input',
            validations: {
              required: 'This field is required',
              pattern: {
                value: REGEX.ONLYNUMBER,
                message: 'This field is should be a number',
              },
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `graduation[educationType]`,
            //required: true,
            name: `graduation[educationType]`,
            label: 'Education Type',
            type: 'select',
            options: [
              { label: 'Full time', value: 'Full time' },
              { label: 'Part time', value: 'Part time' },
              { label: 'Distance', value: 'Distance' },
            ],
            validations: [],
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `graduation[degreeEarned]`,
            //required: true,
            name: `graduation[degreeEarned]`,
            label: 'Degree',
            type: 'select',
            options: [
              { label: 'AA', value: 'AA' },
              { label: 'AS', value: 'AS' },
              { label: 'BA', value: 'BA' },
              { label: 'BS', value: 'BS' },
              { label: 'GED', value: 'GED' },
              { label: 'High School Diploma', value: 'High School Diploma' },
              { label: 'JD', value: 'JD' },
              { label: 'MA', value: 'MA' },
              { label: 'MBA', value: 'MBA' },
              { label: 'MD', value: 'MD' },
              { label: 'MS', value: 'MS' },
              { label: 'PhD', value: 'PhD' },
              { label: 'Other', value: 'Other' },
            ],
            validations: [],
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          // {
          // 	fieldId: "nameOfInstituteGrad",
          // 	//required: true,
          // 	name: "nameOfInstituteGrad",
          // 	label: "University/Institution/ College With Campus Name",
          // 	type: "input",
          // 	validations: [],
          // 	ui: {
          // 		grid: {
          // 			xs: 12,
          // 			md: 6,
          // 			lg: 6,
          // 		},
          // 	},
          // },
          {
            fieldId: `graduation[placeOfStudy]`,
            //required: true,
            name: `graduation[placeOfStudy]`,
            label: 'City',
            type: 'input',
            validations: [],
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
        ],
      },
      {
        sectionId: 'semester',
        sectionName: '',
        subSectionName: 'Semester/Year-Wise Graduation Marks Details',
        sectionPosition: 3,
        sectionVisible: true,
        mergePrevMobile: true,
        sectionType: 'form',
        fields: [
          {
            fieldId: 'graduationMarks[semesteryearwiseMarks]',
            required: true,
            name: 'graduationMarks[semesteryearwiseMarks]',
            label: 'Type',
            type: 'select',
            options: [
              { label: 'Semester', value: 'Semester' },
              { label: 'Year', value: 'Year' },
            ],
            validations: [],
            ui: {
              grid: {
                xs: 12,
                md: 12,
                lg: 12,
              },
            },
          },

          {
            fieldId: `graduationMarks[semesterwiseMarks]`,
            name: `graduationMarks[semesterwiseMarks]`,
            //required: true,
            label: 'Semester wise marks',
            type: 'addMultiple',
            conditional: true,
            condition: {
              stepId: 'educationDetails',
              sectionId: 'semester',
              fieldId: 'graduationMarks[semesteryearwiseMarks]',
              fieldName: 'graduationMarks[semesteryearwiseMarks]',
              conditionType: {
                operation: 'equals',
                value: 'Semester',
              },
              behaviorType: 'DISPLAY_CONTROL',
              control: {
                display: true,
              },
            },
            fieldsArray: [
              {
                fieldId: 'semesterNumber',
                required: true,
                name: 'semesterNumber',
                label: 'Select semester',
                type: 'select',
                options: [
                  { label: 'Semester 1', value: 'semester1Marks' },
                  { label: 'Semester 2', value: 'semester2Marks' },
                  { label: 'Semester 3', value: 'semester3Marks' },
                  { label: 'Semester 4', value: 'semester4Marks' },
                  { label: 'Semester 5', value: 'semester5Marks' },
                  { label: 'Semester 6', value: 'semester6Marks' },
                  { label: 'Semester 7', value: 'semester7Marks' },
                  { label: 'Semester 8', value: 'semester8Marks' },
                ],
                validations: [],
                ui: {
                  grid: {
                    xs: 12,
                    md: 6,
                    lg: 6,
                  },
                },
              },
              {
                fieldId: 'semesterMarks',
                //required: true,
                name: 'semesterMarks',
                label: 'Total Marks',
                type: 'input',
                validations: [],
                ui: {
                  grid: {
                    xs: 12,
                    md: 6,
                    lg: 6,
                  },
                },
              },
            ],
          },
          {
            fieldId: `graduationMarks[yearwiseMarks]`,
            name: `graduationMarks[yearwiseMarks]`,
            //required: true,
            label: 'Year wise marks',
            type: 'addMultiple',
            conditional: true,
            condition: {
              stepId: 'educationDetails',
              sectionId: 'semester',
              fieldId: 'graduationMarks[semesteryearwiseMarks]',
              fieldName: 'graduationMarks[semesteryearwiseMarks]',
              conditionType: {
                operation: 'equals',
                value: 'Year',
              },
              behaviorType: 'DISPLAY_CONTROL',
              control: {
                display: true,
              },
            },
            fieldsArray: [
              {
                fieldId: 'yearNumber',
                required: true,
                name: 'yearNumber',
                label: 'Select Year',
                type: 'select',
                options: [
                  { label: 'Year 1', value: 'year1' },
                  { label: 'Year 2', value: 'year2' },
                  { label: 'Year 3', value: 'year3' },
                  { label: 'Year 4', value: 'year4' },
                ],
                validations: [],
                ui: {
                  grid: {
                    xs: 12,
                    md: 6,
                    lg: 6,
                  },
                },
              },
              {
                fieldId: 'yearMarks',
                //required: true,
                name: 'yearMarks',
                label: 'Total Marks',
                type: 'input',
                validations: [],
                ui: {
                  grid: {
                    xs: 12,
                    md: 6,
                    lg: 6,
                  },
                },
              },
            ],
          },
        ],
      },
      {
        sectionId: 'hsc',
        sectionName: '',
        subSectionName: 'Grade 12/High School',
        sectionPosition: 4,
        sectionVisible: true,
        mergePrevMobile: true,
        sectionType: 'form',
        fields: [
          {
            fieldId: `hsc[degreeCGPADGPA]`,
            name: `hsc[degreeCGPADGPA]`,
            required: true,
            label: 'Grade Type',
            type: 'select',
            options: [
              { label: 'CGPA', value: 'CGPA' },
              { label: 'GPA', value: 'GPA' },
              { label: 'Grade', value: 'Grade' },
              { label: 'Percentage', value: 'Percentage' },
            ],
            validations: {
              required: 'This field is required',
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `hsc[educationType]`,
            name: `hsc[educationType]`,
            required: true,
            label: 'Education Type',
            type: 'select',
            options: [
              { label: 'Full time', value: 'Full time' },
              { label: 'Part time', value: 'Part time' },
              { label: 'Distance', value: 'Distance' },
            ],
            validations: {
              required: 'This field is required',
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `hsc[obtainedMarks]`,
            required: true,
            name: `hsc[obtainedMarks]`,
            label: 'Final Grade',
            type: 'input',
            validations: {
              required: 'This field is required',
              pattern: {
                value: REGEX.MIXED,
                message: 'This is not a valid',
              },
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `hsc[yearOfPassing]`,
            required: true,
            name: `hsc[yearOfPassing]`,
            label: 'Completion year',
            type: 'date',
            validations: {
              required: 'This field is required',
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `hsc[placeOfStudy]`,
            required: true,
            name: `hsc[placeOfStudy]`,
            label: 'School Name',
            type: 'input',
            validations: {
              required: 'This field is required',
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: 'hsc[country]',
            required: true,
            name: 'hsc[country]',
            label: 'Country',
            type: 'select',
            options: [],
            validations: [],
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: 'hsc[state]',
            required: true,
            name: 'hsc[state]',
            label: 'State',
            type: 'select',
            options: [],
            validations: [],
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `hsc[city]`,
            required: true,
            name: `hsc[city]`,
            label: 'City',
            type: 'select',
            options: [],
            // validations: {
            //   required: 'This field is required',
            // },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
        ],
      },
      {
        sectionId: 'ssc',
        sectionName: '',
        subSectionName: 'Grade 10/High School',
        sectionPosition: 5,
        sectionVisible: true,
        mergePrevMobile: true,
        sectionType: 'form',
        fields: [
          {
            fieldId: `ssc[degreeCGPADGPA]`,
            name: `ssc[degreeCGPADGPA]`,
            required: true,
            label: 'Grade Type',
            type: 'select',
            options: [
              { label: 'CGPA', value: 'CGPA' },
              { label: 'GPA', value: 'GPA' },
              { label: 'Grade', value: 'Grade' },
              { label: 'Percentage', value: 'Percentage' },
            ],
            validations: {
              required: 'This field is required',
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `ssc[educationType]`,
            name: `ssc[educationType]`,
            required: true,
            label: 'Education Type',
            type: 'select',
            options: [
              { label: 'Full time', value: 'Full time' },
              { label: 'Part time', value: 'Part time' },
              { label: 'Distance', value: 'Distance' },
            ],
            validations: {
              required: 'This field is required',
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `ssc[obtainedMarks]`,
            required: true,
            name: `ssc[obtainedMarks]`,
            label: 'Final Grade',
            type: 'input',
            validations: {
              required: 'This field is required',
              pattern: {
                value: REGEX.MIXED,
                message: 'This is not a valid value',
              },
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `ssc[yearOfPassing]`,
            required: true,
            name: `ssc[yearOfPassing]`,
            label: 'Completion year',
            type: 'date',
            validations: {
              required: 'This field is required',
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `ssc[placeOfStudy]`,
            required: true,
            name: `ssc[placeOfStudy]`,
            label: 'School Name',
            type: 'input',
            validations: {
              required: 'This field is required',
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: 'ssc[country]',
            required: true,
            name: 'ssc[country]',
            label: 'Country',
            type: 'select',
            options: [],
            validations: [],
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: 'ssc[state]',
            required: true,
            name: 'ssc[state]',
            label: 'State',
            type: 'select',
            options: [],
            validations: [],
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `ssc[city]`,
            required: true,
            name: `ssc[city]`,
            label: 'City',
            type: 'select',
            options: [],
            // validations: {
            //   required: 'This field is required',
            // },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
        ],
      },
      {
        sectionId: 'entranceExams',
        sectionName: '',
        subSectionName: 'Admission Test Score Details',
        sectionPosition: 6,
        sectionVisible: true,
        mergePrevMobile: true,
        sectionType: 'form',
        fields: [
          {
            fieldId: 'entranceExams[0][entrance]',
            name: 'entranceExams[0][entrance]',
            required: true,
            label: 'Have you gone through any entrance/evaluation test',
            type: 'select',
            options: [
              {
                label: 'Yes',
                value: 'Yes',
              },
              {
                label: 'No',
                value: 'No',
              },
            ],
            validations: {
              required: 'This field is required',
            },
            ui: {
              grid: {
                xs: 12,
                md: 12,
                lg: 12,
              },
            },
          },
          {
            fieldId: 'entranceExams[0][selectExam]',
            required: false,
            name: 'entranceExams[0][selectExam]',
            label: 'Test Type',
            conditional: true,
            condition: {
              stepId: 'educationDetails',
              sectionId: 'entranceExams',
              fieldId: 'entranceExams[0][entrance]',
              fieldName: 'entranceExams[0][entrance]',
              conditionType: {
                operation: 'equals',
                value: 'Yes',
              },
              behaviorType: 'DISPLAY_CONTROL',
              control: {
                display: true,
              },
            },
            type: 'select',
            options: [
              {
                label: 'SPJAT',
                value: 'SPJAT',
              },
              {
                label: 'SAT',
                value: 'SAT',
              },
              {
                label: 'GMAT',
                value: 'GMAT',
              },
              {
                label: 'GRE',
                value: 'GRE',
              },
            ],
            // validations: {
            //   required: 'This field is required',
            // },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: 'entranceExams[0][resultStatus]',
            required: false,
            name: 'entranceExams[0][resultStatus]',
            label: 'Result',
            conditional: true,
            condition: {
              stepId: 'educationDetails',
              sectionId: 'entranceExams',
              fieldId: 'entranceExams[0][entrance]',
              fieldName: 'entranceExams[0][entrance]',
              conditionType: {
                operation: 'equals',
                value: 'Yes',
              },
              behaviorType: 'DISPLAY_CONTROL',
              control: {
                display: true,
              },
            },
            type: 'select',
            options: [
              {
                label: 'Pending',
                value: 'Pending',
              },
              {
                label: 'Announced',
                value: 'Announced',
              },
            ],
            // validations: {
            //   required: 'This field is required',
            // },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: 'entranceExams[0][score]',
            required: false,
            name: 'entranceExams[0][score]',
            label: 'Score',
            conditional: true,
            condition: {
              stepId: 'educationDetails',
              sectionId: 'entranceExams',
              fieldId: 'entranceExams[0][resultStatus]',
              fieldName: 'resultStatusExams[0][entrance]',
              conditionType: {
                operation: 'equals',
                value: 'Announced',
              },
              behaviorType: 'DISPLAY_CONTROL',
              control: {
                display: true,
              },
            },
            type: 'input',
            // validations: {
            //   required: 'This field is required',
            // },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
        ],
      },
      {
        sectionId: 'otherInfo',
        sectionName: 'Other Information',
        subSectionName: '',
        sectionPosition: 6,
        sectionVisible: true,
        sectionType: 'form',
        fields: [
          {
            fieldId: `application[sportsAchievements]`,
            name: `application[sportsAchievements]`,
            //required: true,
            label: 'Sport Achievement',
            type: 'input',
            validations: {},
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `application[professionalAchievements]`,
            //required: true,
            name: `application[professionalAchievements]`,
            label: 'Professional Achievement',
            type: 'input',
            validations: {},
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `application[extraCurricularActivities]`,
            //required: true,
            name: `application[extraCurricularActivities]`,
            label:
              'Extra Curricular Activities (social, political, personal etc)',
            type: 'input',
            validations: {},
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `application[exceptionalAcademicAchievements]`,
            name: `application[exceptionalAcademicAchievements]`,
            //required: true,
            label: 'Exceptional Academic Achievements',
            type: 'input',
            validations: {},
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `application[howDoYouPlanToFundYourEducation]`,
            required: true,
            name: `application[howDoYouPlanToFundYourEducation]`,
            label: 'How do you plan to fund your education?',
            type: 'select',
            options: [
              { label: 'Self-funding', value: 'Self-funding' },
              { label: 'Bank Loan Only', value: 'Bank Loan Only' },
              {
                label: 'Self-funding and Scholarship',
                value: 'Self-funding and Scholarship',
              },
              {
                label: 'Bank Loan and Scholarship',
                value: 'Bank Loan and Scholarship',
              },
              {
                label: 'Self-funding + Bank Loan',
                value: 'Self-funding + Bank Loan',
              },
              { label: 'Others', value: 'Others' },
            ],
            validations: {
              required: 'This field is required',
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `application[fundDetails]`,
            required: true,
            name: `application[fundDetails]`,
            label: 'Please Specify',
            conditional: true,
            condition: {
              stepId: 'educationDetails',
              sectionId: 'otherInfo',
              fieldId: 'application[howDoYouPlanToFundYourEducation]',
              fieldName: 'application[howDoYouPlanToFundYourEducation]',
              conditionType: {
                operation: 'equals',
                value: 'Others',
              },
              behaviorType: 'DISPLAY_CONTROL',
              control: {
                display: true,
              },
            },
            type: 'input',
            validations: {
              required: 'This field is required',
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `applicant[isDifferentlyAbled]`,
            required: true,
            name: `applicant[isDifferentlyAbled]`,
            label:
              'Are you physically challenged or suffering from permanent disability?',
            type: 'radio',
            options: [
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' },
            ],
            validations: {
              required: 'This field is required',
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: 'specialAbilityDetails',
            required: true,
            conditional: true,
            condition: {
              stepId: 'educationDetails',
              sectionId: 'otherInfo',
              fieldId: 'applicant[isDifferentlyAbled]',
              fieldName: 'applicant[isDifferentlyAbled]',
              conditionType: {
                operation: 'equals',
                value: 'Yes',
              },
              behaviorType: 'DISPLAY_CONTROL',
              control: {
                display: true,
              },
            },
            name: 'specialAbilityDetails',
            label: 'Please share details',
            type: 'input',
            validations: {
              required: 'This field is required',
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `application[statementOfPurpose]`,
            required: true,
            name: `application[statementOfPurpose]`,
            label: 'Statement of purpose',
            type: 'textArea',
            validations: {
              required: 'This field is required',
              maxLength: {
                value: 800,
                message: 'This field cannot be more than 800 characters',
              },
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `application[mastersPlan]`,
            required: false,
            name: `application[mastersPlan]`,
            label:
              'What do you plan to do after finishing a master’s degree at S P Jain?',
            type: 'textArea',
            validations: {
              maxLength: {
                value: 800,
                message: 'This field cannot be more than 800 characters',
              },
            },
            options: [],
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `application[companySponsorship]`,
            required: false,
            name: `application[companySponsorship]`,
            label: 'Are you sponsored by your company?',
            type: 'radio',
            options: [
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' },
            ],
            // validations: {
            //   required: 'This field is required',
            // },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
        ],
      },
      {
        sectionId: 'languageInfo',
        sectionName: 'Language Proficiency Details',
        subSectionName: '',
        sectionPosition: 7,
        sectionVisible: true,
        sectionType: 'form',
        fields: [
          {
            fieldId: `application[languageSpokenAtHome]`,
            required: true,
            name: `application[languageSpokenAtHome]`,
            label: 'Mother Tongue',
            type: 'select',
            options: [
              { label: 'Assamese', value: 'Assamese' },
              { label: 'Bengali', value: 'Bengali' },
              { label: 'English', value: 'English' },
              { label: 'Gujarati', value: 'Gujarati' },
              { label: 'Hindi', value: 'Hindi' },
              { label: 'Kannada', value: 'Kannada' },
              { label: 'Malayalam', value: 'Malayalam' },
              { label: 'Marathi', value: 'Marathi' },
              { label: 'Odia', value: 'Odia' },
              { label: 'Punjabi', value: 'Punjabi' },
              { label: 'Tamil', value: 'Tamil' },
              { label: 'Telugu', value: 'Telugu' },
              { label: 'Urdu', value: 'Urdu' },
              { label: 'Other', value: 'Other' },
            ],
            validations: {
              required: 'This field is required',
            },
            ui: {
              grid: {
                xs: 12,
                md: 12,
                lg: 12,
              },
              divider: true,
            },
          },
          {
            fieldId: `languages`,
            name: `languages`,
            //required: true,
            label: 'Known Languages',
            type: 'addMultiple',
            fieldsArray: [
              {
                fieldId: `languageName`,
                name: `languageName`,
                required: true,
                label: 'Language Name',
                type: 'input',
                validations: {
                  required: 'This field is required',
                  pattern: {
                    value: REGEX.NAME,
                    message: 'Please enter a valid language',
                  },
                },
                ui: {
                  grid: {
                    xs: 12,
                    md: 6,
                    lg: 6,
                  },
                },
              },
              {
                fieldId: `proficiency`,
                required: true,
                name: `proficiency`,
                label: 'Proficiency',
                type: 'select',
                options: [
                  { label: 'Intermediate', value: 'Intermediate' },
                  { label: 'Advanced', value: 'Advanced' },
                  { label: 'Superior', value: 'Superior' },
                ],
                validations: {
                  required: 'This field is required',
                },
                ui: {
                  grid: {
                    xs: 12,
                    md: 6,
                    lg: 6,
                  },
                },
              },
            ],
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
        ],
      },
      {
        sectionId: 'references',
        sectionName: 'References',
        subSectionName: '',
        sectionPosition: 8,
        sectionVisible: true,
        sectionType: 'form',
        fields: [
          {
            fieldId: `application[appliedFromAnywhere]`,
            name: `application[appliedFromAnywhere]`,
            required: true,
            label:
              'Have you applied to S P Jain-Dubai/Mumbai/Singapore/Sydney in the past?',
            type: 'radio',
            options: [
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' },
            ],
            validations: {
              required: 'This field is required',
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `application[pastBatch]`,
            required: true,
            name: `application[pastBatch]`,
            label: 'Batch',
            type: 'input',
            conditional: true,
            condition: {
              stepId: 'educationDetails',
              sectionId: 'references',
              fieldId: 'application[appliedFromAnywhere]',
              fieldName: 'application[appliedFromAnywhere]',
              conditionType: {
                operation: 'equals',
                value: 'Yes',
              },
              behaviorType: 'DISPLAY_CONTROL',
              control: {
                display: true,
              },
            },
            validations: {
              required: 'This field is required',
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `application[educationalConsultantReferral]`,
            required: true,
            name: `application[educationalConsultantReferral]`,
            label: 'Were you referred by Education Consultant of SP Jain?',
            type: 'radio',
            options: [
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' },
            ],
            validations: {
              required: 'This field is required',
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `application[consultantName]`,
            required: true,
            name: `application[consultantName]`,
            label: 'Consultant Name',
            type: 'input',
            conditional: true,
            condition: {
              stepId: 'educationDetails',
              sectionId: 'references',
              fieldId: 'application[educationalConsultantReferral]',
              fieldName: 'application[educationalConsultantReferral]',
              conditionType: {
                operation: 'equals',
                value: 'Yes',
              },
              behaviorType: 'DISPLAY_CONTROL',
              control: {
                display: true,
              },
            },
            validations: {
              required: 'This field is required',
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          // {
          //   fieldId: `application[consultantApplicationNumber]`,
          //   required: true,
          //   name: `application[consultantApplicationNumber]`,
          //   label: 'Application Number',
          //   type: 'input',
          //   conditional: true,
          //   condition: {
          //     stepId: 'educationDetails',
          //     sectionId: 'references',
          //     fieldId: 'application[educationalConsultantReferral]',
          //     fieldName: 'application[educationalConsultantReferral]',
          //     conditionType: {
          //       operation: 'equals',
          //       value: 'Yes',
          //     },
          //     behaviorType: 'DISPLAY_CONTROL',
          //     control: {
          //       display: true,
          //     },
          //   },
          //   validations: {
          //     required: 'This field is required',
          //   },
          //   ui: {
          //     grid: {
          //       xs: 12,
          //       md: 6,
          //       lg: 6,
          //     },
          //   },
          // },
          {
            fieldId: `application[howDidYouHearAboutSPJain]`,
            required: true,
            name: `application[howDidYouHearAboutSPJain]`,
            label: 'How did you hear about SP Jain?',
            type: 'select',
            options: [
              { label: 'Alumnus of SP Jain', value: 'Alumnus of SP Jain' },
              { label: 'EDM Invite', value: 'EDM Invite' },
              { label: 'Email Promotion', value: 'Email Promotion' },
              { label: 'Info Session', value: 'Info Session' },
              {
                label: 'Newpaper / Magazine Article or Advertisement',
                value: 'Newpaper / Magazine Article or Advertisement',
              },
              {
                label: 'SP Jain Representative',
                value: 'SP Jain Representative',
              },
              { label: 'SP Jain Website', value: 'SP Jain Website' },
              { label: 'Social Media', value: 'Social Media' },
              { label: 'TV / Radio', value: 'TV / Radio' },
            ],
            validations: {
              required: 'This field is required',
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `application[wereYouReferredByAlumniReferral]`,
            required: true,
            name: `application[wereYouReferredByAlumniReferral]`,
            label: 'Were you referred by Alumnus or Active student',
            apiKey: `application[wereYouReferredByAlumniReferral]`,
            type: 'radio',
            options: [
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' },
            ],
            validations: {
              required: 'This field is required',
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `application[alumniStudent]`,
            required: true,
            name: `application[alumniStudent]`,
            label: 'Alumni Student',
            type: 'input',
            conditional: true,
            condition: {
              stepId: 'educationDetails',
              sectionId: 'references',
              fieldId: 'application[wereYouReferredByAlumniReferral]',
              fieldName: 'application[wereYouReferredByAlumniReferral]',
              conditionType: {
                operation: 'equals',
                value: 'Yes',
              },
              behaviorType: 'DISPLAY_CONTROL',
              control: {
                display: true,
              },
            },
            validations: {
              required: 'This field is required',
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `application[alumniEmail]`,
            required: true,
            name: `application[alumniEmail]`,
            label: 'Email',
            type: 'input',
            conditional: true,
            condition: {
              stepId: 'educationDetails',
              sectionId: 'references',
              fieldId: 'application[wereYouReferredByAlumniReferral]',
              fieldName: 'application[wereYouReferredByAlumniReferral]',
              conditionType: {
                operation: 'equals',
                value: 'Yes',
              },
              behaviorType: 'DISPLAY_CONTROL',
              control: {
                display: true,
              },
            },
            validations: {
              required: 'This field is required',
              pattern: {
                value: REGEX.EMAIL,
                message: 'Please Input a valid Email',
              },
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `application[alumniMobile]`,
            required: true,
            name: `application[alumniMobile]`,
            label: 'Mobile',
            type: 'phone',
            conditional: true,
            condition: {
              stepId: 'educationDetails',
              sectionId: 'references',
              fieldId: 'application[wereYouReferredByAlumniReferral]',
              fieldName: 'application[wereYouReferredByAlumniReferral]',
              conditionType: {
                operation: 'equals',
                value: 'Yes',
              },
              behaviorType: 'DISPLAY_CONTROL',
              control: {
                display: true,
              },
            },
            validations: {
              required: 'This field is required',
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
          {
            fieldId: `application[alumniYear]`,
            required: true,
            name: `application[alumniYear]`,
            label: 'Batch',
            type: 'input',
            conditional: true,
            condition: {
              stepId: 'educationDetails',
              sectionId: 'references',
              fieldId: 'application[wereYouReferredByAlumniReferral]',
              fieldName: 'application[wereYouReferredByAlumniReferral]',
              conditionType: {
                operation: 'equals',
                value: 'Yes',
              },
              behaviorType: 'DISPLAY_CONTROL',
              control: {
                display: true,
              },
            },
            validations: {
              required: 'This field is required',
            },
            ui: {
              grid: {
                xs: 12,
                md: 6,
                lg: 6,
              },
            },
          },
        ],
      },
    ],
  },
  {
    stepId: 'uploadDocuments',
    stepName: 'Upload Documents',
    stepPosition: 3,
    showStepName: false,
    stepVisible: false,
    stepType: 'form',
    sections: [
      {
        sectionName: 'Student Documents',
        sectionId: 'studentDocuments',
        subSectionName: '',
        sectionPosition: 1,
        sectionVisible: true,
        sectionType: 'form',
        fields: [
          {
            fieldId: 'passportSizePhotograph',
            //required: true,
            name: 'passportSizePhotograph',
            label: 'Passport Size Photograph',
            description:
              'jpeg, pdf, png formats supported • Max file size 5 MB',
            type: 'upload',
            validations: [],
            ui: {
              grid: {
                xs: 12,
                md: 12,
                lg: 12,
              },
            },
          },
          {
            fieldId: 'birthCertificate',
            required: false,
            name: 'birthCertificate',
            label: 'Birth Certificate',
            description:
              'jpeg, pdf, png formats supported • Max file size 5 MB',
            type: 'upload',
            validations: [],
            ui: {
              grid: {
                xs: 12,
                md: 12,
                lg: 12,
              },
            },
          },

          {
            fieldId: 'academicRecords',
            //required: true,
            name: 'academicRecords',
            label: 'Academic Records',
            description:
              '10th mark sheet, 12th mark sheet, passing certificate • pdf formats supported • Max file size 5 MB',
            type: 'upload',
            validations: [],
            ui: {
              grid: {
                xs: 12,
                md: 12,
                lg: 12,
              },
            },
          },
          {
            fieldId: 'signatureOfStudent',
            //required: true,
            name: 'signatureOfStudent',
            label: 'Signature of Student',
            description:
              'jpeg, pdf, png formats supported • Max file size 5 MB',
            type: 'upload',
            validations: [],
            ui: {
              grid: {
                xs: 12,
                md: 12,
                lg: 12,
              },
            },
          },
        ],
      },
      {
        sectionId: 'fatherDocument',
        sectionName: "Father's Document",
        subSectionName: '',
        sectionPosition: 2,
        sectionVisible: true,
        sectionType: 'form',
        fields: [
          {
            fieldId: 'fatherIdentityProof',
            required: false,
            name: 'fatherIdentityProof',
            label: 'Aadhaar/PAN/Passport',
            description:
              'jpeg, pdf, png formats supported • Max file size 5 MB',
            type: 'upload',
            validations: [],
            ui: {
              grid: {
                xs: 12,
                md: 12,
                lg: 12,
              },
            },
          },
        ],
      },
    ],
  },
  {
    stepId: 'payment',
    stepName: 'Payment',
    stepPosition: 4,
    showStepName: false,
    stepVisible: false,
    stepType: 'payment',
    voucher: true,
    sections: [],
  },
  {
    stepId: 'summaryWithDeclaration',
    stepName: 'Summary and Declaration',
    stepPosition: 5,
    showStepName: true,
    stepVisible: true,
    stepType: 'summary',
    voucher: true,
    sections: [],
  },
];

function KenApplicationForm() {
  const [applicationData, setApplicationData] = useState();
  const query = new URLSearchParams(useLocation().search);
  const { formState, formDispatch } = useContext(FormContext);
  const [loader, setLoader] = useState(true);
  const [formLoader, setFormLoader] = useState(true);
  const [formJson, setFormJson] = useState([...formJSON]);
  const [currentActiveStep, setCurrentActiveStep] = useState();
  const [applicationFormData, setApplicationFormData] = useState();
  const [csc, setCsc] = useState([]);
  const [cscCopy, setCscCopy] = useState();
  const navigate = useNavigate();
  const { hasBack } = useBack();
  const goToDashboard = () => {
    navigate('/home');
  };

  //get all fields in single array to find by id and replace options
  function flat(array) {
    const updatedArray = array.map((step) => {
      const stepId = step.stepId;
      const sections = step.sections?.map((section) => {
        const sectionId = section.sectionId;
        const fields = section.fields?.map((field) => {
          let fArray = [];

          return {
            ...field,
            stepId: stepId,
            sectionId: sectionId,
          };
        });

        return {
          ...section,
          fields: fields,
        };
      });
      return {
        ...step,
        sections: sections,
      };
    });
    // console.log("updated Array", updatedArray);
    setFormJson([...updatedArray]);
    const allSections = updatedArray.flatMap((elem) => elem.sections);
    return allSections.flatMap((elem) => elem.fields);
  }

  const replaceObject = (identifier, key, value, arr) => {
    const dataArray = [...arr];
    dataArray?.map(function (step) {
      step?.sections.map(function (section) {
        section.fields?.map(function (field) {
          if (field?.fieldId == identifier) field[key] = value;
        });
      });
    });
    // console.log("updatedDataArray", dataArray);
    // setFormJson([...dataArray]);
    return dataArray;
  };

  const removeField = (identifier, value, arr = []) => {
    // console.log("identifier", identifier);
    const array = arr;
    // console.log("array", array);
    const dataArray = array.map(function (step) {
      const stepSections = step.sections?.map((section) => {
        const sectionFields =
          section?.fields?.filter((field) => field[identifier] != value) || [];
        return {
          ...section,
          fields: sectionFields,
        };
      });
      // console.log("step sections", stepSections);
      return {
        ...step,
        sections: stepSections,
      };
    });
    // console.log("remove fields", dataArray);
    return dataArray;
    // setFormJson([...dataArray]);
  };

  // console.log(csc);
  const updateFormData = (finalArray) => {
    const copyJson = [...finalArray];
    // console.log(copyJson);
    let countryIndex = copyJson[1].sections[0].fields.findIndex(
      (el) => el?.label === 'Country'
    );
    let sectionIndex12 = copyJson[2].sections.findIndex(
      (el) => el?.sectionId === 'hsc'
    );
    let countryIndex12 = copyJson[2].sections[sectionIndex12].fields.findIndex(
      (el) => el?.label === 'Country'
    );
    let sectionIndex10 = copyJson[2].sections.findIndex(
      (el) => el?.sectionId === 'ssc'
    );
    let countryIndex10 = copyJson[2].sections[sectionIndex10].fields.findIndex(
      (el) => el?.label === 'Country'
    );
    let country = [
      ...Country.getAllCountries().map((el) => {
        return { label: el?.name, value: el?.name };
      }),
    ];
    copyJson[1].sections[0].fields[countryIndex].options = [...country];
    copyJson[2].sections[sectionIndex12].fields[countryIndex12].options = [
      ...country,
    ];
    copyJson[2].sections[sectionIndex10].fields[countryIndex10].options = [
      ...country,
    ];
    return [...copyJson];
  };

  useEffect(() => {
    // debugger;
    const copyJson = [...formJson];
    switch (query.get('type')) {
      case 'Doctoral':
        copyJson[2].sections[0].sectionVisible = true;
        copyJson[2].sections[2].sectionVisible = true;
        copyJson[2].sections[1].sectionVisible = true;
        break;
      case 'Postgraduate':
        copyJson[2].sections[0].sectionVisible = false;
        copyJson[2].sections[1].sectionVisible = true;
        copyJson[2].sections[2].sectionVisible = true;
        copyJson[2].sections[1].sectionName = 'Education Details';
        break;
      case 'Undergraduate':
        copyJson[2].sections[0].sectionVisible = false;
        copyJson[2].sections[1].sectionVisible = false;
        copyJson[2].sections[2].sectionVisible = false;
        copyJson[2].sections[3].sectionName = 'Education Details';
        break;
      default:
      //nothing
    }
    setFormJson([...copyJson]);
  }, [query.get('type')]);

  useEffect(() => {
    if (csc !== undefined) {
      setFormLoader(true);
      const copyJson = [...formJson];
      if (csc[0] !== undefined) {
        let stateIndex = copyJson[1].sections[0].fields.findIndex(
          (el) => el?.label === 'State'
        );
        let selectedCountryCode = Country.getAllCountries().find(
          (el) => el.name === csc[0]
        )?.isoCode;
        let state = [
          ...State.getStatesOfCountry(selectedCountryCode).map((el) => {
            return { label: el?.name, value: el?.name };
          }),
        ];
        copyJson[1].sections[0].fields[stateIndex].options = [...state];
        setFormJson(copyJson);
      }
      if (csc[0] !== undefined && csc[1] !== undefined) {
        let stateIndex = copyJson[1].sections[0].fields.findIndex(
          (el) => el?.label === 'City'
        );
        let selectedCountryCode = Country.getAllCountries().find(
          (el) => el.name === csc[0]
        )?.isoCode;
        let selectedStateCode = State.getAllStates().find(
          (el) => el.name === csc[1]
        )?.isoCode;
        let city = [
          ...City.getCitiesOfState(selectedCountryCode, selectedStateCode).map(
            (el) => {
              return { label: el?.name, value: el?.name };
            }
          ),
        ];
        copyJson[1].sections[0].fields[stateIndex].options = [...city];
        setFormJson(copyJson);
      }
      setFormLoader(false);
      setCscCopy(csc);
    }
  }, [csc]);

  useEffect(() => {
    //Use effect to add campus and specialization options list if data available. Otherwise remove those fields from structure.
    const formStructure = applicationFormData?.data?.formMetadata
      ? JSON.parse(applicationFormData?.data?.formMetadata)
      : formJSON;
    const formArray = flat(formStructure);
    const campusField = formArray.find(
      (item) => item?.fieldId === 'application[campusId]'
    );
    const specializationField = formArray.find(
      (item) => item?.fieldId === 'application[specializationId]'
    );
    let finalArray = [];
    if (applicationFormData?.data?.campuses?.length > 0) {
      finalArray = replaceObject(
        campusField?.fieldId,
        'options',
        applicationFormData?.data?.campuses?.map((item) => {
          return { label: item?.campusName, value: item?.recordId };
        }),
        formStructure
      );
    } else {
      // console.log("No campuses ");
      finalArray = removeField('fieldId', campusField?.fieldId, formStructure);
    }
    if (applicationFormData?.data?.specializations?.length > 0) {
      finalArray = replaceObject(
        specializationField?.fieldId,
        'options',
        applicationFormData?.data?.specializations?.map((item) => {
          return { label: item?.specializationName, value: item?.recordId };
        }),
        finalArray || formStructure
      );
    } else {
      finalArray = removeField(
        'fieldId',
        specializationField?.fieldId,
        finalArray || formStructure
      );
    }

    if (finalArray?.length > 0) {
      let data = updateFormData(finalArray);
      setFormJson(data);
    } else {
      let data = updateFormData(formStructure);
      setFormJson(data);
    }
  }, [applicationFormData]);

  useEffect(() => {
    setFormLoader(true);
    const formId = query.get('formId');
    //get form by form Id
    getFormByFormId(formId)
      .then((res) => {
        setApplicationFormData(res);
        const user = JSON.parse(sessionStorage.getItem('user'));
        getContactInfoByContactId(user?.applicantId)
          .then((response) => {
            if (response.success && response.data) {
              formDispatch({
                type: 'update',
                payload: {
                  applicant: response?.data,
                },
              });
            }
          })
          .catch((err) => {
            console.log('err of contact', err);
          });

        formDispatch({
          type: 'update',
          payload: {
            programDetails: res?.data,
            application: {
              academicCycle: res?.data?.academicCycle,
              programId: res?.data?.programId,
              programName: res?.data?.programName,
            },
          },
        });
        setFormLoader(false);
        if (!query.get('applicationId')) {
          // const user = JSON.parse(sessionStorage.getItem("user"));
          formDispatch({
            type: 'update',
            payload: { applicantId: user?.applicantId },
          });
          setLoader(false);
        } else {
          setLoader(true);
          getApplicationByApplicationId(query.get('applicationId'))
            .then((resp) => {
              // console.log('res of application', resp);
              if (Object.keys(resp?.data).length === 0) {
                goToDashboard();
              } else {
                setCurrentActiveStep(resp?.data?.application?.currentStep || 0);
                let educationHistories = resp?.data?.educationHistories || [];
                let ssc, hsc, grad, postGrad, other, graduationMarks;
                educationHistories?.length > 0 &&
                  educationHistories?.map((ed) => {
                    switch (ed.educationHistoryType) {
                      case '10th':
                        ssc = ed;
                        break;
                      case '12th':
                        hsc = ed;

                        break;
                      case 'Under Graduation':
                        grad = ed;
                        const semData = [];
                        const yearData = [];
                        if (ed.semester1Marks) {
                          semData.push({
                            semesterNumber: 'semester1Marks',
                            semesterMarks: ed.semester1Marks,
                          });
                        }

                        if (ed.semester2Marks) {
                          semData.push({
                            semesterNumber: 'semester2Marks',
                            semesterMarks: ed.semester2Marks,
                          });
                        }
                        if (ed.semester3Marks) {
                          semData.push({
                            semesterNumber: 'semester3Marks',
                            semesterMarks: ed.semester3Marks,
                          });
                        }
                        if (ed.semester4Marks) {
                          semData.push({
                            semesterNumber: 'semester4Marks',
                            semesterMarks: ed.semester4Marks,
                          });
                        }
                        if (ed.semester5Marks) {
                          semData.push({
                            semesterNumber: 'semester5Marks',
                            semesterMarks: ed.semester5Marks,
                          });
                        }
                        if (ed.semester6Marks) {
                          semData.push({
                            semesterNumber: 'semester6Marks',
                            semesterMarks: ed.semester6Marks,
                          });
                        }
                        if (ed.semester7Marks) {
                          semData.push({
                            semesterNumber: 'semester7Marks',
                            semesterMarks: ed.semester7Marks,
                          });
                        }
                        if (ed.semester8Marks) {
                          semData.push({
                            semesterNumber: 'semester8Marks',
                            semesterMarks: ed.semester8Marks,
                          });
                        }

                        if (ed.year1) {
                          yearData.push({
                            yearNumber: 'year1',
                            yearMarks: ed.year1,
                          });
                        }
                        if (ed.year2) {
                          yearData.push({
                            yearNumber: 'year2',
                            yearMarks: ed.year2,
                          });
                        }
                        if (ed.year3) {
                          yearData.push({
                            yearNumber: 'year3',
                            yearMarks: ed.year3,
                          });
                        }
                        if (ed.year4) {
                          yearData.push({
                            yearNumber: 'year4',
                            yearMarks: ed.year4,
                          });
                        }

                        graduationMarks = {
                          semesteryearwiseMarks:
                            ed.semesterwiseMarks === 'Semester'
                              ? 'Semester'
                              : ed.semesterwiseMarks === 'Year'
                                ? 'Year'
                                : null,
                          semesterwiseMarks:
                            ed.semesterwiseMarks === 'Semester'
                              ? [...semData]
                              : ed.semesterwiseMarks === 'Year'
                                ? [...yearData]
                                : [],
                        };
                        break;
                      case 'Post Graduation':
                        postGrad = ed;
                        break;
                      case 'Other':
                        other = ed;
                        break;
                      default:
                    }
                  });

                const payload = {
                  ...resp?.data,
                  application: {
                    ...resp?.data?.application,
                    specializationId_select: getNameById(
                      resp?.data?.application?.specializationId,
                      res?.data?.specializations || [],
                      'recordId',
                      'specializationName'
                    ), //need same in summary page
                    campusId_select: getNameById(
                      resp?.data?.application?.campusId,
                      res?.data?.campuses || [],
                      'recordId',
                      'campusName'
                    ),
                  },
                  programDetails: {
                    ...res?.data,
                    academicYear: resp?.data?.academicYear,
                    courseBatch: resp?.data?.application?.courseBatch,
                    courseDuration: resp?.data?.application?.courseDuration,
                    courseEndDate: resp?.data?.application?.courseEndDate,
                    courseStartDate: resp?.data?.application?.courseStartDate,
                    departmentId: resp?.data?.application?.departmentId,
                    departmentName: resp?.data?.application?.departmentName,
                    programId: resp?.data?.application?.programId,
                    programName: resp?.data?.application?.programName,
                    progress: resp?.data?.application?.progress,
                  },
                  postGraduation: postGrad,
                  graduation: grad,
                  ssc: ssc,
                  hsc: hsc,
                  other: other,
                  graduationMarks: graduationMarks,
                  //   educationHistories: [...educationHistories],
                };
                //TODO: we have to take from education type from backend.
                formDispatch({
                  type: 'update',
                  payload: {
                    ...payload,
                  },
                });
                setApplicationData(resp);
                setLoader(false);
              }
            })
            .catch((error) => {
              setLoader(false);
              console.log('error in getApplicationByApplicationId', error);
            });
        }
      })
      .catch((err) => {
        console.log('err', err);
        setFormLoader(false);
      });
  }, []);

  useEffect(() => {
    console.log('form json changed', formJson);
  }, [formJson]);
  return (
    <UploadDocumentContextProvider>
      {(loader || formLoader) && <KenLoader />}
      {!loader && !formLoader && (
        <KenFormBuilder
          formJSON={formJson}
          currentActiveStep={currentActiveStep}
          setCsc={setCsc}
        />
      )}
    </UploadDocumentContextProvider>
  );
}

export default KenApplicationForm;
