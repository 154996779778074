import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import React, { useRef } from 'react';
import { useState } from 'react';
import { TABLET_BREAKPOINT } from '../../Constants/constant';
import { useApplicationContext } from '../../Context/applicationContext';
import KenStepperFooter from '../KenApplicationForm/Components/KenStepperFooter';
import ThankYouPage from '../KenApplicationForm/Components/ThankYouPage';
import KenSummaryStep from '../KenSummaryPage';
import { useNavigate } from 'react-router-dom';

import FormStep from './Form';
import KenButton from '../KenButton';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import KenInput from '../KenInput';
import nitteLogo from '../../Assets/Images/FINAL_NITTE.png';
import paymentLogo from '../../Assets/Images/forPaymentLogo.png';
import paymentLogo2 from '../../Assets/Images/small-removebg-preview.png';
import Divider from '@mui/material/Divider';
import { useAppContext } from '../../Context/appContext';
import DocumentUpload from './documentUpload';
import { createUpdateApplication, postInitatePayment } from '../../Utils/apiServices';
import KenLoader from '../KenLoader';
import converter from "number-to-words";
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

// Any special cases like payments,summary based on the stepType will be handled
// NOTE: These special steps should be coupled with any other steps

const useStyles = makeStyles((theme) => ({
  thankYouContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      width: '80%',
      position: 'absolute',
      top: '30%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  title: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'font-style': 'normal',
      'font-weight': '500',
      'font-size': '20px',
      'line-height': '23px',
      color: '#000000',
    },
  },
  subTitle: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'font-style': 'normal',
      'font-weight': '400',
      'font-size': '16px',
      'line-height': '150%',
      'text-align': 'center',
      color: '#505F79',
    },
  },
  link: {
    color: '#0000EE',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  Heading: {
    fontSize: '16px',
    fontWeight: '600',
    color: '#092682',
  },
  SubHeading: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '150%',
    color: '#7A869A',
    textTransform:'capitalize'
  },
  SubHeading2: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '13px',
    color: '#061938',
  },
}));

export default function  Stepper({ step }) {
  const { applicationState, applicationDispatch } = useApplicationContext();
  const formRef = useRef();
  console.log(step?.stepType, 'Stepper');

  // based on stepType return components

  const getStepContent = () => {
    switch (step?.stepType) {
      case 'payment':
        return (
          <PaymentStep
            applicationState={applicationState}
            applicationDispatch={applicationDispatch}
          />
        );

      case 'documentUpload':
        return (
          <DocumentUpload
            applicationState={applicationState}
            applicationDispatch={applicationDispatch}
          />
        );

      case 'summary':
        return (
          <SummaryStep
            applicationState={applicationState}
            applicationDispatch={applicationDispatch}
          />
        );

      default:
        return <FormStep step={step} ref={formRef} />;
    }
  };

  return (
    <>
      {/* render step by id */}
      {/* <KenStepperHeader activeStep={step} /> */}

      <Box m={2} alignItems="center" justifyContent="center">
        {getStepContent()}
      </Box>
    </>
  );
}

const PaymentStep = (props) => {
  const classes = useStyles();
  const { applicationState, applicationDispatch } = props;
  const [showThankYou, setShowThankYou] = useState(false);
  const [appStateData, seAppStateData] =useState(applicationState);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
 console.log("props fee stepper",props);
  const handlePrev = () => {
    applicationDispatch({ type: 'prevStep' });
  };
  const handleNext = () => {
    setShowThankYou(true);
    // applicationDispatch({ type: 'showHeader' });
    applicationDispatch({ type: 'nextStep' });
  };

  console.log("appStateData",appStateData);
   const handlePay = () => {
    let applicantName= `${appStateData?.applicationData?.applicant?.firstName}+${appStateData?.applicationData?.applicant?.lastName}`
    let appfee= Math.round(appStateData?.form?.applicationFee);
    let noteValues = [];
    noteValues.push({
      // fee: location?.state?.dueFee?.name,
      amount: appfee,
      Contact__c: appStateData?.applicantId,
      // Fee_Type__c: location?.state?.dueFee?.name,
      K42_Planned_Payment__c:'',
      Late_Fees__c: null,
      Amount__c: appfee,
    })
    console.log("noteValues: ",noteValues)
    const payload = {
      amount: appfee,
      paisa: '00',
      applicationId: appStateData?.applicationId,
      studentId: appStateData?.applicantId,
      accept_partial: false,
      name: applicantName,
      mobile: appStateData?.applicationData?.applicant?.phone,
      email: appStateData?.applicationData?.applicant?.email,
      // callBackUrl: 'http://localhost:3000/Thankyou',
      callBackUrl: `https://apply-nsom-stg.ken42.com/Thankyou`,
      currencyCode: appStateData?.form?.currencyCode,
      programPlan: appStateData?.form?.programName,
      notes: noteValues,
    };

    console.log("Payload : ",payload);
    postInitatePayment(payload)
      .then((res) => {
        console.log(res);
        setLoader(true);
        setTimeout(() => {
          setLoader(false);
        }, 12000);
        window.open(res.data, '_self');
        // setTimeout(() => {
        //   navigate('/home');
        // }, 3000);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });

    // const payload = {
    //   amount: 600,
    //   paisa: '00',
    //   applicationId: 'a0QBh000000wAbkMAE',
    //   studentId: '003Bh000000ryCYIAY',
    //   accept_partial: false,
    //   name: 'Kiran Kumar',
    //   mobile: '919743949246',
    //   email: 'prashanth.p@ken42.com',
    //   callBackUrl: 'https://nitte-applicationportal.ken42.com/thankyou',
    //   currencyCode: 'INR',
    //   programPlan: 'PGDM',
    //   notes: [
    //     {
    //       fee: 'Application Fee-PGDM',
    //       amount: 600,
    //       Contact__c: '003Bh000000ryCYIAY',
    //       Fee_Type__c: 'Application Fee',
    //       K42_Planned_Payment__c: '',
    //       Late_Fees__c: null,
    //       Amount__c: 600,
    //     },
    //   ],
    // };
  };
  
  // return <>Payment Step </>;
  return (
    <>
      {loader && <KenLoader />}
      {/* {appStateData?.applicationData?.application?.paymentDetail === null ? ( */}
       {!showThankYou ? (
        <Paper elevation={0}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid item md={2} sm={2} xs={12}>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  // marginBottom: '32px',
                  margin: '8px',
                }}
              >
                <Typography>
                  <img
                    src={paymentLogo2}
                    alt=""
                    style={{ width: '95px', height: '48px' }}
                  />
                </Typography>
              </Box>
            </Grid>
            <Grid item md={6} sm={8} xs={12}>
              <Box style={{ paddingLeft: '24px', padding: '8px' }}>
                <Typography className={classes.Heading} align="left">
                  Application for NITTE School of Management,New Delhi
                </Typography>
                <Typography
                  className={classes.SubHeading2}
                  style={{ marginTop: '12px' }}
                  align="left"
                >
                  Your Application for Mr.Aditya Nair for Postgraduation
                  Program,
                  <br /> MBA at NITTE School of Management New Delhi,has been
                  saved successfully!
                </Typography>
              </Box>
            </Grid>
            <Grid item md={2} sm={2} xs={12}>
              <Box>
                <Typography
                  sx={{ marginBottom: '12px', marginTop: '9px' }}
                  className={classes.SubHeading}
                  align="right"
                >
                  Application Fee
                </Typography>
                <Typography
                  align="right"
                  sx={{
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '16px',
                    color: '#505F79',
                    // marginTop: '12px',
                  }}
                >
                  {/* &#8377; 1000.00 */}
                  &#8377;  {appStateData?.form?.applicationFee}
                </Typography>
              </Box>
            </Grid>
            <Grid md={2}>{''}</Grid>

            <Grid md={12} xs={12}>
              <Box style={{ margin: 40 }}>{''}</Box>
            </Grid>
            <Grid
              md={8}
              sm={8}
              xs={12}
              container
              mt={5}
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item md={3} sm={12} xs={12}>
                {''}
              </Grid>
              <Grid item md={5} sm={6} xs={10}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '8px',
                  }}
                >
                  <KenInput
                    name="promocode"
                    label="Do you have a discount code?"
                    placeholder="Enter  Discount Code"
                  />
                </Box>
              </Grid>
              <Grid item md={4} sm={3} xs={2}>
                {' '}
                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <KenButton sx={{ marginTop: '16px' }} color="primary">
                    Apply
                  </KenButton>
                </Box>
              </Grid>
            </Grid>
            <Grid mt={5} item md={2} sm={4} xs={12}>
              <Box>
                <Typography
                  sx={{ marginBottom: '12px', marginTop: '4px' }}
                  className={classes.SubHeading}
                  align="right"
                >
                  Application Fee After Discount
                </Typography>
                <Typography
                  align="right"
                  sx={{
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '16px',
                    color: '#505F79',
                    // marginTop: '12px',
                  }}
                >
                  &#8377; {appStateData?.form?.applicationFee}
                </Typography>
              </Box>
            </Grid>
            <Grid md={2} sm={12} xs={12}>
              {''}
            </Grid>
          </Grid>
          <Divider
            style={{ marginTop: '60px', marginLeft: '17%', marginRight: '16%' }}
          />
          <Grid container md={12} sm={12} xs={12}>
            <Grid marginTop={5} marginBottom={8} item md={8} sm={12} xs={12}>
              {''}
            </Grid>

            <Grid
              marginTop={3}
              marginBottom={8}
              item
              md={2}
              sm={12}
              xs={12}
              align="right"
            >
              <Box>
                <Typography className={classes.SubHeading}>
                {`${converter.toWords(appStateData?.form?.applicationFee)} only`}
                </Typography>
                <Box marginTop={2}>
                  <KenButton color="primary" variant="contained" label="Pay" onClick={handlePay}> 
                    {/* Pay &#8377;{''} 750 */}
                    {/* &#8377; {appStateData?.form?.applicationFee} */}
                    
                  </KenButton>
                </Box>
              </Box>
            </Grid>
            <Grid md={2} xs={12}>
              {''}
            </Grid>
          </Grid>
          <KenStepperFooter
            totalSteps={applicationState.totalSteps}
            activeStep={applicationState.activeStep}
            onPrev={handlePrev}
            onNext={handleNext}
          />
        </Paper>
      ) : (
        <Box
          margin="auto"
          height="90%"
          p={2}
          display="flex"
          justifyContent="center"
          alignContent="center"
          flexDirection="column"
        >
          <ThankYouPage
            wrapperClassName={classes.thankYouContainer}
            titleClass={classes.title}
            subTitleClass={classes.subTitle}
            title="Thank you for your Application"
            description={
              <Typography>
                {/* <div style={{fontSize:'14px', fontFamily:"Poppins", fontWeight:500, lineHeight:1.235 , marginTop:'-15px', marginBottom:'20px'}}>
                  <span> Your payment Transaction Id : {`${appStateData?.applicationData?.application?.paymentDetail?.recordId}`}</span>
                </div> */}
                You have successfully Applied for
                {` ${applicationState.applicationData.application.campusName},
                    ${applicationState.applicationData.application.specializationName},
                    ${applicationState.applicationData.application.departmentName},
                    ${applicationState.applicationData.application.programName}
                    `}
                   
                {/* <div style={{ fontSize: 14 }}>
                  Click here to redirect to{' '}
                  <span
                    className={classes.link}
                    onClick={() => {
                      applicationDispatch({ type: 'showHeader' });
                      navigate('/home');
                    }}
                  >
                    Homepage
                  </span>
                </div> */}
              </Typography>
            }
          />
        </Box>
      )}
    </>
  );
};

const SummaryStep = (props) => {
  const { applicationState, applicationDispatch } = props;
  console.log(applicationState.declaration, "applicationState")
  const { dispatch: dispatchAppContext } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const classes = useStyles();
  const [showThankYou, setShowThankYou] = useState(false);
  const navigate = useNavigate();

  const declaration = sessionStorage.getItem('declaration')

  const handlePrev = () => {
    applicationDispatch({ type: 'prevStep' });
  };

  const handleNext = () => {

    dispatchAppContext({ type: 'udpateLoading', value: true });

    if(declaration) {

      let data = applicationState?.applicationData?.application;
      data.applicationStatus = 'Application form Submitted';
      data.applicationDate = new Date();
      

      createUpdateApplication({
        applicationId: applicationState?.applicationId,
        application: { ...data },
      })
        .then((res) => {
          dispatchAppContext({ type: 'udpateLoading', value: false });
          // on success update context data and active step
          if (res?.success) {
            // update context
            applicationDispatch({
              type: 'update',
              payload: { applicationData: res.data },
            });
            applicationDispatch({ type: 'nextStep' });
            setShowThankYou(true);
            applicationDispatch({ type: 'showHeader' });
          }
        })
        .catch((err) => {
          dispatchAppContext({ type: 'udpateLoading', value: false });
          //alert user of failure > try again
          console.log('Updating data failed. Try again');
        });
    } else {
      // dispatchAppContext({ type: 'udpateLoading', value: false });
      enqueueSnackbar(t('Please mark the checkbox to confirm.'), {
        variant: 'error',
      });
      dispatchAppContext({ type: 'udpateLoading', value: false });
    }
  };
  return (
    <>
      {/* Summary */}
      {!showThankYou ? (
        <>
          <Box sx={{ width: '80vw', margin: '0 auto', marginBottom: '100px' }}>
            <KenSummaryStep
              formJson={applicationState.steps}
              data={applicationState.applicationData}
              applicationState={applicationState}
              applicationDispatch={applicationDispatch}
            />
          </Box>
          <KenStepperFooter
            totalSteps={applicationState.totalSteps}
            activeStep={applicationState.activeStep}
            onPrev={handlePrev}
            onNext={handleNext}
            isSave={false}
            applicationStatus={
              applicationState?.applicationData?.application?.applicationStatus
            }
          />
        </>
      ) : (
        <Box
          margin="auto"
          height="90%"
          p={2}
          display="flex"
          justifyContent="center"
          alignContent="center"
          flexDirection="column"
        >
          <ThankYouPage
            wrapperClassName={classes.thankYouContainer}
            titleClass={classes.title}
            subTitleClass={classes.subTitle}
            title="Thank you for your Application"
            redirection={false}
            description={
              <>
                <Typography>
                  You have successfully Applied for
                  {` ${applicationState?.applicationData?.applicant?.course}`}
                  <br />
                  <br />
                  <div style={{ fontSize: 16 }}>
                    To Proceed with the Technical Test. Please{' '}
                    {/* <span
                      className={classes.link}
                      onClick={() => {
                        navigate('https://esscisamsung-lms.ken42.com/');
                      }}
                    > */}
                      <a href="https://esscisamsung-lms.ken42.com/" target='_blank' style={{textDecoration: 'none', color: '#0000EE'}}>click here</a>
                    {/* </span> */}
                      <p style={{ margin: 0 }}>Technical Test Credentials:</p>
                      <p style={{ margin: 0 }}>Username: {applicationState?.applicationData?.applicant?.email}</p>
                      <p style={{ margin: 0 }}>Password: Abcd@1234</p>
                  </div>
                  <br />
                  <div style={{ fontSize: 14 }}>
                    Click here to redirect to{' '}
                    <span
                      className={classes.link}
                      onClick={() => {
                        applicationDispatch({ type: 'showHeader' });
                        navigate('/home');
                      }}
                    >
                      Homepage
                    </span>
                  </div>
                </Typography>
              </>
            }
          />
        </Box>
      )}
    </>
  );
};

