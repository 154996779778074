import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useMediaQuery
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: "16px",
  },
}));

export default function KenDialog(props) {
  const {
    open,
    toggleOpen,
    handleClose,
    title,
    dialogText,
    negativeButtonText,
    positiveButtonText,
    negativeButtonProps,
    positiveButtonProps,
    dialogContent,
    dialogActions,
    children,
    titleContainerStyles,
    maxWidth,
    dialogActionProps,
    negativeButtonClick,
    positiveButtonClick,
    positiveButtonLabelProps,
    negativeButtonLabelProps,
    hidePositiveButton,
    hideNegativeButton,
    Component,
    innerProps,
    onClose,
  } = props;

  console.log(maxWidth, "maxWidth");

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [onPositiveButtonClick, setOnPositiveButtonClick] = React.useState(
    () => positiveButtonClick
  );
  const [onNegativeButtonClick, setOnNegativeButtonClick] = React.useState(
    () =>
      negativeButtonClick ||
      (() => {
        console.log("KenDialog: negative click default");
        toggleOpen(false);
      })
  );
  const [positiveButtonClickChange, setPositiveButtonClickChange] =
    React.useState(0);

  const compRef = React.useRef();

  React.useEffect(() => {
    console.log("KenDialog: effect: ", open);
  }, [open]);

  const classes = useStyles();
  return (
    <div>
      {
        isSmallScreen ? <>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          style={{ minWidth: "362px" }}
          // fullWidth
          maxWidth={maxWidth}
        >
          <DialogTitle
            id="form-dialog-title"
            classes={{ root: classes.padding }}
            style={{ ...titleContainerStyles }}
          >
            {title}
            {onClose ? (
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>
          {dialogContent || (
            <>
              {dialogText && (
                <DialogContent classes={{ root: classes.padding }}>
                  {dialogText && (
                    <DialogContentText>{dialogText}</DialogContentText>
                  )}
                </DialogContent>
              )}
            </>
          )}
          <DialogContent classes={{ root: classes.padding }}>
            {children ? children : null}
            {Component && (
              <>
                <Component
                  {...innerProps}
                  setOnPositiveButtonClick={setOnPositiveButtonClick}
                  setOnNegativeButtonClick={setOnNegativeButtonClick}
                  // setIsOpen={setIsOpen}
                  isOpen={open}
                  toggleOpen={toggleOpen}
                  ref={compRef}
                  positiveButtonClickChange={positiveButtonClickChange}
                />
              </>
            )}
          </DialogContent>
          {dialogActions && (
            <DialogActions {...dialogActionProps}>
              {!hideNegativeButton && (
                <Button
                  {...negativeButtonProps}
                  onClick={() => {
                    console.log("KenDialog: Negative Button Clikc");
                    onNegativeButtonClick();
                  }}
                  color="primary"
                  variant="secondary"
                >
                  <Typography {...negativeButtonLabelProps}>
                    {negativeButtonText || "Cancel"}
                  </Typography>
                </Button>
              )}
              {!hidePositiveButton && (
                <Button
                  onClick={() => {
                    // console.log("Positive clicked", onPositiveButtonClick);
                    // if (onPositiveButtonClick) {
                      positiveButtonClick();
                    // }
                    setPositiveButtonClickChange(positiveButtonClickChange + 1);
                  }}
                  color="primary"
                  variant="primary"
                  {...positiveButtonProps}
                >
                  <Typography {...positiveButtonLabelProps}>
                    {positiveButtonText || "Ok"}
                  </Typography>
                </Button>
              )}
            </DialogActions>
          )}
        </Dialog>
      </>
         : 
        <>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            style={{ minWidth: "362px" }}
            fullWidth
            maxWidth={maxWidth}
          >
            <DialogTitle
              id="form-dialog-title"
              classes={{ root: classes.padding }}
              style={{ ...titleContainerStyles }}
            >
              {title}
              {onClose ? (
                <IconButton
                  aria-label="close"
                  onClick={onClose}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              ) : null}
            </DialogTitle>
            {dialogContent || (
              <>
                {dialogText && (
                  <DialogContent classes={{ root: classes.padding }}>
                    {dialogText && (
                      <DialogContentText>{dialogText}</DialogContentText>
                    )}
                  </DialogContent>
                )}
              </>
            )}
            <DialogContent classes={{ root: classes.padding }}>
              {children ? children : null}
              {Component && (
                <>
                  <Component
                    {...innerProps}
                    setOnPositiveButtonClick={setOnPositiveButtonClick}
                    setOnNegativeButtonClick={setOnNegativeButtonClick}
                    // setIsOpen={setIsOpen}
                    isOpen={open}
                    toggleOpen={toggleOpen}
                    ref={compRef}
                    positiveButtonClickChange={positiveButtonClickChange}
                  />
                </>
              )}
            </DialogContent>
            {dialogActions && (
              <DialogActions {...dialogActionProps}>
                {!hideNegativeButton && (
                  <Button
                    {...negativeButtonProps}
                    onClick={() => {
                      console.log("KenDialog: Negative Button Clikc");
                      onNegativeButtonClick();
                    }}
                    color="primary"
                    variant="secondary"
                  >
                    <Typography {...negativeButtonLabelProps}>
                      {negativeButtonText || "Cancel"}
                    </Typography>
                  </Button>
                )}
                {!hidePositiveButton && (
                  <Button
                    onClick={() => {
                      // console.log("Positive clicked", onPositiveButtonClick);
                      // if (onPositiveButtonClick) {
                        positiveButtonClick();
                      // }
                      setPositiveButtonClickChange(positiveButtonClickChange + 1);
                    }}
                    color="primary"
                    variant="primary"
                    {...positiveButtonProps}
                  >
                    <Typography {...positiveButtonLabelProps}>
                      {positiveButtonText || "Ok"}
                    </Typography>
                  </Button>
                )}
              </DialogActions>
            )}
          </Dialog>
        </>
      }

    </div>
  );
}
