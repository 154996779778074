import * as React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import KenIcon from '../../../KenIcon';
import DoneIcon from '../../../../Assets/Icons/tickInCircle.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import {
  createUpdateApplication,
  getTransactionDetailsByTransactionId,
  postAppliedCouponCode,
} from '../../../../Utils/apiServices';
import { useContext } from 'react';
import { AuthContext } from '../../../../Context/authContext';
import { useState } from 'react';

export default function ThankYouPage(props) {
  const {
    icon,
    title = 'Fee Payment',
    description = "Your Fee's has been successfully paid.",
    wrapperClassName,
    titleClass,
    subTitleClass,
    redirection = true,
  } = props;

  const {
    state: { user },
  } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();

  const [titleReceived, setTitleReceived] = useState();
  const [descriptionReceived, setDescriptionReceived] = useState();

  const query = new URLSearchParams(useLocation().search);
  const applicationId = query.get('applicationId');
  const coupon = query.get('couponId');
  const transactionId = query.get('transactionId');
  const remainingAmount = query.get('remaining');
  const name = query.get('name');


  useEffect(() => {
    if (transactionId !== null) {
      getTransactionDetailsByTransactionId(transactionId).then((res) => {
        console.log("thank you page res",res);
        if (res?.data?.status === 'Success' && coupon) {
          postAppliedCouponCode({
            couponCodeData: [
              {
                Contact: user?.applicantId,
                Application: applicationId,
                CouponCode: coupon,
              },
            ],
          })
            .then((resp) => {
              console.log(resp);
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if (res?.data?.status === 'Success' && Number(remainingAmount) == 0) {
          createUpdateApplication({
            applicationId: applicationId,
            application: {
              // CurrentStepName: `${name} paid`,
              CurrentStepName: `${name} paid`,
              applicantId: user?.applicantId,
              recordId: applicationId,
            },
          })
            .then((resp) => {
              console.log(resp);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    }
  }, [transactionId]);

  useEffect(() => {
    if (location?.state?.title && location?.state?.description) {
      setTitleReceived(location?.state?.title);
      setDescriptionReceived(location?.state?.description);
    }
  }, [location?.state]);

  return (
    <Box
      textAlign="center"
      margin="auto"
      width="50%"
      marginTop="22vh"
      className={wrapperClassName}
    >
      <Box>
        <KenIcon iconType="img" icon={DoneIcon}></KenIcon>
      </Box>

      <Box mt={3} mb={5}>
        <Typography variant="h4" fontWeight={500} className={titleClass}>
          {titleReceived || title}
        </Typography>
      </Box>

      <Grid container alignItems="center" justifyContent="center">
        <Grid item>
          <Typography
            variant="subtitle3"
            fontWeight={400}
            className={subTitleClass}
          >
            {descriptionReceived || description}
          </Typography>
        </Grid>
        {redirection && (
          <Grid item>
            <Typography
              variant="subtitle3"
              fontWeight={400}
              fontSize="17px"
              className={subTitleClass}
            >
              Click here to redirect on{' '}
              <span
                onClick={() => {
                  navigate('/home');
                }}
                style={{ color: '#0000EE' }}
              >
                Homepage
              </span>
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
