import { Box, Divider, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import KenDocumentUpload from '../KenDocumentUpload';
import KenStepperFooter from '../KenApplicationForm/Components/KenStepperFooter/index';
import { makeStyles } from '@mui/styles';
import {
  createDocuments,
  createUpdateApplication,
  getApplicationByApplicationId,
  getDocumentByDocId,
} from '../../Utils/apiServices';
import { useSnackbar } from 'notistack';
import { saveAs } from 'file-saver';
import KenLoader from '../KenLoader';
import KenUploadButton from '../KenDocumentUpload/Components/KenUploadButton';
import FilePreview from '../KenDocumentUpload/Components/KenFilePreview';
import KenDialog from '../../Components/KenDialog';
import { useNavigate } from 'react-router-dom';
import ThankYouPage from '../KenApplicationForm/Components/ThankYouPage';
import { TABLET_BREAKPOINT } from '../../Constants/constant';

const useStyles = makeStyles((theme) => ({
  title2: {
    color: '#092682',
    fontWeight: 600,
    textAlign: 'left',
    marginBottom: 32,
    fontSize: 20,
  },
  thankYouContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      width: '80%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  title: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'font-style': 'normal',
      'font-weight': '500',
      'font-size': '20px',
      'line-height': '23px',
      color: '#000000',
    },
  },
  subTitle: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'font-style': 'normal',
      'font-weight': '400',
      'font-size': '16px',
      'line-height': '150%',
      'text-align': 'center',
      color: '#505F79',
    },
  },
  link: {
    color: '#0000EE',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  Heading: {
    fontSize: '16px',
    fontWeight: '600',
    color: '#092682',
  },
  SubHeading: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '150%',
    color: '#7A869A',
    textTransform: 'capitalize',
  },
  SubHeading2: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '13px',
    color: '#061938',
  },
}));

export default function DocumentUpload(props) {
  const styles = useStyles();
  const classes = useStyles();
  const { applicationState, applicationDispatch } = props;
  const [appState, setAppState] = useState([applicationState]);
  // const [recordId, setRecordId] = useState([applicationState?.applicationData?.attachments?.recordId]);
  const [pageLoader, setPageLoader] = useState(false);
  const [url, setUrl] = useState();
  const [uploadData, setUploadData] = useState();
  const [filePreview, setFilePreview] = useState(false);
  const [file, setFile] = useState();
  const navigate = useNavigate();
  const [showThankYou, setShowThankYou] = useState(false);

  // const {
  //   state: { user },
  // } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const [checkList, setCheckList] = useState([]);

  console.log('applicationState', applicationState);
  const convertBase64ToFile = (base64String, fileName, fileType) => {
    // let arr = base64String.split(',');
    let mime = fileType;
    let bstr = atob(base64String);
    let n = bstr.length;
    let uint8Array = new Uint8Array(n);
    while (n--) {
      uint8Array[n] = bstr.charCodeAt(n);
    }
    let file = new File([uint8Array], fileName, { type: fileType });
    return file;
  };

  useEffect(() => {
    const documents = [];
    const data = [];

    if (applicationState?.form?.DocumentChecklist) {
      const appStates = applicationState?.form?.DocumentChecklist?.split(
        ';'
      ).map((elem) => {
        let required = false;
        if (
          elem === 'Aadhar Card' ||
          elem === 'Passport Size Photo' ||
          elem === 'Std 10th Mark sheet' ||
          elem === 'Highest Qualification Certificate'
        ) {
          required = true;
        }
        return {
          title: elem,
          previewImage: null,
          contentType: elem.includes('Passport Size Photo')
            ? ['image/png', 'image/jpeg']
            : ['application/pdf', 'image/png', 'image/jpeg'],
          description: elem.includes('Passport Size Photo')
            ? 'jpeg,jpg and png format supported (max size 2MB)'
            : 'pdf, jpeg, jpg and png format supported (max size 2MB)',
          required: required,
        };
      });
      setCheckList([...appStates]);
      console.log('appStates', appStates);
      setUploadData(data);
      getData(appStates);
    }
  }, [applicationState?.form]);

  useEffect(() => {
    if (!(file instanceof File)) {
      setUrl(file);
    }
  }, [file]);

  // useEffect(() => {
  //   getData();
  // }, []);

  const getData = (appStates) => {
    // console.log("*****",checkList);
    const uploadDataCopy = appStates || checkList;

    if (uploadDataCopy.length > 0) {
      setPageLoader(true);
      getApplicationByApplicationId(
        // applicationState?.applicationData?.application?.recordId
        applicationState?.applicationId
      )
        .then((resp) => {
          setPageLoader(true);
          if (resp?.success && resp?.data) {
            resp?.data?.attachments?.forEach((el) => {
              let index = uploadDataCopy.findIndex(
                (elem) => elem.title === el.title
              );
              if (index > -1) {
                uploadDataCopy[index] = { ...uploadDataCopy[index], ...el };
              }
            });
            setCheckList([...uploadDataCopy]);

            setPageLoader(false);
            // console.log("uploadDataCopy:",uploadDataCopy);
            // console.log("*******:checkList",checkList);
          }
        })
        .catch((err) => {
          console.log(err);
          setPageLoader(false);
        });
    }
  };

  const onError = (err) => console.log('Error:', err);

  const handlePrev = () => {
    applicationDispatch({ type: 'prevStep' });
  };
  const handleNext = () => {
    console.log('handleChk', checkList);
    console.log(
      'handleCondition',
      checkList?.some((element) => {
        return element?.required === true && element?.recordId;
      })
    );
    let flag = [];
    checkList.forEach((element) => {
      if (element?.required === true && element?.recordId) {
        flag.push(true);
      } else if (element?.required === true && !element?.recordId) {
        flag.push(false);
      }
    });
    if (flag.every((element) => element === true)) {
      let data = { ...applicationState?.applicationData?.application };
      // data.applicationStatus = 'Application form Submitted';
      // data.applicationStatus = 'Application Form Complete';
      // data.enableDocumentUpload = true;
      data.currentStep = Number(data.currentStep) + 1;

      // setLoading(true);
      createUpdateApplication({
        applicationId: applicationState?.applicationData?.application?.recordId,
        applicantId: applicationState?.applicantId,
        application: data,
      })
        .then((res) => {
          let data = res.data;
          data.applicant.combineFirstLast = data?.applicant?.firstName + ' ' + data?.applicant?.lastName
          applicationDispatch({
            type: 'update',
            payload: { applicationData: data },
          });
          // if (res?.success === true) {
          //   applicationDispatch({ type: 'nextStep' });
          //   // setLoading(false);
          //   // setHidden(true);
          //   // enqueueSnackbar(`You have ${status} the Offer Letter`, {
          //   //   variant: 'success',
          //   // });
          //   setShowThankYou(true);
          //   applicationDispatch({ type: 'showHeader' });
          //   // applicationDispatch({ type: 'showHeader' });
          // }
        })
        .catch((err) => {
          console.log(err);
          // setLoading(false);
          // enqueueSnackbar(`Error Occured while saving the data`, {
          //   variant: 'error',
          // });
        });
      applicationDispatch({ type: 'nextStep' });
    } else {
      return enqueueSnackbar('Upload all mandatory documents', {
        variant: 'info',
      });
    }
    // createUpdateApplication({
    //   application: {
    //     ...applicationState?.applicationData?.application,
    //     currentStep: applicationState?.activeStep + 1,
    //   },
    // }).catch((err) => {
    //   console.log(err);
    // });
    // applicationDispatch({ type: 'nextStep' });
  };

  const handleFileDelete = (el) => {
    console.log('eless', el);
    // const index = state.newlyAddedFiles?.findIndex(
    //   (item) => item.name === name
    // );
    // dispatch({
    //   type: 'delete',
    //   payload: index,
    // });
  };

  // console.log('docprops', applicationState);

  // const handleUploaded = (id, file , fileType) => {
  //   setPageLoader(true);

  //   getDocumentByDocId(id)
  //     .then((res) => {
  //       debugger
  //       console.log("responss", res);
  //       if (res?.success && res?.data) {
  //         setFilePreview(true);
  //         setFile(`data:${fileType};base64,${res?.data}`);
  //         setPageLoader(false);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setPageLoader(false);
  //     });

  // };

  const handleDownload = (id, fileType, fileName) => {
    setPageLoader(true);
    getDocumentByDocId(id)
      .then((res) => {
        if (res?.success && res?.data) {
          // setFilePreview(true);
          // setFile(`data:${fileType};base64,${res?.data}`);
          setPageLoader(false);
          let file = convertBase64ToFile(res?.data, fileName, fileType);
          saveAs(file, fileName);
        }
      })
      .catch((err) => {
        console.log(err);
        setPageLoader(false);
      });
  };

  const uploadDoc = (file, el) => {
    setPageLoader(true);
    // const uploadDataCopy = [...checkList];
    const reader = new FileReader();
    let base64;
    reader.onloadend = () => {
      base64 = reader.result.replace('data:', '').replace(/^.+,/, '');
      const body = {
        fileName: file?.name,
        title: el.title,
        expiryDate: null,
        body: base64,
        // applicationId: applicationState?.applicationData?.application?.recordId,
        applicationId: applicationState?.applicationId,
        applicantId: applicationState?.applicantId,
        fileType: file.type,
      };
      createDocuments({ attachments: [body] })
        .then((res) => {
          setPageLoader(false);
          if (res?.success === true) {
            getData();
            window.location.reload();
            return enqueueSnackbar('Document Uploaded Successfully', {
              variant: 'success',
            });
          }
        })
        .catch((err) => {
          setPageLoader(false);
          console.log('error', err);
          return enqueueSnackbar(
            'Error while uploading. Please try again later.',
            {
              variant: 'error',
            }
          );
        });
    };
    reader.readAsDataURL(file);
    // setUploadData([...uploadDataCopy]);
  };

  // const uploadDoc = (file, el) => {
  //   setPageLoader(true);
  //   const uploadDataCopy = [...checkList];

  //   const index = uploadDataCopy?.findIndex(
  //     (elem) => elem.title === el.title
  //   );
  //   uploadDataCopy[index].previewImage = file;

  //   const applicationProgramName = applicationState?.form?.programName;
  //   const appFormName = applicationState?.form?.applicationFormName;
  //   const reader = new FileReader();
  //   const user = JSON.parse(sessionStorage.getItem('user'));
  //   let base64;
  //   reader.onloadend = () => {
  //     base64 = reader.result.replace('data:', '').replace(/^.+,/, '');
  //     const myArray = file.name.split('.');
  //     const body = {
  //       fileName: file?.name,
  //       title: el.title,
  //       formattedDocumentName: `${applicationProgramName}_${appFormName}_${user?.applicantName}.${myArray[myArray.length - 1]}`,
  //       expiryDate: null,
  //       body: base64,
  //       applicationId: applicationState?.applicationId,
  //       fileType: file.type,
  //     };
  //     createDocuments({ attachments: [body] })
  //       .then((res) => {
  //         setPageLoader(false);
  //         if (res?.success === true) {
  //           return enqueueSnackbar('Document Uploaded Successfully', {
  //             variant: 'success',
  //           });
  //         }
  //       })
  //       .catch((err) => {
  //         setPageLoader(false);
  //         console.log('error', err);
  //         return enqueueSnackbar(
  //           'Error while uploading. Please try again later.',
  //           {
  //             variant: 'error',
  //           }
  //         );
  //       });
  //   };
  //   reader.readAsDataURL(file);
  //   setUploadData([...uploadDataCopy]);
  // };

  return (
    <React.Fragment>
      {!showThankYou ? (
        <>
          <Box sx={{ width: '50vw', margin: '0 auto', paddingBottom: 10}}>
            <Typography className={styles.title2}>Document Upload</Typography>
            {checkList?.map((el) => {
              return (
                <>
                  {pageLoader && (
                    <KenLoader />
                    // <KenDialog
                    //   open={pageLoader}
                    //   handleClose={() => {
                    //     setPageLoader(!pageLoader);
                    //   }}
                    // >
                    //   {/* {pageLoader && <KenLoader />} */}
                    //   <Typography
                    //     variant="h3"
                    //     align="center"
                    //     style={{ padding: 20 }}
                    //   >
                    //     Please wait, do not refresh or close the browser.
                    //   </Typography>
                    // </KenDialog>
                  )}
                  <KenDocumentUpload
                    label={el.title}
                    handleDownload={handleDownload}
                    applicationDataState={applicationState}
                    onChange={(file) => uploadDoc(file[0], el)}
                    {...el}
                  />
                  {/* <KenDocumentUpload
                label={el.title}
                previewImage={el.previewImage}
                handleUploaded={(file)=>handleUploaded(file[0])}
                // removeFile={(el)=>handleFileDelete(el)}
                onChange={(file) => uploadDoc(file[0], el)}

              /> */}

                  <Divider
                    variant="fullWidth"
                    style={{ background: '#E2E2E2', marginBottom: '30px' }}
                  />
                </>
              );
            })}
          </Box>
          <KenDialog
            open={filePreview}
            handleClose={() => {
              setFilePreview(false);
            }}
          >
            <FilePreview
              type={'file'}
              file={file instanceof File && file}
              onError={onError}
              width={'210px'}
              height={'150px'}
              // removeFile={removeFile}
              url={url}
            />
          </KenDialog>
          <KenStepperFooter
            totalSteps={applicationState.totalSteps}
            activeStep={applicationState.activeStep}
            onPrev={handlePrev}
            onNext={handleNext}
            applicationStatus={
              applicationState?.applicationData?.application?.applicationStatus
            }
          />
        </>
      ) : (
        <Box
          margin="auto"
          height="90%"
          p={2}
          display="flex"
          justifyContent="center"
          alignContent="center"
          flexDirection="column"
        >
          <ThankYouPage
            wrapperClassName={classes.thankYouContainer}
            titleClass={classes.title}
            subTitleClass={classes.subTitle}
            title="Thank you for your Application"
            redirection={false}
            description={
              <Typography>
                You have successfully Applied for
                {/* {` ${applicationState?.applicationData?.application?.specializationName}, */}
                {/* ${applicationState?.applicationData?.application?.departmentName}, */}
                {`${
                  ' ' +
                  applicationState?.applicationData?.application?.programName
                }`}
                <div style={{ fontSize: 14 }}>
                  Click here to redirect to{' '}
                  <span
                    className={classes.link}
                    onClick={() => {
                      applicationDispatch({ type: 'showHeader' });
                      navigate('/home');
                    }}
                  >
                    Homepage
                  </span>
                </div>
              </Typography>
            }
          />
        </Box>
      )}
    </React.Fragment>
  );
}
