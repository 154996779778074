import React, { useContext } from "react";
import { AuthContext } from "../../Context/authContext";
import { Navigate } from "react-router-dom";
import routes from "../../Constants/routes";

function KenPrivateRoute({ children }) {
  const {
    state: { isAuthenticated, user },
  } = useContext(AuthContext);

  // return <>Hii</>
  // return isAuthenticated ? children : <Navigate to={routes.login} />;
  return isAuthenticated && user?.formId ? <Navigate to={`/application?formId=${user?.formId}`} /> : isAuthenticated ? children : <Navigate to={routes.login} />;
}

export default KenPrivateRoute;
