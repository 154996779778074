import React from 'react';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import KenUploadButton from './Components/KenUploadButton';
import FilePreview from './Components/KenFilePreview';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: 16,
  },
  cardContainer: {
    padding: 8,
  },
  rowWrap: {
    marginBottom: 30,
    // borderBottom: `1px solid ${theme.palette.KenColors.neutral60}`,
    // paddingBottom: 14,
  },
  rowWrapConditional: {
    // marginBottom: 0,
    borderBottom: 0,
  },
  rowLabel: {
    // color: theme.palette.KenColors.neutral900,
    // fontSize: 16,
    fontWeight: 500,
    color: '#091E42',
  },
  rowDesc: {
    // color: theme.palette.KenColors.neutral100,
    // fontSize: 14,
    maxWidth: '400px',
    color: '#7A869A',
  },
  mainContent: {
    position: 'relative',
  },
}));

export default function KenDocumentUpload(props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    value = [],
    label,
    description,
    onChange,
    length,
    index,
    uploading,
    name,
    removeFile,
    required,
    previewImage,
    preview = true,
    recordId,
    handleDownload,
    fileType,
    applicationDataState
  } = props;
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    multiple: false,
    noKeyboard: true,
    maxSize: 2000000,
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length < 0) {
        enqueueSnackbar(
          fileRejections[0]?.errors[0]?.message.replace('200000 bytes', '2 MB'),
          {
            variant: 'error',
          }
        );
      } else {
        if (
          label === 'Passport Size Photo'
            ? acceptedFiles[0]?.type === 'image/jpg' ||
              acceptedFiles[0]?.type === 'image/jpeg' ||
              acceptedFiles[0]?.type === 'image/png'
            : acceptedFiles[0]?.type === 'application/pdf' || 
              acceptedFiles[0]?.type === 'image/jpg' ||
              acceptedFiles[0]?.type === 'image/jpeg' ||
              acceptedFiles[0]?.type === 'image/png'
        ) {
          onChange([...value, ...acceptedFiles]);
        } else {
          enqueueSnackbar(
            label === 'Passport Size Photo'
              ? 'Only JPEG, JPG and PNG format supported is allowed and maximum file size is 2 MB '
              : 'Only PDF, JPEG, JPG and PNG format supported is allowed and maximum file size 2 MB',
            {
              variant: 'error',
            }
          );
        }
      }
    },
  });

  const classes = useStyles();

  return (
    <div
      className={clsx(classes.rowWrap, {
        [classes.rowWrapConditional]: length === index,
      })}
    >
      <Grid container {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <Grid xs={12} md={8}>
          <Typography align="left" className={classes.rowLabel} variant="h5">
            {label}
            {required === true && <span style={{ color: '#E75547' }}>*</span>}
          </Typography>
          <Typography
            align="left"
            className={classes.rowDesc}
            variant="subtitle2"
          >
            {description}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <KenUploadButton
            open={open}
            applicationStateData={applicationDataState}
            recordId={recordId}
            handleDownload={handleDownload}
            fileType={fileType}
            fileName={props?.fileName}
          />
        </Grid>
      </Grid>
    </div>
  );
}
