import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import KenPDFViewer from '../../../../Components/KenPdfViewer';
import { createUpdateApplication } from '../../../../Utils/apiServices';
import { useSnackbar } from 'notistack';
import KenLoader from '../../../../Components/KenLoader';

export default function OfferLetterDetailed() {
  const location = useLocation();
  const [loading, setLoading] = useState();
  const [hidden, setHidden] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    console.log(location.state.applicationData.offerLetterStatusByStudent);
    if (
      location?.state?.applicationData?.offerLetterStatusByStudent === null ||
      location?.state?.applicationData?.offerLetterStatusByStudent ===
        'Generated'
    ) {
      setHidden(false);
    } else {
      setHidden(true);
    }
  }, [location.state.applicationData]);

  const handleStatus = (status) => {
    let data = { ...location.state.applicationData };
    data.offerLetterStatusByStudent = status;
    setLoading(true);
    createUpdateApplication({
      applicationId: data.recordId,
      application: data,
    })
      .then((res) => {
        console.log(res);
        if (res?.success === true) {
          setLoading(false);
          setHidden(true);
          enqueueSnackbar(`You have ${status} the Offer Letter`, {
            variant: 'success',
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        enqueueSnackbar(`Error Occured while saving the data`, {
          variant: 'error',
        });
      });
  };
  return (
    <>
      {loading && <KenLoader />}
      <Grid container direction="row" spacing={5}>
        <Grid item md={3}>
          <KenPDFViewer
            toolbarFlag={false}
            handleStatus={handleStatus}
            {...location.state}
          />
        </Grid>
        <Grid item md={9}>
          <KenPDFViewer
            handleStatus={handleStatus}
            {...location.state}
            hidden={hidden}
          />
        </Grid>
      </Grid>
    </>
  );
}
