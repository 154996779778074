import { Box, Button, Grid, Typography, Tooltip, Fade } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import React,{useState} from 'react';
import AttachFileSharpIcon from '@mui/icons-material/AttachFileSharp';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { makeStyles } from '@mui/styles';
import { getDocumentsViaRecordId } from '../../../Utils/apiServices';
import KenDialog from '../../KenDialog';
import KenFilePreview from './KenFilePreview';
import KenLoader from '../../KenLoader';
import { useMediaQuery } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const useStyles = makeStyles((theme) => ({
  download: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default function KenUploadButton(props) {
  const classes = useStyles();
  const [previewImage, setPreviewImage] = useState();
  const [filePreview, setFilePreview] = useState(false);
  const [loader, setLoader] = useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [url, setUrl] = useState();
  const { open, recordId, handleDownload, fileType, fileName, applicationStateData } = props;

  const handlePreviewImage = () => {
    // alert("Hii")
    if (props?.recordId) {
      setLoader(true);
      getDocumentsViaRecordId(props?.recordId)
        .then((res) => {
          if (res?.success === true) {
            setPreviewImage(`data:${props?.fileType};base64,${res?.data}`);
            setFilePreview(true);
            setLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  };

React.useEffect(() => {
  if (!(previewImage instanceof File)) {
    setUrl(previewImage);
  }
}, [previewImage]);

  return (
    // <div style={{ border: "1px dashed #092682", borderRadius: 6 }}>
    <React.Fragment>
      {!recordId ? (
        <Button
          variant="secondary"
          onClick={open}
          startIcon={<AttachFileSharpIcon style={{ color: '#092682' }} />}
          style={{
            color: '#092682',
            border: '1px dashed #092682',
            borderRadius: 6,
            width: '100%',
          }}
        >
          Choose or Drag file
        </Button>
      ) : (
        <Box>
          {/* { isSmallScreen 
          ? 
          <Grid container spacing={2} alignItems="center">
            <Grid item md={6}>
              <Button
                variant="secondary"
                onClick={() => {
                  handlePreviewImage();
                }}
                //   startIcon={<AttachFileSharpIcon style={{ color: '#092682' }} />}
                style={{
                  color: '#092682',
                  border: '1px dashed #092682',
                  borderRadius: 6,
                  width: '100%',
                }}
              >
                Preview
              </Button>
            </Grid>
            <Grid item md={6}>

            </Grid>
          </Grid>
          :  */}
          <>
          <Grid container spacing={2} alignItems="center">
            <Grid item lg={4} md={4} sm={12} xs={12}>
              {applicationStateData?.applicationData?.attachments.map((status, index) => {
                console.log(status, "dsd")
                if(status?.recordId == recordId) {
                  return (
                    <Typography
                      //   startIcon={<AttachFileSharpIcon style={{ color: '#092682' }} />}
                      style={{
                        color: '#ffffff',
                        fontSize: 14,
                        opacity: '0.6',
                        borderRadius: 6,
                        width: '100%',
                        background: status?.status === 'Rejected' ? 'red' : '#008000',
                        textTransform: 'uppercase',
                        padding: '12px 0px',
                        cursor: 'pointer'
                      }}
                    >
                      {status?.status == null ? 'Submitted' : status?.status}
                    </Typography>
                  );
                } else {
                  return null; // Return null if condition is not met to avoid errors
                }
              })}
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
            {/* <Typography
                //   startIcon={<AttachFileSharpIcon style={{ color: '#092682' }} />}
                style={{
                  color: '#ffffff',
                  fontSize: 14,
                  opacity: '0.6',
                  borderRadius: 6,
                  width: '100%',
                  background: '#008000',
                  textTransform: 'uppercase',
                  padding: '12px 0px',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  handlePreviewImage();
                }}
              >
                Preview
              </Typography> */}
              {/* <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} title={'Preview'}>
                <VisibilityIcon
                  className={classes.icon}
                  onClick={() => {
                    handlePreviewImage();
                  }}
                />
              </Tooltip> */}
              <Button
                variant="secondary"
                onClick={() => {
                  handlePreviewImage();
                }}
                //   startIcon={<AttachFileSharpIcon style={{ color: '#092682' }} />}
                style={{
                  color: '#092682',
                  border: '1px dashed #092682',
                  borderRadius: 6,
                  width: '100%',
                }}
              >
                Preview
              </Button>
            {/* <Typography
                //   startIcon={<AttachFileSharpIcon style={{ color: '#092682' }} />}
                style={{
                  color: '#ffffff',
                  fontSize: 14,
                  opacity: '0.6',
                  borderRadius: 6,
                  width: '100%',
                  background: '#008000',
                  textTransform: 'uppercase',
                  padding: '12px 0px',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  handleDownload(recordId, fileType, fileName);
                }}
              >
                Download
              </Typography> */}
              {/* <FileDownloadIcon
                sx={{ marginTop: '6px' }}
                className={classes.download}
                onClick={() => {
                  handleDownload(recordId, fileType, fileName);
                }}
              /> */}

            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Button
                variant="secondary"
                onClick={open}
                //   startIcon={<AttachFileSharpIcon style={{ color: '#092682' }} />}
                style={{
                  color: '#092682',
                  border: '1px dashed #092682',
                  borderRadius: 6,
                  width: '100%',
                }}
              >
                ReUpload
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={12} textAlign="left">
              <Typography
                align="left"
                variant="subtitle2"
                sx={{
                  marginBottom: '-20px',
                  marginTop: '8px',
                  color: '#7A869A',
                }}
              >
                {fileName}
              </Typography>
            </Grid>
          </Grid>
          {
            loader ? <KenLoader /> : 
            <KenDialog
              open={filePreview}
              handleClose={() => {
                setFilePreview(false);
              }}
            >
              <KenFilePreview remove={false} width="50%" type={'file'} file={previewImage instanceof File && previewImage} url={url} />
            </KenDialog>
          }
          </>
        {/* } */}
        </Box>
      )}
    </React.Fragment>
    // </div>
  );
}
