import {
  Box,
  Grid,
  useTheme,
  useMediaQuery,
  Divider,
  Typography,
} from '@mui/material';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import CoursesBox from './Components/CoursesBox';
import ApplicationProcess from './Components/ApplicationProcess';
import DescriptionBox from './Components/DescriptionBox';
import {
  getAllPrograms,
  getApplicationsByApplicantId,
  getCourseByApplicantId,
  getImportantDates,
} from '../../Utils/apiServices';
import TaskTabs from './Components/TaskTabs';
import Calendar from '../../Components/KenCalendar';
import KenLoader from '../../Components/KenLoader';
import calendarIcon from '../../Assets/Svg/calendar.svg';
import backIcon from '../../Assets/Svg/back-arrow.svg';

import { makeStyles } from '@mui/styles';
import { AuthContext } from '../../Context/authContext';
import moment from 'moment';
import { TABLET_BREAKPOINT } from '../../Constants/constant';
import { courseFilter } from '../../Utils/Helpers/courseFilterHelper';

const useStyles = makeStyles((theme) => ({
  container: {
    border: `1px solid ${theme.palette.KenColors.innerBorder}`,
    borderRadius: '12px',
    padding: 16,
    // maxWidth: "100vw",
  },
  Icon: {
    color: theme.palette.KenColors.grey5,
    marginTop: 32,
  },
  root: {
    [theme.breakpoints.only('xs')]: {
      padding: 4,
    },
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      padding: 0,
      margin: 0,
      width: '100%',
    },
  },
  mobileCalendar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '56px',
    height: '56px',
    background: theme.palette.KenColors.primary,
    borderRadius: '50%',
    position: 'fixed',
    bottom: '100px',
    right: '10px',
    cursor: 'pointer',
    zIndex: 1000,
  },
  mobileItem: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      width: '100%',
      padding: '0 !important',
      'margin-top': '50px',
      'margin-bottom': '25px',
    },
  },
  resetTop: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'margin-top': 0,
      alignItems: 'center',
    },
  },
}));

export default function HomePage() {
  const classes = useStyles();
  const [programs, setPrograms] = useState();
  const [loading, setLoading] = useState();
  const {
    state: { user },
  } = useContext(AuthContext);
  const [applicationType, setApplicationType] = useState();
  const [applicationStatus, setApplicationStatus] = useState();
  const [currentStepName, setCurrentStepName] = useState();
  const [currentProgramName, setCurrentProgramName] = useState();
  const [rows, setRows] = useState([]);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );
  // flag used only in mobile toggle
  const [isCalendarActive, setCalendarActive] = useState(false);

  useEffect(() => {
    if (applicationType === 'Courses') {
      setLoading(true);

      // getCourseByApplicantId(user?.applicantId)
      //   .then((res) > {
      //     if (res?.data) {
      //       const data = courseFilter(res?.data) || [];
      //       setPrograms([...data]);
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     setLoading(false);
      //   });


      getAllPrograms()
      // getCourseByApplicantId(user?.applicantId)
        .then((res) => {
          console.log(res, "czzczxzx")
          // if (res?.data) {
            getCourseByApplicantId(user?.applicantId)
              .then((resCollegeData) => {
                console.log(resCollegeData, "xdfsfdsfd")
                const data = courseFilter(res?.data, resCollegeData?.data) || [];
            //     console.log(data, "dsdzcz")
            // const data = courseFilter(res?.data) || [];


            setPrograms([...data]);
              })
            //   let data = [];
            //   res?.data?.forEach((el) => {
            //     const isBetween = moment(el?.endDate).diff(moment(), 'day');
            //     if (isBetween >= 0) {
            //       data.push(el);
            //     }
            //   });
            //   setPrograms([...data]);
            //   setLoading(false);
          // }
        })
        // .catch((err) => {
        //   console.log(err);
        //   setLoading(false);
        // });
    }
  }, [applicationType]);

  useEffect(() => {
    setLoading(true);
    getApplicationsByApplicantId(user?.applicantId)
      .then((res) => {
        if (res?.data?.length > 0) {
          setApplicationType('MyApplication');
          setPrograms([...res?.data]);
          setLoading(false);
        } else {
          setApplicationType('Courses');
          setPrograms([...res?.data]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  // useEffect(() => {
  //   getImportantDates().then((res) => {
  //     if (res?.data) {
  //       const data = res?.data.map((el) => {
  //         return {
  //           title: `${moment(el?.targetDate).format('MMM Do')} | ${moment(
  //             el?.targetDate
  //           ).format('dddd')}`,
  //           description: el?.description,
  //           ...el,
  //         };
  //       });
  //       setRows([...data]);
  //     }
  //   });
  // }, []);

  const toggleCalendar = useCallback(() => {
    setCalendarActive((prev) => !prev);
  }, []);

  useEffect(() => {
    if (!isMobileScreen && isCalendarActive) {
      toggleCalendar();
    }
  }, [isCalendarActive, isMobileScreen, toggleCalendar]);

  return (
    <>
      {loading && <KenLoader />}
      {/* {isMobileScreen && (
        <Box className={classes.mobileCalendar} onClick={toggleCalendar}>
          {!isCalendarActive ? (
            <img src={calendarIcon} alt="calendar" />
          ) : (
            <img src={backIcon} alt="back" height={30} />
          )}
        </Box>
      )} */}
      <Grid container spacing={5} className={classes.root}>
        {!isCalendarActive && (
          <Grid
            item
            md={12}
            className={`${classes.mobileItem} ${classes.resetTop}`}
          >
            <Grid
              container
              spacing={5}
              direction="column"
              width={isMobileScreen ? '100% !important' : undefined}
              margin={isMobileScreen ? '0 !important' : undefined}
            >
              <Grid
                container
                item
                md={12}
                width={isMobileScreen ? '100% !important' : undefined}
                padding={isMobileScreen ? '0 !important' : undefined}
              >
                <DescriptionBox />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
                direction="column"
                className={classes.appProcess}
                width={isMobileScreen ? '100% !important' : undefined}
                paddingX={isMobileScreen ? '16px !important' : undefined}
              >
                <ApplicationProcess
                  programs={programs}
                  applicationStatus={applicationStatus}
                  currentStepName={currentStepName}
                  currentProgramName={currentProgramName}
                />
              </Grid>
              <Grid
                container
                item
                md={12}
                xs={12}
                direction="column"
                width={isMobileScreen ? '100% !important' : undefined}
                paddingLeft={isMobileScreen ? '16px !important' : undefined}
                paddingTop={`${theme.spacing(3)} !important`}
              >
                <CoursesBox
                  programs={programs}
                  applicationType={applicationType}
                  setApplicationStatus={setApplicationStatus}
                  setCurrentStepName={setCurrentStepName}
                  setCurrentProgramName={setCurrentProgramName}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        {/* {(!isMobileScreen || (isMobileScreen && isCalendarActive)) && (
          <Grid
            item
            md={4}
            display="flex"
            justifyContent="flex-start"
            alignContent="center"
            flexDirection={'column'}
            className={`${classes.mobileItem} ${classes.resetTop}`}
          >
            <Box mb={isMobileScreen ? '16px' : '44px'} mx="auto">
              <Calendar
                mark={['10']}
                semiHighlight={['16', '25', '29']}
                rows={rows}
              />
            </Box>
            {isMobileScreen ? (
              <Divider
                style={{
                  'margin-bottom': '16px',
                  width: '90%',
                  'margin-left': 'auto',
                  'margin-right': 'auto',
                }}
              />
            ) : null}
            <Box width="85%" ml={isMobileScreen ? 0 : 4}>
              <TaskTabs rows={rows} />
            </Box>
          </Grid>
        )} */}
      </Grid>
      <Grid sx={{marginTop: '30px'}}>
        <Typography variant="subtitle1">
          In case of any issues or grievances, please feel free to contact us by
          dropping an email to techsupport@essc-india.org &{' '}
          technical@essc-india.org
        </Typography>
      </Grid>
    </>
  );
}
