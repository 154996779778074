import React from 'react';
import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TABLET_BREAKPOINT } from '../../../../Constants/constant';

const useStyles = makeStyles((theme) => ({
  li: {
    textAlign: 'left',
    padding: theme.spacing(1),
    paddingLeft: '5px',
  },
}));

const Instructions = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );

  return (
    <>
      <Box p={2} width={isMobileScreen ? '100%' : '80%'} margin="0 auto">
        <Typography
          fontWeight={700}
          variant="h4"
          color="primary"
          pt={3}
          align="left"
          textTransform="uppercase"
        >
          Instructions
        </Typography>
        <Box py={isMobileScreen ? 1 : 3}>
          <Divider />
        </Box>

        <Box pt={0}>
          <Typography
            fontWeight={500}
            color="primary"
            align="left"
            variant="subtitle2"
            fontSize={'20px'}
          >
            Important Notes
          </Typography>
          <ol>
            <li className={classes.li}>
              Ensure that the information you have filled is accurate. Candidates are fully and solely responsible to provide correct information during the application process.
            </li>
            <li className={classes.li}>
              If it is found that any information provided by the candidate at the time of filling / submitting the application form is not true, Team SIC has the right to cancel the application or enrolment, at any stage, in accordance with its rules and regulations.
            </li>
            <li className={classes.li}>
              Upload your latest scanned colour passport size photo and Aadhar at the time of Document Upload.(Size Max: 2MB)
            </li>
            <li className={classes.li}>
              All communication with candidates will be done through the official email address [techsupport@essc-india.org & technical@essc-india.org] only. Please ensure that this email address is whitelisted in your registered email.
            </li>
            <li className={classes.li}>
              In case of any issues or grievances, please feel free to contact us by dropping an email to techsupport@essc-india.org & technical@essc-india.org
            </li>
            <li className={classes.li}>
              Please be patient if the screen goes blank while uploading the document. Stay on the page and do not close the window.
            </li>
          </ol>
        </Box>

        {/* <Box>
					<Typography
						fontWeight={500}
						color='primary'
						pb={2}
						align='left'
						variant='subtitle2'
						fontSize={"20px"}
					>
						Mandatory Documents
					</Typography>
					<Typography align='left'>
						Please ensure that you possess the following documents for upload
						before continuing
					</Typography>
					<ul>
						<li className={classes.li}>Birth Certificate</li>
						<li className={classes.li}>Aadhaar Card</li>
						<li className={classes.li}>Passport</li>
						<li className={classes.li}>Provisional Certificate</li>
					</ul>
				</Box> */}
      </Box>
    </>
  );
};

export default Instructions;
