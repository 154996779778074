export const defaultJson = [
   {
     "stepId": "Basic Details",
     "stepName": "Application Form",
     "showStepName": false,
     "stepVisible": true,
     "stepPosition": 1,
     "stepType": "form",
     "sections": [
       {
         "sectionName": "Candidate Information",
         "apiKeyId": "applicant",
         "subSectionName": "",
         "sectionPosition": 1,
         "sectionVisible": true,
         "sectionType": "form",
         "fields": [
           {
             "fieldId": "applicant[salutation]",
             "required": true,
             "name": "applicant[salutation]",
             "label": "Salutation",
             "type": "select",
             "visible": true,
             "disabled": true,
             "validations": {
               "required": "This field is required",
               "pattern": {
                 "value": "",
                 "message": "Please Input a valid Salutation"
               }
             },
             "options": [
               {
                 "label": "Mr.",
                 "value": "Mr."
               },
               {
                 "label": "Mrs.",
                 "value": "Mrs."
               },
               {
                 "label": "Ms.",
                 "value": "Ms."
               }
             ]
           },
           {
             "fieldId": "applicant[combineFirstLast]",
             "required": true,
             "name": "applicant[combineFirstLast]",
             "label": "Full Name",
             "type": "input",
             "disabled": true,
             "validations": {
               "required": "This field is required",
               "pattern": {
                 "value": "",
                 "message": "Please Input a valid Name"
               }
             }
           },
           {
             "fieldId": "applicant[candidateId]",
             "required": true,
             "name": "applicant[candidateId]",
             "label": "Candidate ID",
             "type": "input",
             "disabled": true,
             "validations": {
               "required": "This field is required",
               "pattern": {
                 "value": "",
                 "message": "Please Input a valid  Name"
               }
             }
           },
           {
             "fieldId": "applicant[email]",
             "required": true,
             "name": "applicant[email]",
             "label": "Email",
             "disabled": true,
             "type": "input",
             "validationType": "email",
             "validations": {
               "required": "This field is required",
               "pattern": {
                 "value": "",
                 "message": "Please Input a valid Email"
               }
             }
           },
           {
             "fieldId": "applicant[phone]",
             "required": true,
             "name": "applicant[phone]",
             "label": "Mobile Number",
             "type": "phone",
             "apiKey": "applicant[phone]",
             "disabled": true,
             "validationType": "phone",
             "validations": {
               "required": "This field is required",
               "pattern": {
                 "value": "",
                 "message": "Please Input a valid Mobile Number"
               }
             }
           },
           {
             "fieldId": "applicant[trainingCentre]",
             "required": true,
             "name": "applicant[trainingCentre]",
             "label": "Training Centre",
             "type": "select",
             "disabled": false,
             "options": [
               {
                 "label": "Gautam Buddha University, Greater Noida",
                 "value": "Gautam Buddha University, Greater Noida"
               },
               {
                 "label": "Deen Dayal Upadhyaya Gorakhpur University, Gorakhpur",
                 "value": "Deen Dayal Upadhyaya Gorakhpur University, Gorakhpur"
               },
               {
                 "label": "Madan Mohan Malviya University of Technology, Gorakhpur",
                 "value": "Madan Mohan Malviya University of Technology, Gorakhpur"
               },
               {
                 "label": "NSIC, Delhi",
                 "value": "NSIC, Delhi"
               },
               {
                 "label": "Babasaheb Bhimrao Ambedkar University, Lucknow",
                 "value": "Babasaheb Bhimrao Ambedkar University, Lucknow"
               },
               {
                 "label": "University of Lucknow, Lucknow",
                 "value": "University of Lucknow, Lucknow"
               },
               {
                 "label": "Khwaja Moinuddin Chishti Language University, Lucknow",
                 "value": "Khwaja Moinuddin Chishti Language University, Lucknow"
               },
               {
                 "label": "NSIC, Chennai",
                 "value": "NSIC, Chennai"
               },
               {
                 "label": "Rajiv Gandhi College of Engineering",
                 "value": "Rajiv Gandhi College of Engineering"
               },
               {
                 "label": "Sri Jayachamarajendra Polytechnic College, Bangalore",
                 "value": "Sri Jayachamarajendra Polytechnic College, Bangalore"
               },
               {
                 "label": "Govt Sri Krishnarajendra Silver JubleeTechnological Institute",
                 "value": "Govt Sri Krishnarajendra Silver JubleeTechnological Institute"
               },
               {
                 "label": "University of Visveshvaraya College of Engineering",
                 "value": "University of Visveshvaraya College of Engineering"
               }
             ]
           },
           {
             "fieldId": "applicant[course]",
             "name": "applicant[course]",
             "label": "Course",
             "required": true,
             "type": "select",
             "rule": {
               "effect": "SHOW",
               "condition": {
                 "scope": "applicant[trainingCentre]",
                 "expectedValue": "Gautam Buddha University, Greater Noida",
                 "type": "LEAF"
               }
             },
             "disabled": false,
             "options": [
               {
                 "label": "Artificial Intelligence",
                 "value": "Artificial Intelligence"
               }
             ]
           },
           {
             "name": "applicant[course]",
             "label": "Course",
             "required": true,
             "type": "select",
             "rule": {
               "effect": "SHOW",
               "condition": {
                 "scope": "applicant[trainingCentre]",
                 "expectedValue": "Deen Dayal Upadhyaya Gorakhpur University, Gorakhpur",
                 "type": "LEAF"
               }
             },
             "disabled": false,
             "options": [
               {
                 "label": "Big Data",
                 "value": "Big Data"
               },
               {
                 "label": "Coding & Programming",
                 "value": "Coding & Programming"
               },
               {
                 "label": "Artificial Intelligence",
                 "value": "Artificial Intelligence"
               },
               {
                 "label": "Internet Of Things",
                 "value": "Internet Of Things"
               }
             ]
           },
           {
             "name": "applicant[course]",
             "label": "Course",
             "required": true,
             "type": "select",
             "rule": {
               "effect": "SHOW",
               "condition": {
                 "scope": "applicant[trainingCentre]",
                 "expectedValue": "Madan Mohan Malviya University of Technology, Gorakhpur",
                 "type": "LEAF"
               }
             },
             "disabled": false,
             "options": [
               {
                 "label": "Coding & Programming",
                 "value": "Coding & Programming"
               },
               {
                 "label": "Artificial Intelligence",
                 "value": "Artificial Intelligence"
               }
             ]
           },
           {
             "name": "applicant[course]",
             "label": "Course",
             "required": true,
             "type": "select",
             "rule": {
               "effect": "SHOW",
               "condition": {
                 "scope": "applicant[trainingCentre]",
                 "expectedValue": "NSIC, Delhi",
                 "type": "LEAF"
               }
             },
             "disabled": false,
             "options": [
               {
                 "label": "Internet Of Things",
                 "value": "Internet Of Things"
               }
             ]
           },
           {
             "name": "applicant[course]",
             "label": "Course",
             "required": true,
             "type": "select",
             "rule": {
               "effect": "SHOW",
               "condition": {
                 "scope": "applicant[trainingCentre]",
                 "expectedValue": "Babasaheb Bhimrao Ambedkar University, Lucknow",
                 "type": "LEAF"
               }
             },
             "disabled": false,
             "options": [
               {
                 "label": "Coding & Programming",
                 "value": "Coding & Programming"
               },
               {
                 "label": "Internet Of Things",
                 "value": "Internet Of Things"
               }
             ]
           },
           {
             "name": "applicant[course]",
             "label": "Course",
             "required": true,
             "type": "select",
             "rule": {
               "effect": "SHOW",
               "condition": {
                 "scope": "applicant[trainingCentre]",
                 "expectedValue": "University of Lucknow, Lucknow",
                 "type": "LEAF"
               }
             },
             "disabled": false,
             "options": [
               {
                 "label": "Artificial Intelligence",
                 "value": "Artificial Intelligence"
               },
               {
                 "label": "Big Data",
                 "value": "Big Data"
               },
               {
                 "label": "Coding & Programming",
                 "value": "Coding & Programming"
               }
             ]
           },
           {
             "name": "applicant[course]",
             "label": "Course",
             "required": true,
             "type": "select",
             "rule": {
               "effect": "SHOW",
               "condition": {
                 "scope": "applicant[trainingCentre]",
                 "expectedValue": "Khwaja Moinuddin Chishti Language University, Lucknow",
                 "type": "LEAF"
               }
             },
             "disabled": false,
             "options": [
               {
                 "label": "Big Data",
                 "value": "Big Data"
               },
               {
                 "label": "Coding & Programming",
                 "value": "Coding & Programming"
               }
             ]
           },
           {
             "name": "applicant[course]",
             "label": "Course",
             "required": true,
             "type": "select",
             "rule": {
               "effect": "SHOW",
               "condition": {
                 "scope": "applicant[trainingCentre]",
                 "expectedValue": "NSIC, Chennai",
                 "type": "LEAF"
               }
             },
             "disabled": false,
             "options": [
               {
                 "label": "Big Data",
                 "value": "Big Data"
               },
               {
                 "label": "Coding & Programming",
                 "value": "Coding & Programming"
               }
             ]
           },
           {
             "name": "applicant[course]",
             "label": "Course",
             "required": true,
             "type": "select",
             "rule": {
               "effect": "SHOW",
               "condition": {
                 "scope": "applicant[trainingCentre]",
                 "expectedValue": "Rajiv Gandhi College of Engineering",
                 "type": "LEAF"
               }
             },
             "disabled": false,
             "options": [
               {
                 "label": "Big Data",
                 "value": "Big Data"
               },
               {
                 "label": "Coding & Programming",
                 "value": "Coding & Programming"
               }
             ]
           },
           {
             "name": "applicant[course]",
             "label": "Course",
             "required": true,
             "type": "select",
             "rule": {
               "effect": "SHOW",
               "condition": {
                 "scope": "applicant[trainingCentre]",
                 "expectedValue": "Sri Jayachamarajendra Polytechnic College, Bangalore",
                 "type": "LEAF"
               }
             },
             "disabled": false,
             "options": [
               {
                 "label": "Coding & Programming",
                 "value": "Coding & Programming"
               },
               {
                 "label": "Artificial Intelligence",
                 "value": "Artificial Intelligence"
               },
               {
                 "label": "Internet Of Things",
                 "value": "Internet Of Things"
               }
             ]
           },
           {
             "name": "applicant[course]",
             "label": "Course",
             "required": true,
             "type": "select",
             "rule": {
               "effect": "SHOW",
               "condition": {
                 "scope": "applicant[trainingCentre]",
                 "expectedValue": "Govt Sri Krishnarajendra Silver JubleeTechnological Institute",
                 "type": "LEAF"
               }
             },
             "disabled": false,
             "options": [
               {
                 "label": "Coding & Programming",
                 "value": "Coding & Programming"
               }
             ]
           },
           {
             "name": "applicant[course]",
             "label": "Course",
             "required": true,
             "type": "select",
             "rule": {
               "effect": "SHOW",
               "condition": {
                 "scope": "applicant[trainingCentre]",
                 "expectedValue": "University of Visveshvaraya College of Engineering",
                 "type": "LEAF"
               }
             },
             "disabled": false,
             "options": [
               {
                 "label": "Coding & Programming",
                 "value": "Coding & Programming"
               }
             ]
           },
           {
             "fieldId": "applicant[dateOfBirth]",
             "required": true,
             "name": "applicant[dateOfBirth]",
             "label": "Date of Birth",
             "type": "date",
             "maxDate": "-18",
             "minDate": "26",
             "validations": {
               "required": "This field is required"
             }
           },
           {
             "fieldId": "applicant[ageAsOnDate]",
             "required": true,
             "name": "applicant[ageAsOnDate]",
             "label": "Age",
             "type": "input",
             "disabled": true,
             "validations": {
               "required": "This field is required"
             }
           },
           {
             "fieldId": "application[proofOfIdentity]",
             "required": true,
             "name": "application[proofOfIdentity]",
             "label": "Proof Of Identity",
             "type": "select",
             "visible": true,
             "validations": {
               "required": "This field is required"
             },
             "options": [
               {
                 "label": "Aadhaar",
                 "value": "Aadhaar"
               },
               {
                 "label": "VoterID",
                 "value": "VoterID"
               },
               {
                 "label": "Driving License",
                 "value": "Driving License"
               }
             ]
           },
           {
             "name": "applicant[aadharNumber]",
             "label": "Aadhaar Number",
             "required": true,
             "rule": {
               "effect": "SHOW",
               "condition": {
                 "scope": "application[proofOfIdentity]",
                 "expectedValue": "Aadhaar",
                 "type": "LEAF"
               }
             },
             "type": "input",
             "validationType": "number",
             "visible": true,
             "fieldId": "aadharNumber",
             "validations": {
               "required": {
                 "value": true,
                 "message": "Field is required"
               },
               "minLength": {
                 "value": 12,
                 "message": "Invalid Aadhaar number. Please enter a 12-digit Aadhaar number."
               },
               "maxLength": {
                 "value": 12,
                 "message": "Invalid Aadhaar number. Please enter a 12-digit Aadhaar number."
               },
               "pattern": {
                 "value": "",
                 "message": "Please input a correct value"
               }
             }
           },
           {
             "name": "applicant[votersId]",
             "label": "Voter ID",
             "required": true,
             "rule": {
               "effect": "SHOW",
               "condition": {
                 "scope": "application[proofOfIdentity]",
                 "expectedValue": "VoterID",
                 "type": "LEAF"
               }
             },
             "type": "input",
             "validationType": "alphaNumeric",
             "visible": true,
             "fieldId": "voterId",
             "validations": {
               "required": {
                 "value": true,
                 "message": "Field is required"
               },
               "minLength": {
                 "value": 10,
                 "message": "Invalid Voter ID. Please enter the correct Voter ID details."
               },
               "maxLength": {
                 "value": 10,
                 "message": "Invalid Voter ID. Please enter the correct Voter ID details."
               },
               "pattern": {
                 "value": "",
                 "message": "Please input a correct value"
               }
             }
           },
           {
             "name": "applicant[drivingLicense]",
             "label": "Driving License",
             "required": true,
             "rule": {
               "effect": "SHOW",
               "condition": {
                 "scope": "application[proofOfIdentity]",
                 "expectedValue": "Driving License",
                 "type": "LEAF"
               }
             },
             "type": "input",
             "validationType": "",
             "visible": true,
             "fieldId": "drivingLicense",
             "validations": {
               "required": {
                 "value": true,
                 "message": "Field is required"
               },
               "minLength": {
                 "value": 8,
                 "message": "Invalid Driving License. Please enter the correct Driving License details."
               },
               "maxLength": {
                 "value": 20,
                 "message": "Invalid Driving License. Please enter the correct Driving License details."
               },
               "pattern": {
                 "value": "",
                 "message": "Please input a correct value"
               }
             }
           },
           {
             "fieldId": "applicant[gender]",
             "required": true,
             "name": "applicant[gender]",
             "label": "Gender",
             "type": "radio",
             "validations": {
               "required": "This field is required"
             },
             "options": [
               {
                 "label": "Male",
                 "value": "Male"
               },
               {
                 "label": "Female",
                 "value": "Female"
               },
               {
                 "label": "Prefer not to say",
                 "value": "Prefer not to say"
               }
             ]
           },
           {
             "fieldId": "applicant[fatherName]",
             "required": true,
             "name": "applicant[fatherName]",
             "label": "Father's Name",
             "type": "input",
             "validationType": "alpha",
             "disabled": false,
             "validations": {
               "required": "This field is required",
               "minLength": {
                 "value": "2",
                 "message": "Please enter a valid name"
               },
               "maxLength": {
                 "value": "40",
                 "message": "This field can't be more than 40 characters"
               },
               "pattern": {
                 "value": "",
                 "message": "Please Input a valid Name"
               }
             }
           },
           {
             "fieldId": "applicant[motherName]",
             "required": true,
             "name": "applicant[motherName]",
             "label": "Mother's Name",
             "type": "input",
             "validationType": "alpha",
             "disabled": false,
             "validations": {
               "required": "This field is required",
               "minLength": {
                 "value": "2",
                 "message": "Please enter a valid name"
               },
               "maxLength": {
                 "value": "40",
                 "message": "This field can't be more than 40 characters"
               },
               "pattern": {
                 "value": "",
                 "message": "Please Input a valid Name"
               }
             }
           },
           {
             "fieldId": "applicant[category]",
             "required": true,
             "name": "applicant[category]",
             "label": "Category",
             "type": "select",
             "visible": true,
             "validations": {
               "required": "This field is required"
             },
             "options": [
               {
                 "label": "General",
                 "value": "General"
               },
               {
                 "label": "OBC",
                 "value": "OBC"
               },
               {
                 "label": "SC",
                 "value": "SC"
               },
               {
                 "label": "ST",
                 "value": "ST"
               },
               {
                 "label": "EWS",
                 "value": "EWS"
               }
             ]
           },
           {
             "fieldId": "applicant[highestQualification]",
             "required": true,
             "name": "applicant[highestQualification]",
             "label": "Highest Qualification",
             "type": "select",
             "disabled": false,
             "options": [
               {
                 "label": "12th",
                 "value": "12th"
               },
               {
                 "label": "Diploma",
                 "value": "Diploma"
               },
               {
                 "label": "UG",
                 "value": "UG"
               },
               {
                 "label": "PG",
                 "value": "PG"
               },
               {
                 "label": "Other",
                 "value": "Other"
               }
             ],
             "validations": {
               "required": "This field is required"
             }
           },
           {
             "name": "applicant[highestQualificationOthers]",
             "label": "Highest Qualification Other",
             "required": true,
             "rule": {
               "effect": "SHOW",
               "condition": {
                 "scope": "applicant[highestQualification]",
                 "expectedValue": "Other",
                 "type": "LEAF"
               }
             },
             "type": "input",
             "validationType": "alpha",
             "visible": true,
             "fieldId": "highestQualificationOthers",
             "validations": {
               "required": {
                 "value": true,
                 "message": "Field is required"
               },
               "pattern": {
                 "value": "",
                 "message": "Please input a correct value"
               }
             }
           },
           {
             "fieldId": "applicant[presentStatus]",
             "required": true,
             "name": "applicant[presentStatus]",
             "label": "Highest Qualification Status",
             "type": "select",
             "validations": {
               "required": "This field is required"
             },
             "options": [
               {
                 "label": "Pursuing",
                 "value": "Pursuing"
               },
               {
                 "label": "Completed",
                 "value": "Completed"
               }
             ]
           },
           {
             "fieldId": "application[yearOfPassing]",
             "required": true,
             "name": "application[yearOfPassing]",
             "label": "Year Of Passing",
             "rule": {
               "effect": "SHOW",
               "condition": {
                 "scope": "applicant[presentStatus]",
                 "expectedValue": "Pursuing",
                 "type": "LEAF"
               }
             },
             "type": "select",
             "validations": {
               "required": "This field is required"
             },
             "options": [
               {
                 "label": "2024",
                 "value": "2024"
               },
               {
                 "label": "2025",
                 "value": "2025"
               },
               {
                 "label": "2026",
                 "value": "2026"
               },
               {
                 "label": "2027",
                 "value": "2027"
               },
               {
                 "label": "2028",
                 "value": "2028"
               },
               {
                 "label": "2029",
                 "value": "2029"
               },
               {
                 "label": "2030",
                 "value": "2030"
               }
             ]
           },
           {
             "name": "application[yearOfPassing]",
             "required": true,
             "label": "Year Of Passing",
             "rule": {
               "effect": "SHOW",
               "condition": {
                 "scope": "applicant[presentStatus]",
                 "expectedValue": "Completed",
                 "type": "LEAF"
               }
             },
             "type": "select",
             "validations": {
               "required": "This field is required"
             },
             "options": [
               {
                 "label": "2017",
                 "value": "2017"
               },
               {
                 "label": "2018",
                 "value": "2018"
               },
               {
                 "label": "2019",
                 "value": "2019"
               },
               {
                 "label": "2020",
                 "value": "2020"
               },
               {
                 "label": "2021",
                 "value": "2021"
               },
               {
                 "label": "2022",
                 "value": "2022"
               },
               {
                 "label": "2023",
                 "value": "2023"
               },
               {
                 "label": "2024",
                 "value": "2024"
               }
             ]
           }
         ]
       },
       {
        "sectionName": "Address Information",
        "apiKeyId": "applicant",
        "subSectionName": "",
        "sectionId": "Addresses",
        "sectionPosition": 1,
        "sectionVisible": true,
        "sectionType": "form",
        "fields": [
          // {
          //   "name": "addresses[0]",
          //   "type": "address",
          //   "options": [
          //     {
          //       "fieldId": "mailingCountry",
          //       "required": true,
          //       "name": "mailingCountry",
          //       "isPartOfCollection": true,
          //       "label": "Country",
          //       "type": "select",
          //       "validations": {
          //         "required": "This field is required"
          //       }
          //     }
          //   ]
          // },
          // {
          //   "name": "addresses[0]",
          //   "type": "address",
          //   "rule": {
          //     "effect": "HIDE",
          //     "condition": {
          //       "scope": "addresses[0][mailingCountry]",
          //       "expectedValue": "India",
          //       "type": "LEAF"
          //     }
          //   },
          //   "options": [
          //     {
          //       "fieldId": "mailingState",
          //       "required": true,
          //       "name": "mailingState",
          //       "label": "State",
          //       "type": "select",
          //       "validations": {
          //         "required": "This field is required"
          //       },
          //       "options": []
          //     },
          //     {
          //       "fieldId": "mailingDistrict",
          //       "required": true,
          //       "name": "mailingDistrict",
          //       "label": "City",
          //       "type": "select",
          //       "validations": {
          //         "required": "This field is required"
          //       },
          //       "options": []
          //     }
          //   ]
          // },
          // {
          //   "fieldId": "addresses[0][mailingPostalCode]",
          //   "required": true,
          //   "name": "addresses[0][mailingPostalCode]",
          //   "label": "PIN / PO BOX/ ZIP",
          //   "type": "input",
          //   "validationType": "number",
          //   "validations": {
          //     "required": "This field is required",
          //     "minLength": {
          //       "value": 6,
          //       "message": "Please enter a valid Pin code"
          //     },
          //     "maxLength": {
          //       "value": 6,
          //       "message": "Please enter a valid Pin code"
          //     },
          //     "pattern": {
          //       "value": "",
          //       "message": "Please enter a valid Pin code"
          //     }
          //   },
          //   "rule": {
          //     "effect": "HIDE",
          //     "condition": {
          //       "scope": "addresses[0][mailingCountry]",
          //       "expectedValue": "India",
          //       "type": "LEAF"
          //     }
          //   }
          // },
          {
            "name": "addresses[0]",
            "type": "pincode",
            // "rule": {
            //   "effect": "SHOW",
            //   "condition": {
            //     "scope": "addresses[0][mailingCountry]",
            //     "expectedValue": "India",
            //     "type": "LEAF"
            //   }
            // },
            "options": [
              {
                "fieldId": "mailingPostalCode",
                "name": "mailingPostalCode",
                "label": "PIN / PO BOX/ ZIP",
                "helperText": "If your city and state is not getting auto populated, Please use the nearest PIN / PO BOX/ ZIP",
                "required": true,
                "validationType": "number",
                "validations": {
                  "required": "This field is required",
                  "maxLength": {
                    "value": 6,
                    "message": "This field can't be more than 6 character"
                  },
                  "pattern": {
                    "value": "",
                    "message": "Please enter a valid Pin code"
                  }

                },
                "ui": {
                  "gridType": "halfRow"
                },
                "type": "input"
              },
              {
                "fieldId": "mailingState",
                "name": "mailingState",
                "label": "State",
                "validations": {
                  "pattern": {
                    "value": "",
                    "message": "Please enter a valid value"
                  },
                  "maxLength": {
                    "value": 80,
                    "message": "This field can't be more than 80 character"
                  }
                },
                "validationType": "alpha",
                "type": "input",
                "ui": {
                  "gridType": "halfRow"
                }
              },
              {
                "fieldId": "mailingDistrict",
                "name": "mailingDistrict",
                "label": "City",
                "validations": {
                  "pattern": {
                    "value": "",
                    "message": "Please enter a valid value"
                  },
                  "maxLength": {
                    "value": 80,
                    "message": "This field can't be more than 80 character"
                  }
                },
                "validationType": "alpha",
                "ui": {
                  "gridType": "halfRow"
                },
                "type": "input"
              }
            ]
          },
          {
            "fieldId": "addresses[0][mailingStreet]",
            "required": true,
            "name": "addresses[0][mailingStreet]",
            "label": "Address",
            "type": "input",
            "validations": {
              "required": "This field is required",
              "maxLength": {
                "value": 250,
                "message": "This field cannot be more than 250 characters"
              },
              "minLength": {
                "value": 5,
                "message": "Please add valid address"
              }
            }
          }
        ]
       },
       {
         "sectionName": "Current Address",
         "apiKeyId": "applicant",
         "subSectionName": "",
         "sectionId": "Addresses",
         "showSectionName": false,
         "sectionPosition": 1,
         "sectionVisible": true,
         "sectionType": "form",
         "fields": [
           {
             "fieldId": "addresses[0][isPermanentAddressSame]",
             "required": true,
             "name": "addresses[0][isPermanentAddressSame]",
             "label": "Is your current address same as permanent address?",
             "type": "select",
             "validations": {
               "required": "This field is required"
             },
             "options": [
               {
                 "label": "Yes",
                 "value": "true"
               },
               {
                 "label": "No",
                 "value": "false"
               }
             ],
             "ui": {
               "gridType": "fullRow"
             }
           },
           {
             "name": "addresses[1]",
             "type": "address",
             "rule": {
               "effect": "SHOW",
               "condition": {
                 "scope": "addresses[0][isPermanentAddressSame]",
                 "expectedValue": "false",
                 "type": "LEAF"
               },
               "unregister": true
             },
             "options": [
               {
                 "fieldId": "mailingCountry",
                 "required": true,
                 "name": "mailingCountry",
                 "isPartOfCollection": true,
                 "label": "Country",
                 "type": "select",
                 "validations": {
                   "required": "This field is required"
                 }
               },
               {
                 "fieldId": "mailingState",
                 "required": true,
                 "name": "mailingState",
                 "label": "State",
                 "type": "select",
                 "validations": {
                   "required": "This field is required"
                 },
                 "options": []
               },
               {
                 "fieldId": "mailingDistrict",
                 "required": true,
                 "name": "mailingDistrict",
                 "label": "City",
                 "type": "select",
                 "validations": {
                   "required": "This field is required"
                 },
                 "options": []
               }
             ]
           },
           {
             "fieldId": "addresses[1][mailingStreet]",
             "name": "addresses[1][mailingStreet]",
             "label": "Address",
             "type": "input",
             "required": true,
             "validationType": "alphaNumeric",
             "validations": {
               "required": "This field is required",
               "minLength": {
                 "value": 5,
                 "message": "Please enter a valid address"
               },
               "maxLength": {
                 "value": 120,
                 "message": "This field cannot be more than 120 characters"
               }
             },
             "ui": {
               "grid": {
                 "xs": 12,
                 "md": 6,
                 "lg": 6
               }
             },
             "rule": {
               "effect": "SHOW",
               "condition": {
                 "scope": "addresses[0][isPermanentAddressSame]",
                 "expectedValue": "false",
                 "type": "LEAF"
               },
               "unregister": true
             }
           },
           {
             "name": "addresses[1]",
             "type": "address",
             "ui": {
               "grid": {
                 "xs": 12,
                 "md": 6,
                 "lg": 6
               }
             },
             "rule": {
               "effect": "SHOW",
               "condition": {
                 "scope": "addresses[0][isPermanentAddressSame]",
                 "expectedValue": "false",
                 "type": "LEAF"
               },
               "unregister": true
             },
             "options": [
               {
                 "fieldId": "hed__MailingCountry__c",
                 "required": true,
                 "name": "hed__MailingCountry__c",
                 "label": "Country",
                 "type": "select",
                 "options": [],
                 "validations": {
                   "required": "This field is required"
                 }
               },
               {
                 "fieldId": "hed__MailingState__c",
                 "required": true,
                 "name": "hed__MailingState__c",
                 "label": "State",
                 "type": "select",
                 "validations": {
                   "required": "This field is required"
                 }
               },
               {
                 "fieldId": "hed__MailingCity__c",
                 "required": true,
                 "name": "hed__MailingCity__c",
                 "label": "City",
                 "type": "select",
                 "validations": {
                   "required": "This field is required"
                 },
                 "options": []
               }
             ]
           },
           {
             "fieldId": "addresses[1][mailingPostalCode]",
             "required": true,
             "name": "addresses[1][mailingPostalCode]",
             "label": "PIN / PO BOX/ ZIP",
             "type": "input",
             "validationType": "alphaNumeric",
             "validations": {
               "required": "This field is required",
               "minLength": {
                 "value": 6,
                 "message": "Please enter a valid Pin code"
               },
               "maxLength": {
                 "value": 6,
                 "message": "Please enter a valid Pin code"
               },
               "pattern": {
                 "value": "",
                 "message": "Please enter a valid Pin code"
               }
             },
             "ui": {
               "grid": {
                 "xs": 12,
                 "md": 6,
                 "lg": 6
               }
             },
             "rule": {
               "effect": "SHOW",
               "condition": {
                 "scope": "addresses[0][isPermanentAddressSame]",
                 "expectedValue": "false",
                 "type": "LEAF"
               },
               "unregister": true
             }
           }
         ]
       },
       {
         "sectionName": "Career Goal Section",
         "apiKeyId": "applicant",
         "subSectionName": "",
         "sectionPosition": 1,
         "sectionVisible": true,
         "sectionType": "form",
         "fields": [
           {
             "fieldId": "career[0][yourWishtoAchieveThroughSICProgram]",
             "required": true,
             "name": "career[0][yourWishtoAchieveThroughSICProgram]",
             "label": "Explain what you would like to achieve through SIC?",
             "type": "multiSelect",
             "disabled": false,
             "validations": {
               "required": "This field is required"
             },
             "options": [
               {
                 "label": "Participate in courses and access all materials free of charge",
                 "value": "Participate in courses and access all materials free of charge"
               },
               {
                 "label": "Experience machine learning tools and real-world problem solving with exciting curriculum",
                 "value": "Experience machine learning tools and real-world problem solving with exciting curriculum"
               },
               {
                 "label": "Grasp the landscape of data science tools and their applications",
                 "value": "Grasp the landscape of data science tools and their applications"
               },
               {
                 "label": "Opportunity to meet Samsung employees, industry leaders and field experts",
                 "value": "Opportunity to meet Samsung employees, industry leaders and field experts"
               }
             ]
           },
           {
             "fieldId": "career[0][hearAboutSIC]",
             "required": true,
             "name": "career[0][hearAboutSIC]",
             "label": "How did you find out about SIC?",
             "type": "select",
             "validations": {
               "required": "This field is required"
             },
             "options": [
               {
                 "label": "Social Media",
                 "value": "Social Media"
               },
               {
                 "label": "Referral",
                 "value": "Referral"
               },
               {
                 "label": "School/University",
                 "value": "School/University"
               },
               {
                 "label": "Event/Conference",
                 "value": "Event/Conference"
               },
               {
                 "label": "Online Advertisement",
                 "value": "Online Advertisement"
               },
               {
                 "label": "Samsung Website",
                 "value": "Samsung Website"
               },
               {
                 "label": "Word of Mouth",
                 "value": "Word of Mouth"
               },
               {
                 "label": "Career Fair",
                 "value": "Career Fair"
               },
               {
                 "label": "ESSCI Website",
                 "value": "ESSCI Website"
               },
               {
                 "label": "Alumni",
                 "value": "Alumni"
               }
             ]
           }
         ]
       }
     ]
   },
   {
     "stepId": "Upload Documents",
     "stepName": "Photo & Document Upload",
     "showStepName": false,
     "stepVisible": true,
     "stepPosition": 1,
     "stepType": "documentUpload",
     "sections": []
   },
   {
     "stepId": "summaryWithDeclaration",
     "stepName": "Summary and Declaration",
     "stepPosition": 5,
     "showStepName": true,
     "stepVisible": true,
     "stepType": "summary",
     "voucher": true,
     "sections": []
   }
];
