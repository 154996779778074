import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import KenIcon from '../../../KenIcon';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { makeStyles } from '@mui/styles';
import { TABLET_BREAKPOINT } from '../../../../Constants/constant';
import { Grid, Slider } from '@mui/material';
import { useTheme } from 'styled-components';

const useStyles = makeStyles((theme) => ({
  title: {
    'font-style': 'normal',
    'font-weight': '500',
    'font-size': '18px',
    'line-height': '100%',
    'letter-spacing': '-0.02em',
    'text-transform': 'uppercase',
    color: theme.palette.KenColors.dark,
    display: 'flex',
    position: 'relative',

    '& > .underline': {
      position: 'absolute',
      width: '55px',
      height: '4px',
      top: '24px',
      background: theme.palette.KenColors.statusYellow,
    },
  },
  programName: {
    'font-style': 'normal',
    'font-weight': '500',
    'font-size': '14px',
    'line-height': '100%',
    color: theme.palette.KenColors.grey2,
    marginTop: 16,
    textAlign: 'left',
  },
  wrapper: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      overflowX: 'auto',
    },
  },
  container: {
    border: `1px solid ${theme.palette.KenColors.innerBorder}`,
    borderRadius: '12px',
    marginTop: theme.spacing(4.5),
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      width: '650px !important',
      marginLeft: 'auto',
      marginRight: 'auto',
    },

    '& .MuiStepConnector-horizontal': {
      display: 'none',
    },

    '& .MuiStepLabel-labelContainer': {
      width: 115,
      display: 'flex',
      justifyContent: 'center',
    },

    '& .MuiStepLabel-labelContainer > span': {
      'font-weight': '400',
      'font-size': '14px',
      'line-height': '150%',
      'text-align': 'center',
      color: theme.palette.KenColors.dark,
    },
  },
  Icon: {
    color: theme.palette.KenColors.grey5,
    marginTop: 32,
    margin: '0px 2px',
  },
  labelRoot: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.KenColors.dark,
  },
  stepRoot: {
    fontWeight: 12,
  },
  labelIcon: {
    background: theme.palette.KenColors.grey5,
  },
  completedIcon: {
    color: theme.palette.KenColors.kenWhite,
    display: 'flex',
    'justify-content': 'center',
    'align-items': 'center',
  },
  completedIconContainer: {
    display: 'flex',
    background: theme.palette.KenColors.darkGreen,
  },
  activeIconContainer: {
    background: theme.palette.KenColors.gradeSectionHeaderLabel,
    fontSize: 16,
    fontWeight: 500,
  },
  upcomingIcon: {
    color: theme.palette.KenColors.gradeSectionHeaderLabel,
    fontSize: 16,
    fontWeight: 500,
  },
  upcomingIconContainer: {
    border: `2px solid ${theme.palette.KenColors.gradeSectionHeaderLabel}`,
    background: theme.palette.KenColors.kenWhite,
  },
  verticalUpcomingIcon: {
    fontSize: 16,
    fontWeight: 500,
  },
  stepper: {
    position: 'relative',
    top: -13,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      top: '-21px',
      padding: 8,
    },
  },

  stepperRoot: {
    justifyContent: 'space-evenly',

    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      overflow: 'auto',
      alignItems: 'flex-start',
      marginTop: 8,
    },
  },
  header: {
    position: 'sticky',
    top: 72,
    zIndex: 100,
    padding: theme.spacing(2),
    background: '#FFFFFF',
    
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  },
  stepContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      flexDirection: 'column',
    },
  },
  iconContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      paddingRight: 0,
      paddingBottom: 8,
    },
  },
}));

const Connector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const StepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: theme.palette.primary.main,
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#52C15A',
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

StepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

function StepIcon(props) {
  const { active, completed, className, completedStepIcon, stepIcon } = props;
  return (
    <StepIconRoot ownerState={{ completed, active }} className={className}>
      {completed ? (
        completedStepIcon ? (
          <KenIcon icon={completedStepIcon} />
        ) : (
          <CheckCircleOutlineOutlinedIcon className="QontoStepIcon-completedIcon" />
        )
      ) : stepIcon ? (
        <KenIcon icon={stepIcon} />
      ) : (
        <CircleOutlinedIcon />
      )}
    </StepIconRoot>
  );
}

StepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

export default function CustomizedSteppers(props) {
  
  const { steps, alternativeLabel = false, connectorProps, activeStep } = props;
  const classes = useStyles();
  const theme = useTheme();
 

  return (
    // <Box className={classes.header}>
    //   <Stepper
    //     classes={{ root: classes.stepperRoot }}
    //     alternativeLabel={alternativeLabel}
    //     activeStep={activeStep}
    //     orientation={'horizontal'}
    //     connector={
    //       connectorProps?.display ? <Connector {...connectorProps} /> : <></>
    //     }
    //   >
    //     {steps.map((step, index) => (
    //       <Step  key={step?.stepId}>
    //         <StepLabel
    //         style={{marginTop:'10px'}}
    //           className={classes.stepContainer}
    //           StepIconComponent={(labelProps) => {
    //             return (
    //               <StepIcon
    //                 {...labelProps}
    //                 stepIcon={step?.icon}
    //                 completedStepIcon={step.completedStepIcon}
    //                 className={classes.iconContainer}
    //               />
    //             );
    //           }}
    //         >
    //           <Typography variant="subtitle1">{step?.stepName}</Typography>
    //         </StepLabel>
        

    //       </Step>
    //     ))}
    //   </Stepper>
    // </Box>
    <Grid
    item
    container
    md={12}
    xs={12}
    direction="column"
    className={classes.wrapper}
  >
    <Grid
      container
      item
      md={12}
      xs={12}
      style={{marginTop:'10px',marginBottom:'10px'}}
      // className={classes.container}
      // maxWidth="100%"
      // overflowX="auto"
    >
      <Grid item md={12} xs={12} minWidth={850} >
         <Stepper
        classes={{ root: classes.stepperRoot }}
        alternativeLabel={alternativeLabel}
        activeStep={activeStep}
        orientation={'horizontal'}
        connector={
          connectorProps?.display ? <Connector {...connectorProps} /> : <></>
        }
      >
        {steps.map((step, index) => (
          <Step  key={step?.stepId}>
            <StepLabel
            style={{marginTop:'10px'}}
              className={classes.stepContainer}
              StepIconComponent={(labelProps) => {
                return (
                  <StepIcon
                    {...labelProps}
                    stepIcon={step?.icon}
                    completedStepIcon={step.completedStepIcon}
                    className={classes.iconContainer}
                  />
                );
              }}
            >
              <Typography variant="subtitle1">{step?.stepName}</Typography>
            </StepLabel>
        

          </Step>
        ))}
      </Stepper>
      </Grid>
    </Grid>
  </Grid>
  );
}
