import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import KenCircularProgressWithLabel from '../../../../Components/KenProgress/KenCircularProgressWithLabel';
import { useTranslation } from 'react-i18next';
import { FormContext } from '../../../../Context/formContext';

const useStyles = makeStyles((theme) => ({
  listStyle: {
    background: theme.palette.card.backgroundColor,
    boxShadow:
      '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    borderRadius: '4px',
    alignItems: 'center',
    padding: '16px',
    '&:hover': {
      background: theme.palette.card.hover,
    },
  },
  courseCount: {
    fontFamily: "'Poppins'",
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '100%',
    color: theme.palette.labels.light,
    textAlign: 'left',
  },
  coursetitle: {
    fontFamily: "'Poppins'",
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '100%',
    color: theme.palette.primary.main,
    textAlign: 'left',
    marginLeft: '6px',
  },
  courseDuration: {
    fontFamily: "'Poppins'",
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '100%',
    color: theme.palette.labels.medium,
    textAlign: 'left',
  },
  courseDate: {
    fontFamily: "'Poppins'",
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '100%',
    textTransform: 'capitalize',
    color: theme.palette.labels.medium,
  },
  courseButton: {
    fontFamily: "'Poppins'",
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '100%',
    color: theme.palette.primary.main,
    flex: 'none',
    order: '0',
    flexGrow: '0',
    margin: '0px 10px',
  },
}));
export default function CoursesListView(props) {
  const { data } = props;
  const classes = useStyles();
  const { formState, formDispatch } = useContext(FormContext);
  const navigate = useNavigate();
  const [active, setActive] = useState(null);
  const { t } = useTranslation();

  const goToApplicationForm = (item) => {
    formDispatch({
      type: 'new',
      payload: {
        programDetails: { ...item, formId: item.recordId },
      },
    });
    navigate({
      pathname: '/application',
      search: `?formId=${item?.recordId}&type=${item?.departmentName}`,
    });
  };
  const activeBorder = (active) => {
    setActive(active);
  };
  return (
    <Box mb={4}>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Typography className={classes.courseCount}>
            {data.length} {t('labels:Courses')}
          </Typography>
        </Grid>
        {data &&
          data.map((item) => {
            return (
              <Grid item md={12} sm={12} xs={12}>
                <Paper
                  className={classes.listStyle}
                  onClick={(e) => {
                    activeBorder(item.id);
                  }}
                  style={{
                    border: active === item.id ? '1px solid #092682' : '',
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item sm={3}>
                      <Typography
                        noWrap
                        className={classes.coursetitle}
                        sx={{ marginTop: '10px' }}
                      >
                        {item.course}
                      </Typography>
                    </Grid>
                    <Grid item sm={3}>
                      <Typography
                        noWrap
                        className={classes.courseDuration}
                        sx={{ marginTop: '10px' }}
                      >
                        {item.courseDuration && `${item.courseDuration} |`}{' '}
                        {item.courseBatch}
                      </Typography>
                    </Grid>
                    <Grid item sm={3}>
                      <Typography
                        className={classes.courseDate}
                        sx={{ marginTop: '10px' }}
                      >
                        {t('labels:Last_date')}&nbsp; : &nbsp;
                        <strong
                          style={{ color: item?.status ? '#FE7E6D' : '' }}
                        >
                          {item.lastdate}
                        </strong>
                      </Typography>
                    </Grid>

                    <Grid item sm={1}>
                      {item?.status ? (
                        <KenCircularProgressWithLabel value={25} size={30} />
                      ) : (
                        ''
                      )}
                    </Grid>
                    <Grid item sm={2}>
                      <Button
                        className={classes.courseButton}
                        size="small"
                        onClick={() => goToApplicationForm(item)}
                      >
                        {item?.status
                          ? t('labels:Complete_Application')
                          : t('labels:Apply_Now')}
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
}
