import * as React from 'react';
import { classNames, Viewer } from '@react-pdf-viewer/core';
import { toolbarPlugin, ToolbarSlot } from '@react-pdf-viewer/toolbar';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import { useAppContext } from '../../Context/appContext';
// import { postgetPdf } from '../../Utils/apiServices';
import KenButton from '../KenButton';
import PenIcon from '../../Assets/SvgImages/PenIcon.svg';
import DownloadIcon from '../../Assets/SvgImages/DownloadIcon.svg';
import PrintIcon from '../../Assets/SvgImages/PrintIcon.svg';
import ZoomInIcon from '../../Assets/SvgImages/ZoomInIcon.svg';
import ZoomOutIcon from '../../Assets/SvgImages/ZoomOutIcon.svg';

export default function KenPDFViewer(props) {
  const {
    toolbarFlag = true,
    attachmentData: { body },
    handleStatus,
    hidden = false,
  } = props;
  const [base64, setBase64] = React.useState();
  const [url, setUrl] = React.useState();
  React.useEffect(() => {
    setBase64(base64toBlob(body));
  }, [props]);
  const {
    state: { config },
  } = useAppContext();
  config.totalSteps = 6;
  const toolbarPluginInstance = toolbarPlugin();
  const thumbnailPluginInstance = thumbnailPlugin();

  const { Toolbar } = toolbarPluginInstance;
  const { Thumbnails } = thumbnailPluginInstance;

  // const base64 =
  //   'data:application/pdf;base64,JVBERi0xLjcKCjEgMCBvYmogICUgZW50cnkgcG9pbnQKPDwKICAvVHlwZSAvQ2F0YWxvZwogIC9QYWdlcyAyIDAgUgo+PgplbmRvYmoKCjIgMCBvYmoKPDwKICAvVHlwZSAvUGFnZXMKICAvTWVkaWFCb3ggWyAwIDAgMjAwIDIwMCBdCiAgL0NvdW50IDEKICAvS2lkcyBbIDMgMCBSIF0KPj4KZW5kb2JqCgozIDAgb2JqCjw8CiAgL1R5cGUgL1BhZ2UKICAvUGFyZW50IDIgMCBSCiAgL1Jlc291cmNlcyA8PAogICAgL0ZvbnQgPDwKICAgICAgL0YxIDQgMCBSIAogICAgPj4KICA+PgogIC9Db250ZW50cyA1IDAgUgo+PgplbmRvYmoKCjQgMCBvYmoKPDwKICAvVHlwZSAvRm9udAogIC9TdWJ0eXBlIC9UeXBlMQogIC9CYXNlRm9udCAvVGltZXMtUm9tYW4KPj4KZW5kb2JqCgo1IDAgb2JqICAlIHBhZ2UgY29udGVudAo8PAogIC9MZW5ndGggNDQKPj4Kc3RyZWFtCkJUCjcwIDUwIFRECi9GMSAxMiBUZgooSGVsbG8sIHdvcmxkISkgVGoKRVQKZW5kc3RyZWFtCmVuZG9iagoKeHJlZgowIDYKMDAwMDAwMDAwMCA2NTUzNSBmIAowMDAwMDAwMDEwIDAwMDAwIG4gCjAwMDAwMDAwNzkgMDAwMDAgbiAKMDAwMDAwMDE3MyAwMDAwMCBuIAowMDAwMDAwMzAxIDAwMDAwIG4gCjAwMDAwMDAzODAgMDAwMDAgbiAKdHJhaWxlcgo8PAogIC9TaXplIDYKICAvUm9vdCAxIDAgUgo+PgpzdGFydHhyZWYKNDkyCiUlRU9G';
  // const pdfContentType = 'application/pdf';

  const base64toBlob = (data) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    // const base64WithoutPrefix = data.substr(
    //   'data:application/pdf;base64,'.length
    // );

    const bytes = atob(data);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: 'application/pdf' });
  };

  React.useEffect(() => {
    if (base64) {
      setUrl(URL.createObjectURL(base64));
    }
  }, [base64]);

  return (
    <>
      {url && (
        <div
          style={{
            border: '1px solid rgba(0, 0, 0, 0.3)',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          {toolbarFlag && (
            <div
              style={{
                alignItems: 'center',
                backgroundColor: '#ffffff',
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                padding: '4px',
              }}
            >
              <Toolbar>
                {(props) => {
                  const {
                    CurrentPageInput,
                    CurrentScale,
                    GoToNextPage,
                    GoToPreviousPage,
                    NumberOfPages,
                    ZoomIn,
                    ZoomOut,
                  } = props;
                  return (
                    <div
                      style={{
                        background: '#ffffff',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'right',
                        justifyContent: 'flex-end',
                        marginTop: 8,
                      }}
                    >
                      <div style={{ padding: '8px 16px' }}>
                        <ZoomOut>
                          {(props) => {
                            return (
                              <img src={ZoomOutIcon} onClick={props.onClick} />
                            );
                          }}
                        </ZoomOut>
                      </div>
                      <div style={{ padding: '8px 16px' }}>
                        <ZoomIn>
                          {(props) => (
                            <img src={ZoomInIcon} onClick={props.onClick} />
                          )}
                        </ZoomIn>
                      </div>
                      <div style={{ padding: '8px 16px' }}>
                        <img src={PenIcon} />
                      </div>
                      <div style={{ padding: '8px 16px' }}>
                        <img src={PrintIcon} />
                      </div>
                      <div style={{ padding: '8px 16px' }}>
                        <img src={DownloadIcon} />
                      </div>
                      {/* <div style={{ padding: '8px 16px', marginLeft: 'auto' }}>
                    <GoToPreviousPage>
                      {(props) => (
                        <button
                          style={{
                            backgroundColor: props.isDisabled
                              ? '#96ccff'
                              : '#357edd',
                            border: 'none',
                            borderRadius: '4px',
                            color: '#ffffff',
                            cursor: props.isDisabled
                              ? 'not-allowed'
                              : 'pointer',
                            padding: '8px',
                          }}
                          disabled={props.isDisabled}
                          onClick={props.onClick}
                        >
                          Previous page
                        </button>
                      )}
                    </GoToPreviousPage>
                  </div>
                  <div style={{ padding: '8px 16px', width: '4rem' }}>
                    <CurrentPageInput />
                  </div>
                  <div style={{ padding: '12px 0px' }}>
                    / <NumberOfPages />
                  </div>
                  <div style={{ padding: '8px 16px' }}>
                    <GoToNextPage>
                      {(props) => (
                        <button
                          style={{
                            backgroundColor: props.isDisabled
                              ? '#96ccff'
                              : '#357edd',
                            border: 'none',
                            borderRadius: '4px',
                            color: '#ffffff',
                            cursor: props.isDisabled
                              ? 'not-allowed'
                              : 'pointer',
                            padding: '8px',
                          }}
                          disabled={props.isDisabled}
                          onClick={props.onClick}
                        >
                          Next page
                        </button>
                      )}
                    </GoToNextPage>
                  </div> */}
                      <div style={{ padding: '2px 8px' }}>
                        {hidden === false && (
                          <KenButton
                            onClick={() => {
                              handleStatus('Rejected');
                            }}
                            variant="roundedButtonOutlined"
                          >
                            Reject
                          </KenButton>
                        )}
                      </div>
                      <div style={{ padding: '2px 8px' }}>
                        {hidden === false && (
                          <KenButton
                            onClick={() => {
                              handleStatus('Accepted');
                            }}
                            variant="roundedButton"
                          >
                            Accept
                          </KenButton>
                        )}
                      </div>
                    </div>
                  );
                }}
              </Toolbar>
            </div>
          )}

          {/* <Thumbnails /> */}
          <div
            style={{
              flex: 1,
              overflow: 'hidden',
            }}
          >
            <Viewer fileUrl={url} plugins={[toolbarPluginInstance]} />
          </div>
        </div>
      )}
    </>
  );
}
