import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import _ from 'lodash';

import { useApplicationContext } from '../../Context/applicationContext';
import {
  addExtrasFieldsToObject,
  applicationAddCurrentStep,
  dirtyValues,
  filterDirtyFields,
  getCurrentStepPayload,
  getDirtyValues,
} from '../../Utils/Helpers/applicationHelper';
import Footer from './Footer';
import Section from './Section';
import { createUpdateApplication } from '../../Utils/apiServices';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useAppContext } from '../../Context/appContext';
import KenStepperFooter from '../KenApplicationForm/Components/KenStepperFooter';
import { AuthContext } from '../../Context/authContext';

function Form({ step: { sections = [] } = {} }, ref) {
  const {
    applicationState: {
      applicationData,
      applicationId,
      activeStep,
      applicantId,
      totalSteps,
    } = {},
    applicationDispatch,
  } = useApplicationContext();
  const user = JSON.parse(sessionStorage.getItem('user'));
  const { dispatch: dispatchAppContext } = useAppContext();
  const { dispatch } = useContext(AuthContext);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  //   Use any pcakge to conver json to schema like json-schema-to-yup
  //   const schema = yup.object().shape({
  //     applicant: yup.object().shape({
  //       firstName: yup.string().required(),
  //       email: yup.string().required(),
  //     }),
  //   });

  
  // React hook form to maintain form state
  const methods = useForm({
    defaultValues: applicationData,
    mode: 'all',
    reValidateMode: 'onChange',
    // resolver: yupResolver(schema),
    // resolver: yupResolver(validateSchema),
  });

  const {
    formState: { errors, dirtyFields },
    getValues,
    reset,
    setValue,
  } = methods;

  console.log('Form ');
  // reset form on change of activeStep
  useEffect(() => {
    resetForm();
    window.scrollTo(0, 0);
  }, [activeStep]);

  const setEducationHistoryType = (data) => {
    const copyData = [...data];
    if (data?.length === 4) {
      copyData[0].educationHistoryType = 'Post Graduation';
      copyData[1].educationHistoryType = 'Under Graduation';
      copyData[2].educationHistoryType = '12th';
      copyData[3].educationHistoryType = '10th';
    } else if (data?.length === 3) {
      copyData[0].educationHistoryType = 'Under Graduation';
      copyData[1].educationHistoryType = '12th';
      copyData[2].educationHistoryType = '10th';
    } else {
      copyData[0].educationHistoryType = '12th';
      copyData[1].educationHistoryType = '10th';
    }
    return { educationHistories: [...copyData] };
  };

  // Reset form
  const resetForm = (data = applicationData) => {
    reset(data, {
      keepDirty: false,
      keepErrors: false,
      keepDirtyValues: false,
      keepTouched: false,
      keepValues: false,
    });
    // Object.keys(data).forEach((k) => {
    //   setValue(k, data[k], {
    //     shouldDirty: false,
    //     shouldTouch: false,
    //   });
    // });
  };

  // Next handler
  const handleNext = () => {
    if (!_.isEmpty(errors)) {
      //alert "Please resolve all errors"
      console.log('Please resolve all errors');
      enqueueSnackbar(t('messages:Application_Errors_Found'), {
        variant: 'info',
      });
      return;
    }
    ref?.current?.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true })
    );
  };

  const handlePrev = () => {
    applicationDispatch({ type: 'prevStep' });
  };

  const handleSave = () => {
    // If any errors in form show alert message
    // Get dirty fields and
    console.log(dirtyFields, errors, "save");
    

    if (!_.isEmpty(errors)) {
      //alert "Please resolve all errors"
      console.log('Please resolve all errors');
      enqueueSnackbar(t('messages:Application_Errors_Found'), {
        variant: 'error',
      });
      return;
    }

    const changedValues = getDirtyValues(
      filterDirtyFields(dirtyFields),
      getValues()
    );

    // if (changedValues?.educationHistories) {
    //   changedValues = setEducationHistoryType(
    //     changedValues?.educationHistories
    //   );
    // }

    // if no dirtyValues show alert no changes found
    if (_.isEmpty(changedValues)) {
      console.log('No Changes found');
      enqueueSnackbar(t('messages:Application_No_Changes'), {
        variant: 'info',
      });
      return;
    }

    // on error alert to enter all the mandatory fields
    // Check special condition "Relationship >> lastName" if any field of relation has data

    // payload based on the current steps and sections
    // const payload = getCurrentStepPayload(changedValues, applicationData);

    // TODO: check with api if only the dirty can be sent

    // call api to update

    console.log('SAVE>> ', changedValues);


    const user = JSON.parse(sessionStorage.getItem('user'));
    // const applicantEmail = sessionStorage.getItem('applicantEmail');
    // // const recordId = sessionStorage.getItem('recordId');
    // const applicantName = sessionStorage.getItem('applicantName');
    // const applicantDob = sessionStorage.getItem('applicantDob');

    const payload = addExtrasFieldsToObject(changedValues, {
      applicationId,
      applicantId,
      parentId: applicantId,
    });

    const selectOptions = payload.career[0]?.yourWishtoAchieveThroughSICProgram
    const keysToRemove = ['applicationId', 'applicantId', 'parentId'];

    const selectedStrings = selectOptions.map(obj => {
      const filteredObj = Object.fromEntries(Object.entries(obj).filter(([key]) => !keysToRemove.includes(key)));
      return Object.values(filteredObj).join('');
    }).join(';');

    payload.career[0].yourWishtoAchieveThroughSICProgram = selectedStrings

    createUpdateApplication({ applicationId, ...payload })
      .then((res) => {
        // on success update context data and active step
        if (res?.success) {
          console.log('Saved data successfully');
          console.log('Applicant Campus:', user?.applicantCampus); 
          enqueueSnackbar(t('messages:Application_Save_Success'), {
            variant: 'success',
          });
          let data = res.data;
          data.applicant.combineFirstLast = data?.applicant?.firstName + ' ' + data?.applicant?.lastName
          applicationDispatch({
            type: 'update',
            payload: { applicationData: data },
          });
          dispatch({
            type: 'LOGIN',
            payload: {
              user: {
                applicantEmail: user?.applicantEmail,
                applicantId: res?.data?.applicant?.recordId,
                applicantName: user?.applicantName,
                applicantDob: user?.applicantDob,
                applicantCampus: user?.applicantCampus,
              },
            },
          });
          resetForm(res.data);
        }
      })
      .catch((err) => {
        //alert user of failure > try again
        console.log('Updating data failed. Try again');
        enqueueSnackbar(t('messages:Application_Save_Failure'), {
          variant: 'error',
        });
      });
  };

  const onSubmit = (formValues) => {
    if (!_.isEmpty(errors)) {
      //alert "Please resolve all errors"
      console.log('Please resolve all errors');
      return;
    }

    // Get values of only dirtyFields

    let changedValues = getDirtyValues(
      filterDirtyFields(dirtyFields),
      getValues()
    );

    // if(changedValues?.addresses) {
    //   if (changedValues.addresses?.length > 2) {
    //     changedValues.addresses.pop();
    //   }

    //   console.log(applicationData, "applicationState")
      
    //   const getAddress = applicationData?.applicationData?.addresses?.length > 0 && applicationData?.applicationData?.addresses[1];
    //   console.log(getAddress, applicantId, "getAddress")
    //   if (changedValues.addresses[0]?.Is_Current_Address_Same_As_Permanent__c) {
    //     if (changedValues.addresses[0]?.Is_Current_Address_Same_As_Permanent__c === 'Yes') {
    //       const currentAddress = { ...changedValues.addresses[0] };
    //       if (getAddress) {
    //         currentAddress['Id'] = getAddress.Id;
    //       }
    //       changedValues.addresses[1] = { ...currentAddress };
    //     }
    //     changedValues.addresses[0].hed__Address_Type__c = 'Permanent';
    //     changedValues.addresses[0].hed__Default_Address__c = true;
    //     changedValues.addresses[1].hed__Address_Type__c = 'Mailing';
    //     changedValues.addresses[1].hed__Default_Address__c = false;
    //     changedValues.addresses[0].hed__Parent_Contact__c = applicantId;
    //     changedValues.addresses[1].hed__Parent_Contact__c = applicantId;
    //     if (getAddress) {
    //       changedValues.addresses[1]['Id'] = getAddress.Id;
    //     }
    //   } 
    //   else {
    //     changedValues.addresses = [
    //       ...changedValues.addresses.map((items) => {
    //         items.hed__Parent_Contact__c = user.id;
    //         return items;
    //       }),
    //     ];
    //   }
    // }

    if (changedValues?.educationHistories) {
      changedValues = setEducationHistoryType(
        changedValues?.educationHistories
      );
    }

    console.log('dirtyValues: ', changedValues);
    // if no dirtyValues show alert no changes found
    if (_.isEmpty(changedValues)) {
      console.log('No Changes found');
      applicationDispatch({ type: 'nextStep' });
      return;
    }

    // // payload based on the current steps and sections
    // const payload = getCurrentStepPayload(changedValues, applicationData);

    const payload = addExtrasFieldsToObject(changedValues, {
      applicationId,
      applicantId,
      parentId: applicantId,
    });

    // debugger
    // console.log(payload.career, "paddle")
    if(payload.addresses) {
      payload.addresses[0].mailingCountry = "India"
    }

    if (payload?.career) {
      const selectOptions = payload?.career[0]?.yourWishtoAchieveThroughSICProgram
      const keysToRemove = ['applicationId', 'applicantId', 'parentId'];
      

      const selectedStrings = selectOptions.map(obj => {
        const filteredObj = Object.fromEntries(Object.entries(obj).filter(([key]) => !keysToRemove.includes(key)));
        return Object.values(filteredObj).join('');
      }).join(';');

      payload.career[0].yourWishtoAchieveThroughSICProgram = selectedStrings
    }
    // SetLoader
    dispatchAppContext({ type: 'udpateLoading', value: true });
    // update currentStep
    createUpdateApplication({
      applicationId,
      ...payload,
      ...applicationAddCurrentStep(
        payload.application,
        applicationData,
        activeStep
      ),
    })
      .then((res) => {
        dispatchAppContext({ type: 'udpateLoading', value: false });
        // on success update context data and active step
        if (res?.success) {
          // update context
          let data = res.data;
          data.applicant.combineFirstLast = data?.applicant?.firstName + ' ' + data?.applicant?.lastName
          applicationDispatch({
            type: 'update',
            payload: { applicationData: data },
          });
          applicationDispatch({ type: 'nextStep' });
        }
      })
      .catch((err) => {
          err.response.data.errorMessages.map((error) => {
            enqueueSnackbar(error.errorMessage, {
              variant: 'error',
            }
          )
        })
        dispatchAppContext({ type: 'udpateLoading', value: false });
        //alert user of failure > try again
        console.log('Updating data failed. Try again');
      });
    // applicationDispatch({ type: 'nextStep' });
  };

  return (
    <div>
      <form
        ref={ref}
        onSubmit={methods.handleSubmit(onSubmit)}
        style={{ marginBottom: 120 }}
      >
        <FormProvider {...methods}>
          {sections.map((section, index) => (
            <Section
              {...section}
              key={index}
              isLast={index === sections.length - 1}
            />
          ))}
        </FormProvider>
      </form>
      {/* // Footer stepper is consistent   */}
      {/* <Footer /> */}
      
      
      <KenStepperFooter
        onNext={handleNext}
        onPrev={handlePrev}
        onSave={handleSave}
        activeStep={activeStep}
        totalSteps={totalSteps}
      />
    </div>
  );
}

export default React.forwardRef(Form);
