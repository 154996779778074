import moment from 'moment';

const userData = JSON.parse(sessionStorage.getItem('user'));

export const courseFilter = (courses, applicantCampus) => {
  // console.log(courses,"courses")
  // console.log(applicantCampus, "applicantCampus")
  let relevantCourses = [];
  
  switch (applicantCampus[0]) {
    // switch (applicantCampus) {
      case 'Gautam Buddha University, Greater Noida':
        relevantCourses = [
          {
            label: 'Artificial Intelligence',
            value: 'Artificial Intelligence',
          },
        ];
        break;
      case 'Deen Dayal Upadhyaya Gorakhpur University, Gorakhpur':
        relevantCourses = [
          { label: 'Coding & Programming', value: 'Coding & Programming' },
          { label: 'Artificial Intelligence', value: 'Artificial Intelligence' },
          { label: 'Internet Of Things', value: 'Internet Of Things' },
          { label: 'Big Data', value: 'Big Data' },
        ];
        break;
      case 'Madan Mohan Malviya University of Technology, Gorakhpur':
        relevantCourses = [
          { label: 'Coding & Programming', value: 'Coding & Programming' },
          { label: 'Artificial Intelligence', value: 'Artificial Intelligence' },
        ];
        break;
      case 'NSIC, Delhi':
        relevantCourses = [
          { label: 'Internet Of Things', value: 'Internet Of Things' }
        ];
        break;
      case 'Babasaheb Bhimrao Ambedkar University, Lucknow':
        relevantCourses = [
          { label: 'Coding & Programming', value: 'Coding & Programming' },
          { label: 'Internet Of Things', value: 'Internet Of Things' },
        ];
        break;
      case 'NSIC, Chennai':
        relevantCourses = [
          { label: 'Coding & Programming', value: 'Coding & Programming' },
          { label: 'Big Data', value: 'Big Data' },
        ];
        break;
      case 'Rajiv Gandhi College of Engineering':
        relevantCourses = [
          { label: 'Coding & Programming', value: 'Coding & Programming' },
            { label: 'Big Data', value: 'Big Data' },
        ];
        break;
      case 'University of Lucknow, Lucknow':
        relevantCourses = [
          { label: 'Artificial Intelligence', value: 'Artificial Intelligence' },
          { label: 'Big Data', value: 'Big Data' },
          { label: 'Coding & Programming', value: 'Coding & Programming' },
        ];
        break;
      case 'Khwaja Moinuddin Chishti Language University, Lucknow':
        relevantCourses = [
          { label: 'Big Data', value: 'Big Data' },
          { label: 'Coding & Programming', value: 'Coding & Programming' },
        ];
        break;
      case 'Sri Jayachamarajendra Polytechnic College, Bangalore':
        relevantCourses = [
          { label: 'Coding & Programming', value: 'Coding & Programming' },
          { label: 'Artificial Intelligence', value: 'Artificial Intelligence' },
          { label: 'Internet Of Things', value: 'Internet Of Things' },
        ];
        break;
      case 'Govt Sri Krishnarajendra Silver JubleeTechnological Institute':
        relevantCourses = [
          { label: 'Coding & Programming', value: 'Coding & Programming' },
        ];
        break;
      case 'University of Visveshvaraya College of Engineering':
        relevantCourses = [
          { label: 'Coding & Programming', value: 'Coding & Programming' },
        ];
        break; 

      default:
        relevantCourses = [];
        return courses;
        break;
    }


    if (relevantCourses.length > 0) {
      // Filter the data based on programId
      const filteredData = courses.filter((item) =>
        relevantCourses.some((course) => course.value === item.programName)
      );
      console.log('filtered data', filteredData);
      console.log('relevantCourses data', relevantCourses);
      if (filteredData.length > 0) {
        const data = filteredData.map((el) => {
          const isBetween = moment(el?.endDate).diff(moment(), 'day');
          if (isBetween >= 0) {
            console.log('Program Name:', el?.programName);
            return {
              course: el?.programName,
              duration: `${
                isNaN(moment(el?.endDate).diff(moment(el?.startDate), 'year'))
                  ? 0
                  : moment(el?.endDate).diff(moment(el?.startDate), 'year')
              } yrs`,
              lastdate: el?.endDate,
              id: el?.recordId,
              ...el,
            };
          }
        });
        console.log('dataaaaa', data);
        return data;
      }
    }
};
