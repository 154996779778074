import React, { useReducer } from 'react';
import { getApplicationByApplicationId } from '../Utils/apiServices';

export const initialState = {
    applicationId: null,
    applicationData: null, //Data received from salesforce
    formId: null,
    form: null, // form related data from salesforce with template json
    // contactInfo: {},
    formOptions: {},
    activeStep: null,
    isSubmitted: true,
    totalSteps: 0,
    steps: [],
    showHeader: true,
    declaration: false,
};

export const reducer = (state, action) => {
    switch (action.type) {
        case 'update':
            return {
                ...state,
                ...action.payload,
            };

        case 'prevStep':
            //got prev step only if >0 else active step is zero
            return {
                ...state,
                activeStep: state?.activeStep > 0 ? state.activeStep - 1 : 0,
            };
        // check if last step and stop updating
        case 'nextStep':
            return {
                ...state,
                activeStep:
                    state?.activeStep < state?.totalSteps - 1
                        ? state.activeStep + 1
                        : state.activeStep,
            };

        case 'showHeader':
            return {
                ...state,
                showHeader: state.showHeader === true ? false : true,
            };

        case 'reset':
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export const ApplicationContext = React.createContext();

export const ApplicationContextProvider = (props) => {
    const [applicationState, applicationDispatch] = useReducer(
        reducer,
        initialState
    );

    const updateApplicationData = () => {
        if (applicationState?.applicationId) {
            getApplicationByApplicationId(applicationState?.applicationId)
                .then((res) => {
                    applicationDispatch({
                        type: 'update',
                        payload: {
                            applicationData: res?.data,
                        },
                    });
                })
                .catch((err) => {
                    console.log('Update Application Data err: ', err);
                });
        }
    };
    return (
        <ApplicationContext.Provider
            value={{
                applicationState,
                applicationDispatch,
                updateApplicationData,
            }}
        >
            {props.children}
        </ApplicationContext.Provider>
    );
};

export const useApplicationContext = () => {
    // debugger
    const context = React.useContext(ApplicationContext);
    console.log(context,"context");

    if (!context) {
        throw new Error('useApplicationContext within provider');
    }

    return context;
};
