import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Button, CircularProgress } from '@mui/material';
import { SubTitle, Title } from '../common';
import KenButton from '../../../Components/KenButton';
import OtpInput from 'react-otp-input';
import Countdown from 'react-countdown';
import { useSnackbar } from 'notistack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    backgroundColor: 'white',
    borderRadius: '12px',
    minWidth: '470px',
    maxWidth: '470px',
    minHeight: '-webkit-fill-available',
    // "box-shadow":
    //   "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
    // "border-radius": "16px",
    padding: '25px 60px',
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'none',
      padding: '8px',
      minWidth: '360px',
      maxWidth: '360px',
    },
  },
  title: {
    marginBottom: '16px',
  },
  subTitle: {
    fontSize: '16px !important',
    fontWeight: '500 !important',
    marginBottom: '32px',
    margin: '0 auto 20px',
    display: 'block',
    textAlign: 'center',
    marginTop: '10px',
  },
  mobileLabel: {
    'font-style': 'normal',
    'font-weight': '400',
    'font-size': '14px',
    'line-height': '100%',
    'text-transform': 'capitalize',
    color: '#505F79',
  },
  verify: {
    width: '40%',
    height: '40px',
    // marginBottom: '40px',
    borderRadius: '12px !important',
    fontSize: '16px',
    fontWeight: 600,
    backgroundColor: '#4a006a !important',
    color: 'white !important',
    fontFamily: 'SamsungSharp',
    margin: 'auto !important', // Horizontally center the button
  },
  containerStyle: {
    color: 'red',
    '& div': {
      width: '70px',
      border: '0',
      borderBottom: '1px solid #A8AFBC;',
      margin: '16px 8px',
    },
  },
  inputStyle: {
    width: '100% !important',
    border: '0',
    height: '35px',

    '&:focus-visible': {
      outline: 'none',
    },
  },
  resend: {
    'font-weight': '400',
    'font-size': '14px',
    'line-height': '100%',
    color: '#52C15A',
    marginTop: '-12px'
  },
  logoCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginBottom: 20,
  },
}));

let COOL_DOWN_COUNT_IN_SECONDS = 10 * 60;
function pad(n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export default function Verify(props) {
  const classes = useStyles();
  const [otp, setOtp] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [countDown, setCountDown] = useState(
    Date.now() + COOL_DOWN_COUNT_IN_SECONDS * 1000
  );
  const countDownRef = useRef();
  const handleChange = (otp) => {
    setOtp(otp);
  };

  useEffect(() => {
    if (countDownRef) {
      countDownRef.current.api.start();
    }
  }, [countDown]);

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <Button
          className={classes.resend}
          onClick={(e) => {
            e.preventDefault();
            if (typeof props.resendOTP === 'function') {
              props.resendOTP();
            }
            setCountDown(Date.now() + COOL_DOWN_COUNT_IN_SECONDS * 1000);
          }}
        >
          Resend
        </Button>
      );
    } else {
      // Render a countdown
      return (
        <span>
          {pad(minutes, 2)}:{pad(seconds, 2)}
        </span>
      );
    }
  };

  return (
    <Box
      className={classes.contentWrapper}
      display={'flex'}
      flexDirection="column"
      justifyContent={'flex-start'}
      alignItems="flex-start"
    >
      <Box className={classes.logoCenter}>
        <img
          style={{
            height: '90px',
            borderRadius: '12px',
            width: '150px',
          }}
          src={'https://infivestoragedev.blob.core.windows.net/infive/SIC.jpeg'}
          className={classes.logo}
          alt=""
        />
      </Box>
      <Box sx={{display: 'flex', alignItems: 'flex-start'}}>
        <IconButton
          style={{  }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIcon className={classes.backIcon} />
        </IconButton>

        <Title className={classes.title} text={props.title} />
        <SubTitle className={classes.subTitle} text={props.subTitle} />
        
      </Box>
      {props.children}
      <OtpInput
        isInputNum
        value={otp}
        onChange={handleChange}
        numInputs={4}
        containerStyle={classes.containerStyle}
        inputStyle={classes.inputStyle}
      />
      <Box
        display={'flex'}
        justifyContent="center"
        alignItems={'center'}
        my={4}
        width="100%"
      >
        <Countdown
          ref={countDownRef}
          date={countDown}
          renderer={renderer}
        ></Countdown>
      </Box>
      <KenButton
        variant="primary"
        buttonClass={classes.verify}
        type="submit"
        disabled={props.loading}
        onClick={(e) => {
          e.preventDefault();
          // if (`${otp}`.length === 4) {
          //   props.onVerify({ otp });
          // }
          if (`${otp}`.length < 4) {
            return enqueueSnackbar(
              otp?.errorMessage || 'Please enter valid OTP',
              {
                variant: 'error',
              }
            );
          } else {
            props.onVerify({ otp });
          }
        }}
      >
        {props.submitText ? props.submitText : 'Verify'}
      </KenButton>
      {props.loading && (
        <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '49%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      )}
    </Box>
  );
}
