import { REGEX } from '../../Constants/constant';

export default function getValidation(validationType) {
  switch (validationType) {
    case 'email':
      return REGEX.EMAIL;

    case 'alpha':
      return REGEX.NAME;

    case 'alphaNumeric':
      return REGEX.MIXED;

    case 'number':
      return REGEX.ONLYNUMBER;

    case 'numberWithDecimal':
      return REGEX.NUMBERWITHDECIMAL;

    case 'certificate':
      return REGEX.CERTIFICATE;

    case 'cgpa':
      return REGEX.CGPA;
      
    case 'phone':
      return REGEX.PHONE_NUMBER

    default:
      return '';
  }
}
