import { axiosInstance } from '../../Utils/apiServices';

/**
 * Send/Resend OTP to email or mobile
 * @param {{id: string}} payload will have either mobile or email
 */
export const requestForOTP = async (payload) => {
  const path = `/salesforce?endpoint=/services/apexrest/otp/send&contact=${payload.id}`;
  // `/salesforce?endpoint=/services/apexrest/OneTimePassword/send&email=${payload.id}`;

  return axiosInstance
    .request({ url: `${encodeURI(path)}`, method: 'post' })
    .then((res) => res.data);
};

// For contact Less
export const requestForNewOTP = async (payload) => {
  const path = `/salesforce?endpoint=/services/apexrest/OneTimePassword/send?contact=${payload.id}`;

  return axiosInstance
    .request({ url: `${encodeURI(path)}`, method: 'post' })
    .then((res) => res.data);
};

// Lead Form endpints

// send OTP
export const sendOtp = async (queryParams) => {
  // const path = `https://api-v2.ken42.com/essci-samsung-stage/stage/api/salesforce?endpoint=/services/apexrest/otp/send&contact=${queryParams}`;
  const path = `https://esscisamsung-stg-apply-api.ken42.com/ch/api/salesforce?endpoint=/services/apexrest/OneTimePassword/send&email=${queryParams}`;
  const res = await axiosInstance.post(path);
  return res && res.data ? res.data : null;
};

export const validateOtp = async (email, otp) => {
  // const path = `https://api-v2.ken42.com/essci-samsung-stage/stage/api/salesforce?endpoint=/services/apexrest/otp/verify&contact=${email}&otpentered=${otp}`;
  const path = `https://esscisamsung-stg-apply-api.ken42.com/ch/api/salesforce?endpoint=/services/apexrest/OneTimePassword/verify&contact=${email}&otpEnteredEmail=${otp}`;
  const res = await axiosInstance.get(path);
  return res && res.data ? res.data : null;
};

export const postDetails = async (data) => {
  // const path = `https://api-v2.ken42.com/essci-samsung-stage/stage/api/sf/leads`;
  // const path = `https://api-v2.ken42.com/essci-samsung/prod/api/salesforce/services/data/v54.0/sobjects/Lead`;
  const path = `https://esscisamsung-stg-apply-api.ken42.com/ch/api/salesforce?endpoint=/services/data/v54.0/sobjects/Lead`;
  const res = await axiosInstance.post(path, data, {
    headers: {
      'Sforce-Auto-Assign': true,
    },
  });
  return res && res.data ? res.data : null;
};

// Lead Form endpints

/**
 * Verify OTP for user login or signup
 * @param {{id: string, otp: string}} payload will verify otp for email or mobile
 */
export const verifyOTP = async (payload) => {
  const path = `/salesforce?endpoint=/services/apexrest/otp/verify&contact=${payload.id}&otpentered=${payload.otp}&course=${payload.course}`;
  return axiosInstance
    .request({ url: `${path}`, method: 'get' })
    .then((res) => res.data);
};

/**
 * User Signup Request
 * @param {*} payload
 */
export const signUp = async (payload) => {
  const path = `/salesforce?endpoint=/services/apexrest/portal/save`;

  return axiosInstance
    .request({
      url: `${path}`,
      method: 'post',
      data: {
        applicant: {
          salutation: payload.salutation,
          firstName: payload.firstName,
          lastName: payload.lastName,
          email: payload.email,
          phone: payload.phone,
          trainingCentre: payload.trainingCentre,
          course: payload.course,
          // date: payload.date,
          // program: payload.program,
        },
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err, 'err');
    });
};
