import React from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { useTheme } from '@emotion/react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import { useEffect } from 'react';
import { TABLET_BREAKPOINT } from '../../Constants/constant';
import { getSplitName } from '../../Utils/Helpers/stringHelper';
import KenLoader from '../KenLoader';
// import flamelogo from '../../Assets/Images/flame-logo.png';
import { getDocumentsViaRecordId } from '../../Utils/apiServices';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
// import sampleImg from '../../Assets/Images/Nlogo.png';
import Nobody from '../../Assets/Images/nobody.jpg'

const sampleImg = 'https://infivestoragedev.blob.core.windows.net/infive/SIC.jpeg'

const useStyles = makeStyles((theme) => ({
  fieldNameValue: {
    fontWeight: 400,
    color: '#7A869A',
  },
  sectionLabelStrip: {
    backgroundColor: '#000137'
  },
  sectionLabel: {
    fontWeight: 400,
    // overflow: 'visible',
    padding: 2,
    textAlign: 'center',
    backgroundColor: '#000137 !important',
    color:"#fff",
    margin: '10px 0px',
    width: '-webkit-fill-available'
  },
  fieldName: {
    color: '#7A869A',
    overflow: 'visible',
  },
  fieldValue: {
    color: '#061938',
    wordBreak: 'break-all',
    padding: '0px',
  },
  fieldContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'align-items': 'center',
      'justify-content': 'space-between',
      'max-width': '100%',
      display: 'flex',
      width: '100%',
    },
  },
  fieldKeyContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'font-style': 'normal',
      'font-weight': '400',
      'font-size': '16px',
      'text-transform': 'capitalize',
      color: '#7A869A',
    },
    margin: '8px 0px !important',
  },
  fieldValueContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'font-style': 'normal',
      'font-weight': '400',
      'font-size': '16px',
      color: '#061938',
    },
    margin: '8px 0px !important',
  },
  tableHeaderRow: {
    background: '#000137 !important',
  },
  tableHeaderCell: {
    'font-style': 'normal',
    'font-weight': '600',
    'font-size': '12px',
    'text-transform': 'capitalize',
    color: '#fff',
    whiteSpace: 'normal',
    padding: 5,
    textAlign: 'center',
    '&>:nth-child(5)': {
      maxWidth: '100px',
    },
  },
  tableBodyCell: {
    color: '#061938',
    'font-size': '14px',
    'font-style': 'normal',
    'font-weight': '400',
    whiteSpace: 'normal',
    textAlign: 'center',
  },
  formContainer: {
    height: 'calc(100% - 16px)',
    paddingTop: theme.spacing(7.125),
    overflow: 'auto',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      paddingTop: theme.spacing(3),
    },
  },
  stepLabel: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  sectionSubLabel: {
    color: '#7A869A',
    fontWeight: 400,
  },
  footerContainer: {
    position: 'absolute',
    bottom: '0px',
    padding: '16px',
    paddingRight: '223px',
    minHeight: 76,
    // height: "100px",
    left: '0px',
    right: '0px',
    overflow: 'hidden',
    background: '#FFFFFF',
    boxShadow:
      '10px 0px 20px rgba(0, 0, 0, 0.04), 2px 0px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    // backgroundColor: "blue",
  },
  container: {
    position: 'absolute',
    top: '270px',
    bottom: '0px',
    left: '0px',
    right: '0px',
  },
  paymentContainer: {
    padding: '16px 223px',
  },
  fieldNameValue: {
    wordBreak: 'break-word',
    fontWeight: 400,
  },
  fieldName: {
    color: '#7A869A',
  },
  fieldValue: {
    color: '#061938',
  },
  fieldContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      flexDirection: 'column',
      'align-items': 'flex-start',
      'justify-content': 'flex-start',
      'max-width': '100%',
      display: 'flex',
      width: '100%',
      'padding-top': '0 !important',
    },
  },
  fieldKeyContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      width: '40%',
      'font-style': 'normal',
      'font-weight': '400',
      'font-size': '16px',
      'line-height': '150%',
      'text-transform': 'capitalize',
      color: '#7A869A',
    },
  },
  fieldValueContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      width: '60%',
      'font-style': 'normal',
      'font-weight': '400',
      'font-size': '16px',
      'line-height': '150%',
      color: '#061938',
    },
  },
  divider: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      margin: '5px auto 16px 0',
    },
  },
  dividerContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      margin: '5px',
    },
  },
  dividerWrapper: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      margin: '0px',
      paddingTop: '0 !important',
    },
  },
}));


const SummaryToPrint = React.forwardRef((props, ref) => {
  const { formJson, data, applicationState } = props;
  const [pageLoader, setPageLoader] = React.useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const [photo, setPhoto] = React.useState(Nobody);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down(TABLET_BREAKPOINT));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const checkFormOptions = (value) => {
    let combinedValues = [];
    let optionsArray = Object.values(applicationState.formOptions);
    for (let i = 0; i < optionsArray.length; i++) {
      const element = optionsArray[i];
      if (Array.isArray(element)) {
        combinedValues.push(...element);
      }
    }
    if (combinedValues.some((el) => el.value === value)) {
      return combinedValues.find((el) => el.value === value).label;
    } else if (value === 'true' || value === 'false') {
      return value === 'true' ? 'Yes' : 'No';
    } else {
      return value;
    }
  };

  const getFieldValue = (fieldName) => {
    try {
      const name = 'data[' + fieldName + ']';
      const keysArray = getSplitName(name, '', true) || [];
      let str = '';
      for (let i = 0; i < keysArray.length; i++) {
        const element = keysArray[i];
        if (i === 0) {
          str = element;
        } else {
          if (isNaN(element)) {
            str = `${str}["${element}"] && ${str}["${element}_select"] || ${str}["${element}"] && ${str}["${element}"]`;
          } else {
            str = `${str}[${element}] && ${str}[${element}]`;
          }
        }
      }
      return checkFormOptions(eval(str)); //covert string to executable
    } catch {
      console.log('error in eval');
    }
  };

  const getNestedData = (name, structure) => {
    const flatStructureData = structure.map((a) => {
      return { name: a?.name, label: a?.label };
    });
    let dataByName;
    if (/\d/.test(name)) {
      dataByName = getFieldValue(name);
    } else {
      dataByName = data[name] || [];
    }
    const allData = dataByName?.map((el) => {
      let obj = {};
      flatStructureData?.forEach((item) => {
        obj[item.label] = item.name.includes('semesterNumber') || item.name.includes('yearNumber') ? el[item?.name]?.replace(/\D/g, '') : el[item?.name];
      });
      return obj;
    });
    return allData;
  };

  const LastDivider = () => {
    return (
      <Grid item xs={12} lg={12} md={12}>
        <Divider className={classes.divider} />
      </Grid>
    );
  };

  const returnProperValue = (value) => {
    return value ? value : '-';
  };

  const getArrayValues = (values) => {
    let arrayValues = '';
    for (let i = 0; i < values.length; i++) {
      const element = values[i];
      if (i === values.length - 1) {
        arrayValues = arrayValues + `${element?.Program_Plan__r?.Name}`;
      } else {
        arrayValues = arrayValues + `${element?.Program_Plan__r?.Name},`;
      }
    }
    return arrayValues === '' ? '-' : arrayValues;
  };

  React.useEffect(() => {
    console.log(data, 'DATA');
    let attachments = data?.attachments;
    if (attachments !== undefined && attachments.length > 0) {
      // .toLowerCase().includes('photo')
      attachments = attachments.filter((item) => item.title === "Passport Size Photo");
      // console.log(attachments, 'gj658yu567');
      if (attachments.length > 0) {
        let id = attachments[0]?.recordId || null;
        console.log(id, "IDssss")
        let type = attachments[0]?.fileType;
        if (id) {
          setPageLoader(true);
          getDocumentsViaRecordId(id)
            .then((res) => {
              if (res?.success && res?.data) {
                setPageLoader(false);
                if (res.data) {
                  console.log(`data:${type};base64,${res.data}`, 'f45t4545y');
                  setPhoto(`data:${type};base64,${res.data}`);
                }
              }
            })
            .catch((err) => {
              setPageLoader(false);
            });
        }
      }
    }
  }, [data]);


  console.log(applicationState, "applicationSeeeeee")
  return (
        <div ref={ref}>
        <Box p={2}>
          <Box>
          {/* <Typography
            align="left"
            marginBottom={isMobileScreen ? 0 : theme.spacing(4)}
            className={classes.stepLabel}
            fontSize="20px"
          >
            Summary
          </Typography> */}
          <Box display={'flex'} justifyContent={'space-between'}>
            <Grid>
              <img
                src={sampleImg}
                alt="logo"
                style={{
                  height: '150px',
                  width: '250px',
                  marginTop: '10px',
                  objectFit: 'cover',
                }}
              />
          </Grid>
          <Grid>
            <img
              src={photo}
              alt="logo"
              style={{
                height: '150px',
                width: '150px',
                marginTop: '10px',
                objectFit: 'cover',
              }}
            />
          </Grid>
          </Box>
          
          </Box>  
          <Box pt={1}>
            {formJson?.map((step) => {
              return (
                <>
                  <Grid container spacing={2}>
                    {step?.sections?.map((section, index) => {
                      return (
                        <>
                          {section.sectionVisible === true && (
                            <>
                              <Grid item xs={12}>
                                <Box>
                                  {section?.sectionName && (
                                    <Box className={`${classes.sectionLabelStrip}`}>
                                      <Typography
                                        align="center"
                                        marginBottom={
                                          isMobileScreen
                                            ? theme.spacing(1)
                                            : theme.spacing(4)
                                        }
                                        className={classes.sectionLabel}
                                        fontSize="20px"
                                        color="#28144F"
                                        sx={{backgroundColor: '#28144F', width: "100vw" }}
                                      >
                                        {section?.sectionName}
                                      </Typography>
                                    </Box>
                                  )}
                                  {section?.subSectionName && (
                                    <Typography
                                      align="left"
                                      marginBottom={
                                        isMobileScreen
                                          ? theme.spacing(1)
                                          : theme.spacing(4)
                                      }
                                      className={classes.sectionSubLabel}
                                      variant="body2"
                                    >
                                      {section?.subSectionName}
                                    </Typography>
                                  )}
                                </Box>
                              </Grid>
  
                              {/* <Box key={section.sectionId}> */}
                              {/* <Grid container spacing={6} rowSpacing={4}> */}
                              {section.fields.map((field, fieldIndex) => {
                                return field?.metaData?.addMultiple === true ? (
                                  <Grid
                                    item
                                    container
                                    xs={12}
                                    md={12}
                                    spacing={3}
                                  >
                                    <>
                                      {getNestedData(
                                        field.name,
                                        field.fieldsArray
                                      )?.map((nestedField, nestedFieldIndex) => {
                                        return (
                                          nestedField &&
                                          Object.keys(nestedField).map(
                                            (keyName, keyIndex) => {
                                              return (
                                                <>
                                                  <Grid
                                                    item
                                                    container
                                                    xs={12}
                                                    md={6}
                                                    spacing={1}
                                                    className={
                                                      classes.fieldContainer
                                                    }
                                                  >
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      sm={12}
                                                      md={6}
                                                      textAlign="left"
                                                      className={
                                                        classes.fieldKeyContainer
                                                      }
                                                    >
                                                      <Typography
                                                        className={`${classes.fieldName} ${classes.fieldNameValue}`}
                                                        variant="h5"
                                                      >
                                                        {keyName}
                                                      </Typography>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      sm={12}
                                                      md={6}
                                                      textAlign="left"
                                                      className={
                                                        classes.fieldValueContainer
                                                      }
                                                    >
                                                      <Typography
                                                        className={`${classes.fieldValue} ${classes.fieldNameValue}`}
                                                        variant="h5"
                                                      >
                                                        {nestedField[keyName]}
                                                      </Typography>
                                                    </Grid>
                                                  </Grid>
                                                  {Object.keys(nestedField)
                                                    ?.length -
                                                    1 ===
                                                    keyIndex &&
                                                    getNestedData(
                                                      field.name,
                                                      field.fieldsArray
                                                    )?.length -
                                                      1 !==
                                                      nestedFieldIndex && (
                                                      <Grid
                                                        item
                                                        xs={12}
                                                        className={
                                                          classes.dividerContainer
                                                        }
                                                      >
                                                        <Divider
                                                          className={
                                                            classes.divider
                                                          }
                                                        />
                                                      </Grid>
                                                    )}
                                                </>
                                              );
                                            }
                                          )
                                        );
                                      })}
                                    </>
                                    {/* </Typography> */}
                                    {/* </Grid> */}
                                  </Grid>
                                ) : field?.fieldId ? (
                                  <>
                                    {getFieldValue(field.name) && (
                                      <Grid
                                        item
                                        container
                                        xs={12}
                                        md={6}
                                        spacing={2}
                                        className={classes.fieldContainer}
                                      >
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          textAlign="left"
                                          className={classes.fieldKeyContainer}
                                        >
                                          <Typography
                                            className={`${classes.fieldName} ${classes.fieldNameValue}`}
                                            variant="h5"
                                          >
                                            {field?.label}
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          textAlign="left"
                                          className={classes.fieldValueContainer}
                                        >
                                          <Typography
                                            className={`${classes.fieldValue} ${classes.fieldNameValue}`}
                                            variant="h5"
                                          >
                                            {field && getFieldValue(field.name)}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    )}
                                    {field?.ui?.divider && (
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        className={classes.dividerContainer}
                                      >
                                        <Divider className={classes.divider} />
                                      </Grid>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {field?.options.map((el) => {
                                      return (
                                        <>
                                          {getFieldValue(
                                            `${field.name}${el.fieldId}`
                                          ) && (
                                            <Grid
                                              item
                                              container
                                              xs={12}
                                              md={6}
                                              spacing={2}
                                              className={classes.fieldContainer}
                                            >
                                              <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={6}
                                                textAlign="left"
                                                className={
                                                  classes.fieldKeyContainer
                                                }
                                              >
                                                <Typography
                                                  className={`${classes.fieldName} ${classes.fieldNameValue}`}
                                                  variant="h5"
                                                >
                                                  {el?.label}
                                                </Typography>
                                              </Grid>
                                              <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={6}
                                                textAlign="left"
                                                className={
                                                  classes.fieldValueContainer
                                                }
                                              >
                                                <Typography
                                                  className={`${classes.fieldValue} ${classes.fieldNameValue}`}
                                                  variant="h5"
                                                >
                                                  {el &&
                                                    getFieldValue(
                                                      `${field.name}${el.fieldId}`
                                                    )}
                                                </Typography>
                                              </Grid>
                                            </Grid>
                                          )}
                                          {field?.ui?.divider && (
                                            <Grid
                                              item
                                              xs={12}
                                              sm={12}
                                              md={12}
                                              lg={12}
                                              className={classes.dividerContainer}
                                            >
                                              <Divider
                                                className={classes.divider}
                                              />
                                            </Grid>
                                          )}
                                        </>
                                      );
                                    })}
                                  </>
                                );
                              })}
                              {/* </Grid> */}
                              {/* {step?.sections?.length - 1 !== index && ( */}
                              <Grid
                                item
                                xs={12}
                                className={classes.dividerWrapper}
                              >
                                <Box
                                  mt={3}
                                  mb={1}
                                  className={classes.dividerContainer}
                                >
                                  <Divider className={classes.divider} />
                                </Box>
                              </Grid>
                              {/* )} */}
                              {/* </Box> */}
                            </>
                          )}
                        </>
                      );
                    })}
                  </Grid>
                </>
              );
            })}
            {/* {
              applicationState?.declaration ? <>
              <Typography align="left" className={classes.sectionLabel} fontSize="20px" mt={5}>
                Declaration
              </Typography>
              <Grid sx={{display: "flex", alignItems: 'baseline'}}>
                <Grid item >
                  <Checkbox
                    value={declaration}
                    onClick={handleClick}
                  />
                </Grid>
                <Grid item>
                  <Typography>I acknowledge that I have read and understood the terms and conditions set forth by SIC (Samsung Innovation Campus). By ticking this box, I agree to abide by the rules and regulations outlined by SIC and understand that any violation may result in disciplinary action.</Typography>
                </Grid>
              </Grid></> : <>
              <Typography align="left" className={classes.sectionLabel} fontSize="20px" mt={5}>
                Declaration
              </Typography>
              <Grid sx={{display: "flex", alignItems: 'baseline'}}>
                <Grid item >
                  <Checkbox
                    value={declaration}
                    onClick={handleClick}
  
                  />
                </Grid>
                <Grid item>
                  <Typography>I acknowledge that I have read and understood the terms and conditions set forth by SIC (Samsung Innovation Campus). By ticking this box, I agree to abide by the rules and regulations outlined by SIC and understand that any violation may result in disciplinary action.</Typography>
                </Grid>
              </Grid>
            </> 
            } */}
             
          </Box>
        </Box>
      </div>
  );
});

export default SummaryToPrint;