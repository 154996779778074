import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Grid } from '@mui/material';
import Verify from '../common/Verify';
import { AuthContext } from '../../../Context/authContext';
import LoginBG from '../../../Assets/Svg/login-bg.svg';
import MobileLoginBG from '../../../Assets/Svg/login-mobile-bg.svg';
import { requestForOTP, verifyOTP } from '../api';
import { useNavigate, useLocation } from 'react-router-dom';
import routes from '../../../Constants/routes';
import validator from 'validator';
import KenLoader from '../../../Components/KenLoader';
import { useSnackbar } from 'notistack';
import { handleError } from '../util';
import { useMediaQuery } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'white',
    borderRadius: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundImage: `url(${LoginBG})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${MobileLoginBG})`,
    },
  },
}));
export default function LoginOTP(props) {
  const classes = useStyles();
  const { dispatch } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [isEmail, setIsEmail] = useState(null);
  const navigate = useNavigate();
  const { state: navigateState } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [currentSlide, setCurrentSlide] = useState(0); // State to keep track of the current slide index
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  //for refresh signup page
  useEffect(() => {
    const handleRefresh = () => {
      // if (window.performance.navigation.type === 1) {
      navigate(routes.login);
      // }
    };

    window.addEventListener('beforeunload', handleRefresh);

    return () => {
      window.removeEventListener('beforeunload', handleRefresh);
    };
  }, [navigate]);

  useEffect(() => {
    if (!navigateState?.id) {
      navigate(routes.login);
    } else {
      setIsEmail(validator.isEmail(navigateState.currentId));
    }
  }, [navigate, navigateState?.currentId, navigateState?.id]);

  const onSubmit = async ({ otp: { otp } }) => {
    setLoading(true);
    try {
      const res = await verifyOTP({
        otp,
        id: navigateState.currentId,
      });

      if (!res || !res?.success) {
        return enqueueSnackbar(res?.errorMessage || 'Something went wrong', {
          variant: 'error',
        });
      }

      // TODO: load token
      dispatch({
        type: 'LOGIN',
        payload: {
          user: {
            id: navigateState.id,
            applicantEmail: res?.applicantEmail,
            applicantId: res?.applicantId,
            applicantName: res?.applicantName,
            applicantDob: res?.applicantDob,
            applicantCampus: res?.applicantCampus,
          },
        },
      });
    } catch (error) {
      handleError(enqueueSnackbar, error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      {!isSmallScreen && (
        <Box
          style={{
            position: 'relative',
            minHeight: '100vh',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            width: '100%',
            backgroundImage:
              'url("https://inazstgpfs3001.blob.core.windows.net/assets/Images/SAMSUNG/BackgroundPFS.png")',
          }}
        >
          <Box
            style={{
              position: 'absolute',
              top: '50%',
              left: '80%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            {/* Conditionally render loader or login content */}
            {typeof isEmail !== 'boolean' ? (
              <KenLoader />
            ) : (
              <Box className={classes.container}>
                <form>
                  <Verify
                    loading={loading}
                    from="loginOTP"
                    subTitle={
                      <>
                        <span>
                          {isEmail
                            ? 'Please enter the OTP sent to your registered Email ID.'
                            : 'Please enter the OTP sent on your registered mobile number'}
                        </span>
                        {!isEmail && (
                          <strong style={{ marginLeft: '5px' }}>
                            *******{navigateState.currentId.slice(-3)}.
                          </strong>
                        )}
                      </>
                    }
                    submitText="Verify & Log In"
                    onVerify={(otp) => {
                      onSubmit({ otp });
                    }}
                    resendOTP={async () => {
                      const res = await requestForOTP({
                        id: navigateState.currentId,
                      });
                      // alert(res?.OTP);
                      // alert('OTP is: ' + res?.OTP);
                    }}
                  ></Verify>
                </form>
              </Box>
            )}
          </Box>
        </Box>
      )}

      {isSmallScreen && (
        <Box
          className={classes.container}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '90vh',
          }}
        >
          <form>
            {typeof isEmail !== 'boolean' ? (
              <KenLoader />
            ) : (
              <Verify
                loading={loading}
                from="loginOTP"
                subTitle={
                  <>
                    <span>
                      {isEmail
                        ? 'Please enter the OTP sent to your registered Email ID.'
                        : 'Please enter the OTP sent on your registered mobile number'}
                    </span>
                    {!isEmail && (
                      <strong style={{ marginLeft: '5px' }}>
                        *******{navigateState.currentId.slice(-3)}.
                      </strong>
                    )}
                  </>
                }
                submitText="Verify & Log In"
                onVerify={(otp) => {
                  onSubmit({ otp });
                }}
                resendOTP={async () => {
                  const res = await requestForOTP({
                    id: navigateState.currentId,
                  });
                  // alert(res?.OTP);
                  // alert('OTP is: ' + res?.OTP);
                }}
              ></Verify>
            )}
          </form>
        </Box>
      )}
    </Box>
  );
}
