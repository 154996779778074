import styled from '@emotion/styled';
import {
  Avatar,
  Box,
  Button,
  Grid,
  Popover,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import MuiAppBar from '@mui/material/AppBar';
import { makeStyles } from '@mui/styles';
import KenAutoComplete from '../KenAutoComplete';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { AuthContext } from '../../Context/authContext';
import ReorderOutlinedIcon from '@mui/icons-material/ReorderOutlined';
import { useNavigate } from 'react-router-dom';
import { getAllPrograms } from '../../Utils/apiServices';
import { useTheme } from '@emotion/react';
import { TABLET_BREAKPOINT } from '../../Constants/constant';

const drawerWidth = 210;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  paddingLeft: 124,
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    paddingLeft: 28,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: theme.palette.KenColors.kenWhite,
  paddingTop: 8,
  [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
    paddingLeft: 0,
    width: '100%',
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.KenColors.kenWhite,
    minHeight: '56px !important',
  },
  icon: {
    color: theme.palette.KenColors.kenBlack,
  },
  iconWrapper: {
    // padding: "8px 12px",
    background: '#F4F5F7',
    borderRadius: '50%',
    height: '40px',
    width: '40px',
    display: 'flex',
    'justify-content': 'center',
    'align-items': 'center',
  },
  name: {
    color: theme.palette.KenColors.kenBlack,
    fontWeight: 500,
  },
  avatar: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  appbarRoot: {
    [theme.breakpoints.only('xs')]: {
      right: 'auto',
      paddingLeft: 0,
      width: '100%',
    },
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'box-shadow': '0px 1px 12px rgb(0 0 0 / 25%)',
      // Note: added so header shadow shows on top of add task modal
      zIndex: '1301',
    },
  },
  menuToggle: {
    display: 'none',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      display: 'block',
    },
  },
}));

export default function MenuAppBar(props) {
  const classes = useStyles();
  const { open, setSwipeableDrawer } = props;
  const {
    state: { user },
    dispatch,
  } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [data, setData] = React.useState();
  const [searchValue, setSearchValue] = useState('');
  const navigate = useNavigate();
  const [programs, setPrograms] = useState();
  const theme = useTheme();
  const screenSizeSmallScreen = useMediaQuery(theme.breakpoints.only('sm'));

  useEffect(() => {
    if (data) {
      handleSearch();
      setSearchValue('');
      setData();
    }
  }, [data]);

  React.useEffect(() => {
    getAllPrograms().then((res) => {
      if (res?.data) {
        const data = res?.data?.map((el) => {
          return { label: el?.programName, ...el };
        });
        setPrograms(data);
      }
    });
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'simple-popover' : undefined;

  const handleSearch = () => {
    if (searchValue !== '') {
      navigate({
        pathname: '/courses',
        search: `?search=${searchValue}`,
      });
    }
  };
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );

  return (
    <AppBar
      position="fixed"
      open={open}
      elevation={0}
      classes={{ root: classes.appbarRoot }}
    >
      <Toolbar classes={{ root: classes.root }} variant="regular">
        <Grid container direction="row" spacing={2}>
          <Grid
            item
            md={8}
            sm={8}
            xs={10}
            container
            spacing={1}
            alignItem="center"
            justifyContent="center"
            direction="row"
          >
            <Grid item xs={2} sx={{ mt: 1 }} className={classes.menuToggle}>
              <ReorderOutlinedIcon
                className={classes.icon}
                onClick={() => {
                  setSwipeableDrawer(true);
                }}
              />
            </Grid>
            <Grid item md={12} tablet={12} xs={10}>
              <KenAutoComplete
                options={programs || []}
                placeholder={'Search...'}
                setData={setData}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                handleSearch={handleSearch}
              />
            </Grid>
          </Grid>
          <Grid
            item
            md={4}
            sm={4}
            xs={2}
            container
            alignItems="center"
            justifyContent="flex-end"
            direction="row"
            sx={{ paddingRight: '12px' }}
          >
            <Grid item>
              {/* <Box className={classes.iconWrapper}>
                <NotificationsNoneIcon className={classes.icon} />
              </Box> */}
            </Grid>
            <Grid
              item
              sx={{
                display: { xs: 'none', sm: 'block' },
                marginLeft: theme.spacing(3),
              }}
            >
              <Typography className={classes.name}>
                {user?.applicantName?.split(' ')[0].length < 13
                  ? user?.applicantName?.split(' ')[0]
                  : `${user?.applicantName
                      ?.split(' ')[0][0]
                      .toUpperCase()} ${user?.applicantName
                      ?.split(' ')[1][0]
                      .toUpperCase()}`}
              </Typography>
            </Grid>
            <Grid
              item
              onClick={handleClick}
              sx={{
                display: { xs: 'none', sm: 'block' },
                marginLeft: theme.spacing(1.25),
              }}
            >
              <Avatar className={classes.avatar}>
                {user?.applicantName[0]}
              </Avatar>
            </Grid>
            {/* } */}
          </Grid>
          <Popover
            id={id}
            open={openPopover}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Typography
              sx={{ p: 2 }}
              onClick={() => {
                // TODO: remove once signup is in place
                dispatch({
                  type: 'LOGOUT',
                  payload: {
                    user: {},
                    token: null,
                  },
                });
              }}
              className={classes.avatar}
            >
              Logout
            </Typography>
          </Popover>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
